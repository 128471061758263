import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EditorState } from 'draft-js';
import { editorStateToHtml } from '@tsUtils';
import actions from '../redux/actions';
import { useAppSelector } from '../redux';
import i18n from '../i18n';
import { FormDataTermsAndConditions, TermsAndConditions } from '../types';
import { TEMPLATE_STEPS } from '../enums';
import TermsAndConditionsLanguage from './TermsAndConditionsLanguage';
import {
    getDraftFilePlaceholders,
    getDraftTermsPlaceholders,
    getDraftTermsAndConditions,
} from '../redux/epackageSelectors';
import { AlertStyles } from '../styles/alert';

const TermsAndConditionsView = (): JSX.Element => {
    const dispatch = useDispatch();
    const errors = useAppSelector((store) => store.appState.errors?.termsAndConditions);
    const templateStep = useAppSelector((store) => store.appState.templateStep);
    const formErrors = useAppSelector((store) => store.appState.errors?.form);
    const impersonation = useAppSelector((store) => store.settings.impersonation);
    const isActive = useAppSelector((store) => store.epackage.isActive);
    const [panelOpen, setPanelOpen] = useState<boolean>(templateStep === TEMPLATE_STEPS.step_2);

    // set-up data structure to give to logic when saving terms and conditions
    const draftFilePlaceholders = useAppSelector(getDraftFilePlaceholders);
    const draftTermsPlaceholders = useAppSelector(getDraftTermsPlaceholders);
    const [data, setData] = useState<FormDataTermsAndConditions>({
        files: draftFilePlaceholders,
        terms: draftTermsPlaceholders,
    });

    // Data structure for rendering
    const draftTermsAndConditionsPlaceholders = useAppSelector(getDraftTermsAndConditions);

    const [draftTermsAndConditions, setDraftTermsAndConditions] = useState<TermsAndConditions[]>(
        draftTermsAndConditionsPlaceholders,
    );

    const panelClass = formErrors?.['epackageGroupId.terms_and_files']
        ? 'panel-danger'
        : 'panel-default';

    useEffect(() => {
        setPanelOpen(templateStep === TEMPLATE_STEPS.step_2);
    }, [templateStep]);

    const updateFiles = (language: string, file: File | null): void => {
        setData({
            ...data,
            files: {
                ...data.files,
                [language]: file,
            },
        });
    };

    const updateTermsAndConditions = (
        language: string,
        TermsAndConditionsText: EditorState,
    ): void => {
        setDraftTermsAndConditions(
            draftTermsAndConditions.map((draft) => {
                return language === draft.language
                    ? { ...draft, text: editorStateToHtml(TermsAndConditionsText) }
                    : draft;
            }),
        );
        setData({
            ...data,
            terms: {
                ...data.terms,
                [language]: editorStateToHtml(TermsAndConditionsText),
            },
        });
    };

    return (
        <div className={`panel ${panelClass}`}>
            <div className="panel-heading" role="tab" id="heading-terms">
                <h4 className="panel-title">
                    <a
                        role="button"
                        className="collapse in"
                        data-parent="#accordion"
                        href="#collapse-terms"
                        aria-expanded="true"
                        aria-controls="collapse-terms"
                        onClick={() => {
                            // Open/Close template step 2
                            setPanelOpen(!panelOpen);
                            dispatch(actions.appState.setTemplateStep(panelOpen ? null : 2));
                        }}
                    >
                        {i18n('step_2_your_terms_and_conditions')}
                    </a>
                </h4>
            </div>
            <div
                id="collapse-terms"
                className={`panel-collapse collapse ${panelOpen ? 'in' : ''}`}
                aria-labelledby="heading-terms"
                aria-expanded={panelOpen}
            >
                <div className="panel-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <span id="tooltip-target-4" className="alert alert-info pull-left">
                                {i18n('terms_and_conditions_help_message')}
                            </span>
                        </div>
                    </div>

                    {errors && (
                        <>
                            <div className="row">
                                <div className="col-lg-12">
                                    <span
                                        className="alert alert-warning pull-left"
                                        style={AlertStyles}
                                    >
                                        {Object.entries(errors).map(([id, error]) => {
                                            return <div key={id}>{error}</div>;
                                        })}
                                    </span>
                                </div>
                            </div>
                            <br />
                        </>
                    )}

                    <div className="row">
                        {draftTermsAndConditions.map((termAndCondition) => {
                            return (
                                <TermsAndConditionsLanguage
                                    key={`tac_${termAndCondition.language}`}
                                    data={termAndCondition}
                                    error={
                                        errors?.[`files[${termAndCondition.language}][new_path]`] ||
                                        null
                                    }
                                    setTermsAndConditions={updateTermsAndConditions}
                                    setTermsAndConditionsFiles={updateFiles}
                                    isActive={isActive}
                                />
                            );
                        })}
                    </div>
                    <hr />
                    <div className="form-group">
                        <div className="col-lg-12">
                            {/* User can't save t&c when impersonating prof and package is active */}
                            <button
                                type="button"
                                id="tooltip-target-5"
                                className="btn btn-primary"
                                disabled={isActive}
                                onClick={
                                    impersonation && isActive
                                        ? undefined
                                        : () => {
                                              dispatch(
                                                  actions.epackage.saveTermsAndConditions(data),
                                              );
                                          }
                                }
                            >
                                {i18n('save_all')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditionsView;
