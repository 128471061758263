import React from 'react';
// Components
import ModalWrapper from './ModalWrapper';
// Labels
import i18n from '../i18n';

/* --- <RemoveConfirmModal> ---------------------------------------------------------------------------- */

const RemoveConfirmModal = ({ onClose, urls, id, type, postAndUpdate }) => {
    // -- Handlers --

    const onRemove = () => {
        const removeUrl = type === 'folder' ? urls.removeFolder : urls.removeDocument;
        postAndUpdate(removeUrl.replace('__id__', id), {}, onClose);
    };

    // -- Render --

    return (
        <ModalWrapper
            modalTitle={i18n('areYouSure')}
            closeLabel={i18n('no')}
            saveLabel={i18n('yes')}
            onSubmit={onRemove}
            onClose={onClose}
        >
            {i18n(type === 'folder' ? 'removeThisFolder' : 'removeThisFile')}
        </ModalWrapper>
    );
};

/* --- Exports ------------------------------------------------------------------------------ */

export default RemoveConfirmModal;
