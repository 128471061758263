/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import axios from 'axios';
import boiler from '@boiler';
import { cleanObject } from '@tsUtils';
import Modal from '@common/Modal';
import DropDown from '@common/controls/DropDown';
import VerificationIcon, { STATUS } from '@common/atoms/VerificationIcon';
import { setNumberOfPanels, setSerialNumber, setUnitCode, setUnitType } from './redux/actions';

const UNIT_TYPES = {
    OUTDOOR: 'outdoor',
    INDOOR: 'indoor',
    TANK: 'tank',
    BOILER: 'boiler',
    GATEWAY: 'gateway',
    SOLAR_PUMP: 'solar_pump',
    LAN_ADAPTER: 'lan_adapter',
};

const mb0 = { marginBottom: 0 };
const mb15 = { marginBottom: 15 };
const warningStyle = { marginTop: 0, marginBottom: 10 };

class App extends React.Component {

    componentDidMount() {
        window.addEventListener('keydown', e => {
            if (e.code === 'Enter') {
                e.preventDefault();
                const { unitType, serialNumber, unitCode } = this.props.input;
                if (unitType && serialNumber && unitCode) {
                    this.onSave();
                }
            }
        });
    }

    onSerialInput = e => {
        this.props.setSerialNumberAction(e.currentTarget.value);
    };

    onNumberOfPanelsInput = e => {
        this.props.setNumberOfPanelsAction(e.currentTarget.value);
    };

    onSave = () => {
        const { saveUrl, input } = this.props;
        const { unitType, serialNumber, unitCode } = input;
        const data = {
            unitType,
            serial: serialNumber,
            material: unitCode,
            numberOfPanels: input.numberOfPanels || null,
        };
        axios.post(saveUrl, cleanObject(data))
            .then(_ => window.location.reload())
            .catch(_ => window.location.reload()); // No failure scenario described
    };

    render() {
        const {
            // Data
            units,
            labels,
            input,
            evEnabled,
            gatewayPrefix,
            // Actions
            setUnitTypeAction,
            setUnitCodeAction,
        } = this.props;
        // Check if there are units available
        if (!units.length) {
            return (
                <Modal modalTitle={labels.add_unit} closeLabel={labels.close}>
                    {labels.no_units_can_be_added}
                </Modal>
            );
        }
        // Fetch selectedUnit
        const selectedUnit = units.find(u => u.type === input.unitType);
        // Convert units to { value, label }-objects & inject an empty 'Select...' placeholder
        const unitTypeChoices = units.map(unit => ({ value: unit.type, label: labels.types[unit.type] }));
        unitTypeChoices.unshift({ value: '', label: labels.select });
        // Convert materials to unitCodes & inject an empty 'Select...' placeholder
        const unitCodeChoices = selectedUnit ? selectedUnit.materials.map(value => ({ value })) : [];
        unitCodeChoices.unshift({ value: '', label: labels.select });
        // Verification
        let errorMessage = null;
        let verificationCss = '';
        if (input.verification) {
            if (input.verification.status === STATUS.ERROR) {
                errorMessage = input.verification.error;
                verificationCss = 'has-error';
            } else if (input.verification.status === STATUS.SUCCESS) {
                verificationCss = 'has-success';
            }
        }
        // Number of solar panels warning
        let warningMessage = null;
        let panelVerificationCss = '';
        if (
            (input.unitType === UNIT_TYPES.SOLAR_PUMP)
            && !Number.isNaN(+input.numberOfPanels)
            && (input.numberOfPanels < 1 || input.numberOfPanels > 10)
        ) {
            panelVerificationCss = 'has-error';
            warningMessage = labels.invalid_number_of_panels;
        }
        // Flags
        const isGateway = input.unitType === UNIT_TYPES.GATEWAY;
        // Serial input
        let serialInput = input.serialNumber;
        if (serialInput === null && isGateway) serialInput = gatewayPrefix;
        // Verify if user can submit
        return (
            <Modal
                modalTitle={labels.add_unit}
                closeLabel={labels.close}
                saveLabel={labels.save}
                submit={this.onSave}
                isSubmitDisabled={!(input.unitType && input.serialNumber && input.unitCode)}
            >
                <form className='form-horizontal'>
                    <div className='form-group required' style={mb0}>
                        {/* UNIT TYPE */}
                        <div className='row' style={mb15}>
                            <label htmlFor='unittype' className='col-sm-3 control-label'>
                                {labels.unit_type}
                            </label>
                            <div className={`col-sm-${evEnabled ? 6 : 8}`}>
                                <DropDown
                                    options={unitTypeChoices}
                                    selected={input.unitType}
                                    onChange={setUnitTypeAction}
                                />
                            </div>
                        </div>
                    </div>
                    {selectedUnit && (
                        <>
                            <div className='row' style={mb15}>
                                <div className='col-sm-3' />
                                <div className={`col-sm-${evEnabled ? 6 : 8}`}>
                                    <h5>{labels.unit_details}</h5>
                                </div>
                            </div>
                            <div className={`form-group required ${verificationCss}`} style={mb0}>
                                {/* SERIAL NR */}
                                <div className='row' style={mb15}>
                                    <label htmlFor='serialnr' className='col-sm-3 control-label'>
                                        {isGateway ? labels.gateway : labels.serial_number}
                                    </label>
                                    <div className={`col-sm-${evEnabled ? 6 : 8}`}>
                                        <input
                                            type='text'
                                            className='form-control serial-number verify'
                                            id='serialnr'
                                            placeholder=''
                                            value={serialInput}
                                            onChange={this.onSerialInput}
                                        />
                                    </div>
                                </div>
                                {/* UNIT CODE */}
                                <div className='row' style={mb15}>
                                    <label htmlFor='unitcode' className='col-sm-3 control-label'>
                                        {labels.unit}
                                    </label>
                                    <div className={`col-sm-${evEnabled ? 6 : 8}`}>
                                        <DropDown
                                            options={unitCodeChoices}
                                            selected={input.unitCode}
                                            onChange={setUnitCodeAction}
                                        />
                                    </div>
                                    {/* VALIDATION */}
                                    {evEnabled && <VerificationIcon verification={input.verification} />}
                                </div>
                            </div>
                            {/* NUMBER OF PANELS */}
                            {(input.unitType === UNIT_TYPES.SOLAR_PUMP) && (
                                <div className={`form-group ${panelVerificationCss}`}>
                                    <div className='row'>
                                        <label htmlFor='numberOfPanels' className='col-sm-3 control-label'>
                                            {labels.number_of_panels}
                                        </label>
                                        <div className='col-sm-3'>
                                            <input
                                                min={0}
                                                step={1}
                                                type='number'
                                                className='form-control'
                                                id='numberOfPanels'
                                                placeholder=''
                                                value={input.numberOfPanels || ''}
                                                onChange={this.onNumberOfPanelsInput}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <div className='row' style={mb15}>
                        <label htmlFor='lanAdapterInfo' className='col-sm-3 control-label' />
                        <div className='col-sm-8'>{labels.lan_adapter_info}</div>
                    </div>
                    {warningMessage && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='alert alert-warning' style={warningStyle}>
                                    {warningMessage}
                                </div>
                            </div>
                        </div>
                    )}
                    {errorMessage && (
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='alert alert-danger' style={warningStyle}>
                                    {errorMessage}
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </Modal>
        );
    }

}

const mapStateToProps = ({ settings, input, data, labels }) => {
    const { units, allowedUnits, url } = data;
    return {
        units: Object.values(units).filter(u => allowedUnits[u.type]),
        input,
        labels,
        evEnabled: settings.evEnabled,
        gatewayPrefix: settings.gatewayPrefix,
        saveUrl: url,
    };
};

const mapActionsToProps = {
    setUnitTypeAction: setUnitType,
    setSerialNumberAction: setSerialNumber,
    setUnitCodeAction: setUnitCode,
    setNumberOfPanelsAction: setNumberOfPanels,
};

export default boiler.connect(mapStateToProps, mapActionsToProps)(App);
