// Action types
export const SET_UNIT_TYPE = 'SET_UNIT_TYPE';
export const SET_SERIAL_NUMBER = 'SET_SERIAL_NUMBER';
export const SET_UNIT_CODE = 'SET_UNIT_CODE';
export const SET_VERIFICATION = 'SET_VERIFICATION';
export const SET_NUMBER_OF_PANELS = 'SET_NUMBER_OF_PANELS';

// Action creators
export const setUnitType = unitType => ({ type: SET_UNIT_TYPE, unitType });
export const setSerialNumber = serialNumber => ({ type: SET_SERIAL_NUMBER, serialNumber });
export const setUnitCode = unitCode => ({ type: SET_UNIT_CODE, unitCode });
export const setVerification = verification => ({ type: SET_VERIFICATION, verification });
export const setNumberOfPanels = numberOfPanels => ({ type: SET_NUMBER_OF_PANELS, numberOfPanels });
