import React from 'react';
import { useDispatch } from 'react-redux';
import { TooltipRenderProps } from 'react-joyride';
import * as Icons from '@common/icons';
import actions from '../redux/actions';
import * as JoyrideTooltipStyles from '../styles/JoyrideTooltip';

const JoyrideTooltip = ({ step, tooltipProps, index }: TooltipRenderProps): JSX.Element => {
    const dispatch = useDispatch();

    const onCloseTooltip = (): void => {
        dispatch(actions.appState.ignoreTooltip(index));
    };

    return (
        <JoyrideTooltipStyles.Body {...tooltipProps}>
            {step.title && (
                <JoyrideTooltipStyles.Title>
                    {step.title}
                    <JoyrideTooltipStyles.Button onClick={onCloseTooltip}>
                        <Icons.Glyph.Close />
                    </JoyrideTooltipStyles.Button>
                </JoyrideTooltipStyles.Title>
            )}
            {step.content && (
                <JoyrideTooltipStyles.Content>{step.content}</JoyrideTooltipStyles.Content>
            )}
        </JoyrideTooltipStyles.Body>
    );
};
export default JoyrideTooltip;
