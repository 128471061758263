import { InstallationsData } from '../../types';
import { Actions } from '../actions';
import { APP_STATE_ACTIONS } from '../actions/appState';
import { INSTALLATIONS_ACTIONS } from '../actions/installations';

const initialState: InstallationsData = {
    installations: [],
    selected: [],
    filteredInstallationIds: [],
};

const installationsData = (state: InstallationsData, action: Actions): InstallationsData => {
    switch (action.type) {
        case INSTALLATIONS_ACTIONS.SET:
            return {
                ...state,
                installations: action.installations,
            };
        case INSTALLATIONS_ACTIONS.SELECT_ALL_INSTALLATIONS:
            return {
                ...state,
                selected: action.selectAll ? state.installations.map(({ id }) => id) : [],
            };
        case INSTALLATIONS_ACTIONS.SELECT_INSTALLATION:
            return {
                ...state,
                selected: state.selected.find((id) => id === action.installationId)
                    ? state.selected.filter((id) => id !== action.installationId)
                    : [...state.selected, action.installationId],
            };
        case APP_STATE_ACTIONS.CLOSE_OVERLAY:
            return initialState;
        case INSTALLATIONS_ACTIONS.SET_SEARCH_INPUT:
            return {
                ...state,
                selected: [],
            };
        case INSTALLATIONS_ACTIONS.SET_FILTERED_INSTALLATION_IDS:
            return {
                ...state,
                filteredInstallationIds: action.installationIds,
            };
        default:
            return state || initialState;
    }
};

export default installationsData;
