import React from 'react';
import autobind from 'autobind-decorator';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import i18n from '../../i18n';

export default class Link extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            searchQuery: '',
        };
    }

    @autobind
    onRenderUser({ id, name }) {
        return <span key={id}>{name}</span>;
    }

    @autobind
    onChangeIsHandoverAllowed(selectedEndUser, setSelectedEndUserAction) {
        setSelectedEndUserAction({
            ...selectedEndUser,
            isContractHandoverAllowed: {
                ...selectedEndUser?.isContractHandoverAllowed,
                other_eu: !selectedEndUser?.isContractHandoverAllowed?.other_eu,
            },
        });
    }

    @autobind
    searchCustomers(searchQuery) {
        const { settings } = this.props;

        if (searchQuery.length >= 2) {
            axios
                .get(settings.fetchCustomersUrl, {
                    params: {
                        searchQuery,
                    },
                })
                .then((res) => {
                    const { data } = res;
                    this.setState({ users: data });
                })
                .catch((error) => {
                    console.log(error); // eslint-disable-line no-console
                });
        }
    }

    @autobind
    selectedEndUser(_event, { name }) {
        this.setState({ searchQuery: name });
    }

    render() {
        const { errors, setSelectedEndUserAction, selectedEndUser = {} } = this.props;
        const { users } = this.state;
        const { linkError } = errors;

        const inputProps = {
            placeholder: i18n('search_placeholder'),
            value: this.state.searchQuery,
            onChange: (e) => this.setState({ searchQuery: e.target.value }),
        };

        const isHandOverAllowed = !!selectedEndUser?.isContractHandoverAllowed?.other_eu;

        return (
            <div className="panel-body">
                <div className={`form-group required ${linkError && 'has-error'}`}>
                    <div className="col-sm-12">
                        <Autosuggest
                            suggestions={users.slice(0, 5)}
                            onSuggestionsFetchRequested={(e) => this.searchCustomers(e.value)}
                            onSuggestionsClearRequested={() => this.setState({ users: [] })}
                            onSuggestionSelected={(e, { suggestion }) => {
                                this.selectedEndUser(e, suggestion);
                                setSelectedEndUserAction(suggestion);
                            }}
                            getSuggestionValue={(suggestion) => suggestion}
                            renderSuggestion={(e) => this.onRenderUser(e)}
                            inputProps={inputProps}
                        />
                        {linkError && (
                            <span className="help-block">
                                <ul className="list-unstyled">
                                    <li>
                                        <span className="glyphicon glyphicon-exclamation-sign" />
                                        {' '}
                                        {linkError}
                                    </li>
                                </ul>
                            </span>
                        )}
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-12">
                        <span className="help-block">{i18n('search_help')}</span>
                    </div>
                </div>
                <div className='checkbox'>
                    <label>
                        <input
                            type='checkbox'
                            onChange={() => {
                                this.onChangeIsHandoverAllowed(selectedEndUser, setSelectedEndUserAction);
                            }}
                            checked={isHandOverAllowed}
                        />
                        {i18n('allow_contract_handover')}
                    </label>
                </div>
            </div>
        );
    }
}
