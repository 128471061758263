import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
`;

export const Main = styled.button`
    display: table;
    position: relative;
    padding: 0;
    background: none;
    border: 0;
    border-collapse: separate;
    border-radius: 2px;
    outline: 0;
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    text-decoration: none;
    text-transform: none;
    text-rendering: auto;
    text-indent: 0px;
    text-shadow: none;
    text-align: center;
    letter-spacing: normal;
    word-spacing: normal;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: middle;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 20px;
    -webkit-transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out,border-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out,border-color 0.2s ease-in-out;
    overflow: visible;
    -webkit-writing-mode: horizontal-tb !important;
    align-items: flex-start;
`;

export const Label = styled.span`
    display: table-cell;
    width: 100%;
    padding: 10px 11px;
    font-family: 'Roboto',sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: left;
    color: #628495;
    background-color: #fff;
    background-image: none;
    border: 1px solid #C4CFD5;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: 0;
    box-sizing: border-box;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`;

export const Addon = styled.span`
    display: table-cell;
    width: 1%;
    min-width: 43px;
    padding: 10px 10px;
    font-family: 'OpenSansRegular', sans-serif;
    font-size: 15px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #C4CFD5;
    border-left: 0;
    border-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-transition: background-color 0.3s ease-in;
    transition: background-color 0.3s ease-in;
`;

export const Icon = styled.span`
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    line-height: 1;
    vertical-align: middle;
`;

export const List = styled.ul`
    display: block;
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    list-style: none;
    text-align: left;
    color: #6C8EA4;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #C4CFD5;
    border-top: 0;
    z-index: 1000;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
`;

export const Item = styled.li`
    color: #09C;
    font-family: "Roboto Condensed",sans-serif;
    border-top: 1px solid #C4CFD5;
    cursor: pointer;

    ${props => props.disabled ? `
        pointer-events: none;
        cursor: default;
    ` : ''}
`;

export const ItemLabel = styled.a`
    display: block;
    padding: 1rem;
    color: #09C;
    text-decoration: none;
    background-color: transparent;

    ${props => props.disabled ? `opacity: .3;` : ''}
`;
