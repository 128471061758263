export enum TEMPLATE_STEPS {
    step_1 = 1,
    step_2 = 2,
    step_3 = 3,
    step_4 = 4,
}

export enum TOOLTIPS {
    tt1 = 0,
    tt2 = 1,
    tt3 = 2,
    tt4 = 3,
    tt5 = 4,
}
