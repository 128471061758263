import { EndUser, EndUserData, EndUserActions } from '../types';
import { actionTypes, ActionTypes } from './index';

const ENDUSER_ERROR_MAP: Record<string, string> = {
    'endUserData[email]': 'emailError',
    'endUserData[id]': 'linkError',
};

const initialEndUserDataState: Partial<EndUserData> = {
    action: EndUserActions.no_change,
    hasEndUser: null,
    selectedEndUser: null,
    errors: {},
};

const endUserData = (state: EndUserData, action: ActionTypes): EndUserData => {
    switch (action.type) {
        case actionTypes.SET_ENDUSER_ACTION:
            return { ...state, action: action.data };
        case actionTypes.SET_SELECTED_ENDUSER:
            return { ...state, ...{ selectedEndUser: action.data as EndUser } };
        case actionTypes.SET_ERRORS_ACTION: {
            const errors: Record<string, string> = {};
            Object.entries(action.errors).forEach(([key, value]) => {
                const mappedKey: string = ENDUSER_ERROR_MAP[key];
                if (mappedKey) errors[mappedKey] = value as string;
            });
            return { ...state, ...{ errors } };
        }
        default:
            return { ...initialEndUserDataState, ...state };
    }
};

export default endUserData;
