// Action types
import { ACTIONS } from '../constants';
// Default state
const initialState = {
    notifications: [],
    notificationCount: 0,
    unreadNotificationCount: 0,
    tasks: [],
    taskCount: null,
    animate: false,
    page: 1,
    showUnread: false,
};

// Action creators
export const actions = {
    setNotifications: notificationsData => ({ type: ACTIONS.SET_NOTIFICATIONS, value: notificationsData }),
    setAnimation: animate => ({ type: ACTIONS.SET_ANIMATION, value: animate }),
    toggleNotificationRead: id => ({ type: ACTIONS.TOGGLE_NOTIFICATION_READ, value: id }),
    updateUnreadNotificationCounter: count => ({ type: ACTIONS.SET_UNREAD_NOTIFICATION_COUNTER, value: count }),
    setPaging: page => ({ type: ACTIONS.SET_PAGING, value: page }),
    fetchNotifications: () => ({ type: ACTIONS.FETCH_NOTIFICATIONS }),
    toggleShowUnreadAction: () => ({ type: ACTIONS.TOGGLE_SHOW_UNREAD_NOTIFICATIONS }),
};

function toggleNotificationRead(notifications, id) {
    return notifications.map((notification) => {
        if (notification.id !== id) {
            return notification;
        }

        return {
            ...notification,
            hasBeenRead: !notification.hasBeenRead,
        };
    });
}

// Reducer
export default function data(state, action) {

    switch (action.type) {
        case ACTIONS.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.value.notifications,
                notificationCount: action.value.count,
                unreadNotificationCount: action.value.unreadCount,
            };
        case ACTIONS.SET_ANIMATION:
            return { ...state, animate: action.value };
        case ACTIONS.TOGGLE_NOTIFICATION_READ:
            return { ...state, notifications: toggleNotificationRead(state.notifications, action.value) };
        case ACTIONS.SET_UNREAD_NOTIFICATION_COUNTER:
            return { ...state, unreadNotificationCount: action.value };
        case ACTIONS.SET_PAGING:
            return { ...state, page: action.value };
        case ACTIONS.TOGGLE_SHOW_UNREAD_NOTIFICATIONS:
            return { ...state, page: 1, showUnread: !state.showUnread };
        default:
            return { ...initialState, ...state };
    }
}
