export enum APP_STATE_ACTIONS {
    OPEN_OVERLAY = 'installations/openOverlay',
    CLOSE_OVERLAY = 'installations/closeOverlay',
}

export type OpenOverlayAction = {
    type: APP_STATE_ACTIONS.OPEN_OVERLAY;
};
const openOverlay = (): OpenOverlayAction => ({
    type: APP_STATE_ACTIONS.OPEN_OVERLAY,
});

export type CloseOverlayAction = {
    type: APP_STATE_ACTIONS.CLOSE_OVERLAY;
};
const closeOverlay = (): CloseOverlayAction => ({
    type: APP_STATE_ACTIONS.CLOSE_OVERLAY,
});

export type AppStateActions = OpenOverlayAction | CloseOverlayAction;

export default {
    openOverlay,
    closeOverlay,
};
