import { tada } from 'react-animations';
import styled, { css, keyframes } from 'styled-components';
import { THEMES } from './constants';

const tadaAnimation = keyframes`${tada}`;

export const NotificationButton = styled.button`
    ${({ animate }) =>
        animate &&
        css`
            animation: 1s ${tadaAnimation};
        `}
`;

export const Badge = styled.span`
    background-color: red;
    color: white;
`;

export const NotificationDropdown = styled.div`
    padding:0;
    border: 0;
    display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
`;

export const NotificationListGroup = styled.div`
    width: 500px;
    margin-bottom: 0;
`;

export const ButtonAllNotifications = styled.div`
    padding-top: 5px;
    padding-bottom: 5px;
`;

export const Header = styled.div`
    padding-top: 5px;
    padding-bottom: 5px;
    ${({ theme }) => {
        switch(theme.type) {
            case THEMES.WIDGET:
                return css`
                    border-bottom: 2px solid #c6d8e5;
                `;
            case THEMES.OVERVIEW:
                return css`
                    padding-left: 5px;
                    padding-right: 5px;
                    border: 0px;
                    border-bottom: 1px solid #c6d8e5;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                `;
            default:
                return css``;
        }
    }}
`;

export const Notification = styled.div`
    ${({ theme }) =>
       theme.type === THEMES.OVERVIEW &&
        css`
            border-left: 0;
            border-right: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
    `}
    ${({ hasBeenRead, theme }) =>
        !hasBeenRead && (theme.type === THEMES.WIDGET || theme.type === THEMES.OVERVIEW) &&
        css`
            background-color: lightblue;
    `}
`;

export const NotificationLink = styled.a`
    color: inherit !important;
`;

export const NotificationHeaderCounter = styled.span`
    background-color: grey;
    color: white;
    margin-left: 5px;
`;

export const LoadMore = styled.div`
    ${({ theme }) =>
        theme.type === THEMES.OVERVIEW &&
        css`
            text-align: center;
            border-left: 0;
            border-right: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
    `}
`;

export const CheckIcon = styled.span`
    padding-top: 15px;
    padding-right: 15px;
`;
