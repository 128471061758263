/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import axios from 'axios';
import boiler from '@boiler';
import autobind from 'autobind-decorator';
import Modal from '@common/Modal';
import { actions } from './redux';
import i18n from './i18n';
import EndUserChange from './components/EndUserChange';
import KeyAccountChange from './components/KeyAccountChange';

class App extends React.Component {

    @autobind
    onSaveChanges() {
        const { endUserData, keyAccountData, settings, setErrorsAction } = this.props;
        let euData = {},
            kaData = {};

        switch (endUserData.action) {
            case 'invite_eu':
                euData = { email: '', firstname: '', lastname: '', agreed: false };
                euData = {
                    ...euData,
                    ...endUserData.selectedEndUser,
                    isContractHandoverAllowed: !!endUserData.selectedEndUser?.isContractHandoverAllowed?.invite_eu,
                };
                break;
            case 'other_eu':
                euData = { id: '' };
                euData = {
                    ...euData,
                    ...endUserData.selectedEndUser,
                    isContractHandoverAllowed: !!endUserData.selectedEndUser?.isContractHandoverAllowed?.other_eu,
                };
                break;
        }
        switch (keyAccountData.action) {
            case 'other_ka':
                const { selectedKeyAccount, selectedProject } = keyAccountData;
                kaData = {
                    selectedKeyAccount: selectedKeyAccount ? selectedKeyAccount.id || '' : '',
                    selectedProject: selectedProject ? selectedProject.id || '' : '',
                };
                break;
        }

        const payload = {
            keyAccountAction: keyAccountData.action,
            keyAccountData: kaData,
        };
        if (settings.canEditEndUser) {
            payload.endUserAction = endUserData.action;
            payload.endUserData = euData;
        }

        axios.post(settings.saveOwnerUrl, payload)
            .then((response) => {
                const { data } = response;
                if (data.success === true) {
                    window.location.href = data.url;
                }
            })
            .catch((error) => {
                const { success, errors } = error.response.data;
                if (success === false) {
                    setErrorsAction(errors);
                }
            });
    }

    render() {
        const { isSubmitDisabled, settings } = this.props;

        return (
            <Modal
                modalTitle={i18n('modal_title')}
                closeLabel={i18n('close')}
                saveLabel={i18n('save')}
                submit={this.onSaveChanges}
                isSubmitDisabled={isSubmitDisabled}
            >
                <KeyAccountChange />
                {settings.canEditEndUser && <EndUserChange />}
            </Modal>
        );
    }

}

const mapStateToProps = ({ data, settings }) => ({
    settings,
    isSubmitDisabled: data.isSubmitDisabled,
    endUserData: data.endUserData,
    keyAccountData: data.keyAccountData,
});

const mapActionsToProps = {
    setErrorsAction: actions.setErrorsAction,
};

export default boiler.connect(mapStateToProps, mapActionsToProps)(App);
