/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
// This file contains a lot of references to an untyped GoogleMaps utility
// Instead of fixing these types GoolgeMaps.js should be migrated
import GoogleMaps from '@common/GoogleMaps';
import MarkerClusterer from '@common/MarkerClusterer';
import { toGoogleMapsCoordinates, validateCoordinates } from '@tsUtils';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IconMarkers, MAP_STYLE, MONITORING_STATUS } from '../constants';
import i18n from '../i18n';
import { useAppSelector } from '../redux';
import { setErrorAction } from '../redux/actions';

const MAPS_JS_API = '//maps.googleapis.com/maps/api/js';
const mapStyles = { minWidth: '500px', minHeight: '500px' };

const InstallationMap: FC = () => {
    const dispatch = useDispatch();
    const installations = useAppSelector((store) => store.installations);
    const googleAPIkey = useAppSelector((store) => store.settings.googleAPIkey);
    const coordinates = useAppSelector((store) => store.settings.mapCenter);
    const mapRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        let map: unknown;
        const markers: unknown[] | null = [];
        const markerClusters: unknown[] | null = [];
        let currentWindow: unknown | boolean = false;
        let bounds: unknown;

        const refreshMarkers = (): void => {
            // Assign markers
            installations.forEach((installation) => {
                const {
                    latitude,
                    longitude,
                    id,
                    customerName,
                    keyAccountName,
                    productRange,
                    installationUrl,
                    monitoringStatus,
                } = installation;
                const iconMarker = IconMarkers[monitoringStatus || MONITORING_STATUS.no_status];
                const marker = GoogleMaps.createMarker({
                    id,
                    position: {
                        lat: Number(latitude),
                        lng: Number(longitude),
                    },
                    icon: iconMarker,
                    map,
                });

                // Create Info window
                const createInfo = (): string => {
                    const name = customerName || keyAccountName || null;
                    return `<div>
                            ${name ? `<h5>${name}</h5>` : ''}
                            <p>${productRange}</p>
                            <a href=${installationUrl} target="_blank">
                                ${i18n('view_installation')}
                            </a>
                        </div>`;
                };
                const infoWindow = GoogleMaps.createInfoWindow(createInfo());

                marker.addListener('click', () => {
                    // Close current info window before opening a new one
                    if (currentWindow) {
                        currentWindow?.close();
                    }
                    currentWindow = infoWindow;
                    map.panTo(marker.getPosition());
                    infoWindow.open(map, marker);
                });

                bounds.extend({ lat: marker.position.lat(), lng: marker.position.lng() });
                (markers as any[]).push(marker);
            });
            // Support marker clustering
            const clusterOpts = {
                minimumClusterSize: 3,
                imagePath: '../assets/backend/images/clusters/m',
            };
            // Assign Clusterer
            const newMarkerCluster = new MarkerClusterer(map, markers as any[], clusterOpts);
            (markerClusters as any[]).push(newMarkerCluster);
        };

        GoogleMaps.init(googleAPIkey, MAPS_JS_API, 'installation-map');
        GoogleMaps.checkReadiness(async () => {
            let center = null;
            try {
                if (validateCoordinates(coordinates)) {
                    center = toGoogleMapsCoordinates(coordinates);
                    dispatch(setErrorAction(null));
                } else {
                    dispatch(setErrorAction('installation_map_failed'));
                }
            } catch (err) {
                dispatch(setErrorAction('installation_map_failed'));
            }
            // creates a new center point based on the 2 furthers points of the map
            bounds = GoogleMaps.getLatLngBounds();
            // Initialize Map
            const newMap = GoogleMaps.getMap(mapRef.current, {
                center,
                zoom: 8,
                styles: MAP_STYLE,
            });
            map = newMap;
            // If no installations found reset the center of the map
            if (!installations.length) bounds.extend(center);
            refreshMarkers();
            // Make use all markers are shown on the map
            map.fitBounds(bounds);
            // If no installations found set the zoom to original
            if (!installations.length) map.setZoom(8);
        });

        return () => {
            // Clear markers
            (markers as any[]).forEach((marker) => {
                marker.setMap(null);
            });
            // Clear markerClusters
            (markerClusters as any[]).forEach((mc) => {
                mc.clearMarkers();
            });
        };
    }, [installations]);

    return <div className="map" ref={mapRef} style={mapStyles} />;
};

export default InstallationMap;
