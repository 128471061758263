import { TEMPLATE_STEPS, TOOLTIPS } from '../enums';
import { Store, TermsAndConditions } from '../types';

export const getDraftFilePlaceholders = (store: Store): Record<string, null> => {
    const { placeholder } = store.settings;
    const { termsAndConditions } = store.epackage;

    // Editing a new e-package has no terms and conditions
    if (termsAndConditions.length > 0) {
        return Object.fromEntries(termsAndConditions.map((tac) => [tac.language, null]));
    } else {
        return Object.fromEntries(
            Object.keys(placeholder.termsAndConditions).map((tac) => [tac, null]),
        );
    }
};

// set-up data structure to give to logic when saving terms and conditions
export const getDraftTermsPlaceholders = (store: Store): Record<string, string | null> => {
    const { placeholder } = store.settings;
    const { termsAndConditions } = store.epackage;

    // Editing a new e-package has no terms and conditions
    if (termsAndConditions.length === 0) return placeholder.termsAndConditions;

    const hasSavedTerms = termsAndConditions.some((t) => t.text);
    const draft: Record<string, string | null> = {};
    termsAndConditions.forEach(({ text, language }) => {
        draft[language] = hasSavedTerms ? text : '';
    });
    return draft;
};

const getTacPlaceholder = (store: Store, language: string): TermsAndConditions | null => {
    return store.epackage.termsAndConditions.find((d) => d.language === language) || null;
};

// Data structure for rendering
export const getDraftTermsAndConditions = (store: Store): TermsAndConditions[] => {
    const {
        epackage: { termsAndConditions },
        settings: { placeholder },
    } = store;

    // Editing a new e-package has no terms and conditions the placeholders should be rendered then
    if (termsAndConditions.length > 0) {
        return termsAndConditions.map((data) => {
            return data.text
                ? data
                : {
                      ...data,
                      ...getTacPlaceholder(store, data.language),
                  };
        });
    } else {
        return (
            Object.entries(placeholder.termsAndConditions).map(([language, text]) => {
                return { language, text, fileName: null, filePath: null };
            }) || null
        );
    }
};

export const getCurrentTooltip = (store: Store): TOOLTIPS | null => {
    const {
        settings: { shouldRenderTooltips },
        appState: { ignoredTooltips, templateStep },
    } = store;

    if (!shouldRenderTooltips) return null;

    switch (templateStep) {
        case TEMPLATE_STEPS.step_1: {
            if (!ignoredTooltips.includes(TOOLTIPS.tt1)) return TOOLTIPS.tt1;
            if (!ignoredTooltips.includes(TOOLTIPS.tt2)) {
                if (document.querySelector('.tooltip-target-2:first-of-type')) {
                    return TOOLTIPS.tt2;
                }
                return null;
            }
            if (!ignoredTooltips.includes(TOOLTIPS.tt3)) return TOOLTIPS.tt3;
            return null;
        }
        case TEMPLATE_STEPS.step_2: {
            if (!ignoredTooltips.includes(TOOLTIPS.tt4)) return TOOLTIPS.tt4;
            if (!ignoredTooltips.includes(TOOLTIPS.tt5)) return TOOLTIPS.tt5;
            return null;
        }
        case TEMPLATE_STEPS.step_3:
        case TEMPLATE_STEPS.step_4:
        default: {
            return null;
        }
    }
};
