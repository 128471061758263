import React from 'react';
import JoyrideWrapper from './components/JoyrideWrapper';
import ProductsAndPrices from './components/ProductsAndPrices';
import TermsAndConditions from './components/TermsAndConditions';
import Example from './components/Example';
import Activation from './components/Activation';
import { useAppSelector } from './redux';
import i18n from './i18n';
import { AlertStyles } from './styles/alert';

const App = (): JSX.Element => {
    const errors = useAppSelector((store) => store.appState.errors?.form);
    const isFormSaved = useAppSelector((store) => store.appState.isFormSaved);

    return (
        <>
            {isFormSaved && (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="alert alert-success pull-left" style={AlertStyles}>
                            {i18n('success_epackage_activation')}
                            &nbsp;
                            <button
                                type="button"
                                className="close"
                                data-dismiss="alert"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {errors && (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="alert alert-warning pull-left" style={AlertStyles}>
                            {Object.entries(errors).map(([id, error]) => {
                                return <div key={id}>{i18n(error)}</div>;
                            })}
                        </div>
                    </div>
                </div>
            )}
            <JoyrideWrapper />
            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                <ProductsAndPrices />
                <TermsAndConditions />
                <Example />
                <Activation />
            </div>
        </>
    );
};

export default App;
