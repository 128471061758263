export const ACTIONS = Object.freeze({
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    SET_ANIMATION: 'SET_ANIMATION',
    TOGGLE_NOTIFICATION_READ: 'TOGGLE_NOTIFICATION_READ',
    SET_UNREAD_NOTIFICATION_COUNTER: 'SET_UNREAD_NOTIFICATION_COUNTER',
    SET_PAGING: 'SET_PAGING',
    FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATION',
    TOGGLE_SHOW_UNREAD_NOTIFICATIONS: 'TOGGLE_SHOW_UNREAD_NOTIFICATIONS',
});

export const THEMES = Object.freeze({
    WIDGET: 'WIDGET',
    OVERVIEW: 'OVERVIEW',
});

export const WS_RESPONSE_TYPES = Object.freeze({
    REGISTERED: 'feedback',
    UPDATE: 'update',
});
