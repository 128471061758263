import React from 'react';
import axios from 'axios';
import autobind from 'autobind-decorator';
import i18n from '../../i18n';

export default class Link extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            foundKeyAccounts: [],
            searchQuery: '',
            searchedKeyAccounts: false,
        };
    }

    onSelect(e, type, list) {
        const { setKeyAccountDataAction } = this.props;
        this.setState({
            searchedKeyAccounts: false,
        });
        setKeyAccountDataAction({ [type]: list.find(i => i.id === e.target.value) });
    }

    @autobind
    enterPressed(event) {
        if ((event.keyCode || event.which) === 13) {
            this.searchKeyAccount();
        }
    }

    @autobind
    searchKeyAccount() {
        const { resetKeyAccountDataAction, settings } = this.props;

        axios
            .get(settings.fetchKeyAccountsUrl, {
                params: {
                    searchQuery: this.state.searchQuery,
                },
            })
            .then((res) => {
                const { data } = res;
                this.setState({
                    foundKeyAccounts: data || [],
                    searchQuery: '',
                    searchedKeyAccounts: true,
                });
                resetKeyAccountDataAction();
            })
            .catch((error) => {
                console.log(error); // eslint-disable-line no-console
            });
    }

    renderOptions(options) {
        return options.map(o => <option key={o.id} value={o.id}>{o.name}</option>);
    }

    render() {
        const { foundKeyAccounts, searchQuery, searchedKeyAccounts } = this.state;
        const hasSearchResults = (foundKeyAccounts && foundKeyAccounts.length);
        const { keyAccounts, selectedKeyAccount, selectedProject, errors } = this.props;
        const { selectedKeyAccountError, selectedProjectError } = errors;

        return (
            <div className='panel-body'>
                <div className={`form-group ${(selectedKeyAccountError || selectedProjectError) && 'has-error'}`}>
                    <label className='col-sm-3 control-label'>{i18n('key_account')}</label>
                    <div className='col-sm-3'>
                        <select
                            className='form-control'
                            onChange={e => this.onSelect(e, 'selectedKeyAccount', keyAccounts)}
                            value={((foundKeyAccounts.length === 0) && selectedKeyAccount) ? selectedKeyAccount.id : '0'}
                        >
                            <option value='0'>{i18n('select_existing')}</option>
                            {this.renderOptions(keyAccounts)}
                        </select>
                        {selectedKeyAccountError && (
                            <span className='help-block'>
                                <ul className='list-unstyled'>
                                    <li>
                                        <span
                                            className='glyphicon glyphicon-exclamation-sign'
                                        />
                                        {' '}
                                        {selectedKeyAccountError}
                                    </li>
                                </ul>
                            </span>
                        )}
                    </div>
                    <div className='col-lg-1 text-center'>or</div>
                    <div className='col-sm-5'>
                        <div className='input-group'>
                            <input
                                type='text'
                                className='form-control'
                                placeholder={i18n('search_by_name')}
                                onChange={e => this.setState({ searchQuery: e.target.value })}
                                onKeyPress={this.enterPressed}
                                value={searchQuery}
                            />
                            <span
                                className='input-group-btn'
                                onClick={this.searchKeyAccount}
                                role='button'
                                tabIndex={0}
                            >
                                <button className='btn btn-info' type='button'>
                                    <i className='glyphicon glyphicon-search' />
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='form-group'>
                    <label className='col-sm-3 control-label' />
                    <div className='col-sm-9'>
                        {hasSearchResults ? (
                            <div>
                                <h4>
                                    {`${foundKeyAccounts.length} ${i18n('key_accounts_found')}`}
                                </h4>
                                <select
                                    className='form-control'
                                    onChange={e => this.onSelect(e, 'selectedKeyAccount', foundKeyAccounts)}
                                    value={selectedKeyAccount ? selectedKeyAccount.id : '0'}
                                >
                                    <option value='0'>{i18n('select_existing')}</option>
                                    {this.renderOptions(foundKeyAccounts)}
                                </select>
                            </div>
                        ) : (
                            searchedKeyAccounts && <div className='alert alert-warning'>{i18n('can_not_find_ka')}</div>
                        )}
                    </div>
                </div>
                {(selectedKeyAccount && selectedKeyAccount.projects && selectedKeyAccount.projects.length) && (
                    <div className='form-group'>
                        <label className='col-sm-3 control-label'>{i18n('projects')}</label>
                        <div className='col-sm-6'>
                            <select
                                className='form-control'
                                onChange={e => this.onSelect(e, 'selectedProject', selectedKeyAccount.projects)}
                                value={selectedProject ? selectedProject.id : '0'}
                            >
                                <option>{i18n('select')}</option>
                                {this.renderOptions(selectedKeyAccount.projects)}
                            </select>
                        </div>
                    </div>
                )}
            </div>
        );
    }

}
