import React from 'react';
import autobind from 'autobind-decorator';

export default class Accordion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedId: props.items[0].id,
        };
    }

    @autobind
    renderRadioBtn(item) {
        const { onSelectedItem } = this.props;
        const isSelected = (onSelectedItem ? this.props.selectedId : this.state.selectedId) === item.id;
        return (
            <React.Fragment key={item.id}>
                <div
                    role='button'
                    tabIndex={0}
                    className='panel-heading clearfix'
                    onClick={e => {
                        // Fix for double clickHandlers triggered by radio-buttons
                        if (e.target.tagName !== 'INPUT') return;
                        if (onSelectedItem) onSelectedItem(item.id);
                        else this.setState({ selectedId: item.id });
                    }}
                >
                    <div className='radio'>
                        <label type='button' className='label-float'>
                            <input
                                type='radio'
                                className='select-change select-change-eu'
                                checked={isSelected}
                                readOnly
                            />
                            {item.label}
                        </label>
                    </div>
                </div>
                {isSelected && item.content}
            </React.Fragment>
        );
    }

    render() {
        return (
            <div className='panel-group' id='eu' role='tablist' aria-multiselectable='true'>
                <div className='panel panel-default'>
                    {this.props.items.map(item => item && this.renderRadioBtn(item))}
                </div>
            </div>
        );
    }

}
