import React from 'react';
import autobind from 'autobind-decorator';
import i18n from '../../i18n';

export default class Invite extends React.Component {

    onInputChange(key, value) {
        const { setSelectedEndUserAction } = this.props;
        setSelectedEndUserAction({ ...this.props.selectedEndUser, [key]: value });
    }


    onChangeIsHandoverAllowed(isContractHandoverAllowed) {
        this.onInputChange('isContractHandoverAllowed',
            { ...isContractHandoverAllowed,
                invite_eu: !isContractHandoverAllowed?.invite_eu,
            },
        );
    }

    @autobind
    renderTextInput(key) {
        return (
            <input
                className='form-control'
                placeholder={key}
                onChange={e => this.onInputChange(key, e.target.value)}
                value={this.props.selectedEndUser ? this.props.selectedEndUser[key] || '' : ''}
            />
        );
    }

    render() {
        const { agreed, isContractHandoverAllowed } = this.props.selectedEndUser || {};
        const { errors } = this.props;
        const { emailError } = errors;
        const isHandOverAllowed = !!isContractHandoverAllowed?.invite_eu;

        return (
            <div className='panel-body'>
                <div className='form-group private'>
                    <label className='col-sm-3 control-label'>{i18n('name')}</label>
                    <div className='col-sm-4'>
                        {this.renderTextInput('firstname')}
                    </div>
                    <div className='col-sm-4'>
                        {this.renderTextInput('lastname')}
                    </div>
                </div>
                <div className={`form-group required ${emailError && 'has-error'}`}>
                    <label className='col-sm-3 control-label'>{i18n('email')}</label>
                    <div className='col-sm-8'>
                        {this.renderTextInput('email')}
                        {emailError && (
                            <span className='help-block'>
                                <ul className='list-unstyled'>
                                    <li>
                                        <span className='glyphicon glyphicon-exclamation-sign' />
                                        {' '}
                                        {emailError}
                                    </li>
                                </ul>
                            </span>
                        )}
                    </div>
                </div>
                <div className='form-group private'>
                    <label className='col-sm-3 control-label' />
                    <div className='col-sm-8'>
                        <div className='checkbox'>
                            <label>
                                <input
                                    type='checkbox'
                                    onChange={() => this.onInputChange('agreed', !agreed)}
                                    checked={agreed ? 'checked' : ''}
                                />
                                {i18n('allow_emailaddress')}
                            </label>
                        </div>
                        <div className='checkbox'>
                            <label>
                                <input
                                    type='checkbox'
                                    onChange={() => {
                                        this.onChangeIsHandoverAllowed(isContractHandoverAllowed);
                                    }}
                                    checked={isHandOverAllowed}
                                />
                                {i18n('allow_contract_handover')}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
