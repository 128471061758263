import { Store, FilterType } from '../../types';
import { FILTERS } from '../../constants';

export const getFilter = (store: Store, filterkey: string): FilterType | undefined => {
    return store.settings.filters.find((filter) => filter.name === filterkey);
};

export const getFilterSelection = (store: Store, filterKey: string): string => {
    return store.data.filterSelection[filterKey as FILTERS];
};

export const getApiQuery = (store: Store): string => {
    const { installationSearch } = store.settings.urls;
    const { filterSelection, searchQuery } = store.data;
    const params = [];

    Object.entries(filterSelection).forEach(([key, value]) => {
        if (getFilter(store, key)?.name && value) params.push(`filters[${key}]=${value}`);
    });
    if (searchQuery) params.push(`filters[search-map]=${searchQuery}`);

    return `${installationSearch}${params.length ? `?${params.join('&')}` : ''}`;
};
