import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FilterType } from '../types';
import { setFilterValueAction } from '../redux/actions';
import { getFilterSelection } from '../redux/selectors';
import { useAppSelector } from '../redux';

type PropsTypes = { filter: FilterType };

const Filter: FC<PropsTypes> = ({ filter }) => {
    const { name, label: filterLabel, options } = filter;
    const dispatch = useDispatch();
    const selectedValue = useAppSelector((store) => getFilterSelection(store, name));

    return (
        <div className="pb-4" key={name}>
            <label htmlFor={`${name}-filter`}>{filterLabel}</label>
            <select
                id={`${filter.name}-filter`}
                className="form-control"
                value={selectedValue || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    dispatch(setFilterValueAction({ [name]: e.target.value }));
                }}
            >
                {Object.entries(options).map(([label, key]) => (
                    <option key={key} value={key}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Filter;
