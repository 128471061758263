import boiler from '@boiler';
import { createLogicMiddleware } from 'redux-logic';
import App from './App';
import i18n from './i18n';
import { parseCompany } from './utils';
import store from './store';
import logic from './logic';

const mapInputsToState = json => {
    // Pass labels to i18n
    i18n.set(json.labels);
    // Normalize companies
    return {
        store: {
            id: null,
            urls: json.settings.urls,
            knownCompanies: json.settings.availableProfessionals.map(parseCompany),
        },
    };
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(
        App,
        'send-to-professional',
        { store },
        mapInputsToState,
        [createLogicMiddleware(logic)],
    );
}());
