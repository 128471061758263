import React from 'react';
import { KEYS, addShortcut, removeShortcut } from '../../KeyboardListener';
import Postal from '../../Postal';
import PopoverView from './PopoverView';

// Note: Everything is nested in a data-object because some values are not overwritten
const CLEAR = {
    show: false,
    data: {
        title: null,
        content: null,
        positioning: null,
        triggerDimensions: null,
    },
};

export default class Popover extends React.PureComponent {

    constructor(props) {
        super(props);
        // Default state
        this.state = CLEAR;
        // Listen for escape presses
        addShortcut([KEYS.escape], this.closePopover);
    }

    componentDidMount() {
        Postal.smartSubscribe(
            this,
            { event: 'SHOW_POPOVER', handler: this.showPopover },
            { event: 'CLOSE_POPOVER', handler: this.closePopover },
        );
        // We need to do this in order ensure correct Popover position
        this.forceUpdate();
    }

    componentWillUnmount() {
        Postal.smartUnsubscribe(this);
        removeShortcut(this.closePopover);
    }

    showPopover = (data) => {
        this.setState({ show: true, data });
    };

    closePopover = () => {
        this.setState(CLEAR);
        removeShortcut(this.closePopover);
    };

    render() {
        const { show, data } = this.state;
        const {
            title,
            content,
            positioning,
            triggerDimensions,
            lineHeight,
            fontSize,
        } = data;
        return (show && (
            <PopoverView
                title={title}
                content={content}
                positioning={positioning}
                triggerDimensions={triggerDimensions}
                lineHeight={lineHeight}
                fontSize={fontSize}
            />
        ));
    }

}
