import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import i18n from '../i18n';
import { useAppSelector } from '../redux';
import { setSearchQueryAction, fetchInstallationsAction } from '../redux/actions';
import Filter from './Filter';

const Filters: FC = () => {
    const dispatch = useDispatch();
    // Store data
    const filters = useAppSelector((store) => store.settings.filters);
    const searchQuery = useAppSelector((store) => store.data.searchQuery);

    return (
        <div className="form well clearfix">
            {filters.map((filter) => (
                <Filter key={filter.name} filter={filter} />
            ))}
            <div className="pb-4">
                <label htmlFor="search-filter">{i18n('search')}</label>
                <input
                    id="search-filter"
                    className="form-control"
                    type="text"
                    placeholder={i18n('search_description')}
                    value={searchQuery || ''}
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') dispatch(fetchInstallationsAction());
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch(setSearchQueryAction(e.target.value));
                    }}
                />
            </div>
            <button
                className="btn btn-info"
                type="submit"
                onClick={() => dispatch(fetchInstallationsAction())}
            >
                {i18n('search')}
            </button>
        </div>
    );
};

export default Filters;
