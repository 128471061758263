import { TsBoolean } from '@tsUtils';
import { INDETERMINATE_STATES } from '../../constants';
import { Installation, State } from '../../types';

const getIndermedinateCheckboxState = (state: State): INDETERMINATE_STATES => {
    const { installations, selected } = state.installationsData;
    if (installations?.length === 0) return INDETERMINATE_STATES.UNCHECKED;
    if (selected?.length === 0) return INDETERMINATE_STATES.UNCHECKED;
    if (selected?.length === installations?.length) return INDETERMINATE_STATES.CHECKED;
    return INDETERMINATE_STATES.INDETERMINATE;
};

const getFilteredInstallations = (state: State): Array<Installation> => {
    const { searchInput } = state.appState;
    const { filteredInstallationIds, installations } = state.installationsData;

    if (!searchInput) return installations;

    return filteredInstallationIds
        .map((id) => installations.find((installation) => installation.id === id))
        .filter(TsBoolean);
};

export default {
    getIndermedinateCheckboxState,
    getFilteredInstallations,
};
