/* eslint-disable func-names */
import boiler from '@boiler';
import { createLogicMiddleware } from 'redux-logic';
import App from './App';
import reducers from './redux/reducers';
import i18n from './i18n';
import logic from './redux/logic';

const mapInputToState = (input) => {

    const { labels, data, settings } = input;
    i18n.set(labels);
    const { filters, searchQuery } = settings;

    const filterSelection = {};
    filters.forEach((filter) => {
        filterSelection[filter.name] = filter.value || '';
    });

    return {
        data: {
            ...data,
            filterSelection,
            searchQuery,
        }, settings
    };
};

(function () {
    boiler.initAppInstances(App, 'installation-map', reducers, mapInputToState, [createLogicMiddleware(logic)]);
}());
