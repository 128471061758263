import styled from 'styled-components';

export const ListItem = styled.div`
    padding-top: 0;
    border-top: 0;
    padding: 2rem 0 2rem 0;
    border-top: 1px solid #eee;
    margin: 0;

    &:first-child {
        border-top: 0;
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
`;

export const RadioLabel = styled.label`
    position: relative;
    padding-left: 2.5rem;
    font-family: "Roboto",sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 2rem;
    text-transform: none;
    cursor: pointer;
    color: #6C8EA4;
    display: inline-block;
    margin-bottom: .5rem;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before {
        display: inline-block;
        position: absolute;
        top: .3rem;
        left: 0;
        width: 1.5rem;
        height: 1.5rem;
        background-color: #fff;
        border: 1px solid #C4CFD5;
        border-radius: 8px;
        content: '';
    }
`;

export const RadioInput = styled.input`
    display: none;
    &:checked + ${RadioLabel} {
        &::before {
            background-color: #fff;
            border-width: 4px;
            border-color: #09C;
        };
    }
`;
