import boiler from '@boiler';
import App from './App';
import data from './redux/data';
import settings from './redux/settings';
import labels from './redux/labels';
import middleware from './redux/middleware';

const mapInputValueToState = (state, input) => {
    try {
        return { ...state, data: { ...state.data || {}, totalFieldCharge: input } };
    } catch (e) {
        return state;
    }
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(
        App,
        'refrigerant-type-calculator',
        { data, settings, labels },
        mapInputValueToState,
        middleware,
    );
}());
