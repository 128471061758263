import { Installation } from '../../types';

export enum INSTALLATIONS_ACTIONS {
    FETCH = 'installations/fetch',
    SET = 'installations/set',
    SELECT_ALL_INSTALLATIONS = 'installations/select_all_installations',
    SELECT_INSTALLATION = 'installations/select_installation',
    SAVE = 'installations/save',
    SET_SEARCH_INPUT = 'installations/set_search_input',
    SET_FILTERED_INSTALLATION_IDS = 'installations/set_filtered_installation_ids',
}

export type FetchInstallationsAction = { type: INSTALLATIONS_ACTIONS.FETCH };
const fetchInstallations = (): FetchInstallationsAction => ({
    type: INSTALLATIONS_ACTIONS.FETCH,
});

export type SetInstallationsAction = {
    type: INSTALLATIONS_ACTIONS.SET;
    installations: Array<Installation>;
};
const setInstallations = (installations: Array<Installation>): SetInstallationsAction => ({
    type: INSTALLATIONS_ACTIONS.SET,
    installations,
});

export type SelectAllInstallationsAction = {
    type: INSTALLATIONS_ACTIONS.SELECT_ALL_INSTALLATIONS;
    selectAll: boolean;
};
const selectAllInstallations = (selectAll: boolean): SelectAllInstallationsAction => ({
    type: INSTALLATIONS_ACTIONS.SELECT_ALL_INSTALLATIONS,
    selectAll,
});

export type SelectInstallationAction = {
    type: INSTALLATIONS_ACTIONS.SELECT_INSTALLATION;
    installationId: string;
};
const selectInstallation = (installationId: string): SelectInstallationAction => ({
    type: INSTALLATIONS_ACTIONS.SELECT_INSTALLATION,
    installationId,
});

export type SaveInstallationsAction = {
    type: INSTALLATIONS_ACTIONS.SAVE;
};
const saveInstallations = (): SaveInstallationsAction => ({
    type: INSTALLATIONS_ACTIONS.SAVE,
});

export type SetSearchInputAction = {
    type: INSTALLATIONS_ACTIONS.SET_SEARCH_INPUT;
    input: string;
};
const setSearchInput = (input: string): SetSearchInputAction => ({
    type: INSTALLATIONS_ACTIONS.SET_SEARCH_INPUT,
    input,
});

export type SetFilteredInstallationIdsAction = {
    type: INSTALLATIONS_ACTIONS.SET_FILTERED_INSTALLATION_IDS;
    installationIds: Array<string>;
};
const setFilteredInstallationIds = (installationIds: Array<string>): SetFilteredInstallationIdsAction => ({
    type: INSTALLATIONS_ACTIONS.SET_FILTERED_INSTALLATION_IDS,
    installationIds,
});

export type InstallationsActions =
    | FetchInstallationsAction
    | SetInstallationsAction
    | SelectAllInstallationsAction
    | SelectInstallationAction
    | SaveInstallationsAction
    | SetSearchInputAction
    | SetFilteredInstallationIdsAction;

export default {
    fetchInstallations,
    setInstallations,
    selectAllInstallations,
    selectInstallation,
    saveInstallations,
    setSearchInput,
    setFilteredInstallationIds,
};
