import React from 'react';

const Dropdown = ({ title, users, onSelect }) => (
    <React.Fragment>
        <p>{title}</p>
        <select className='form-control' onChange={e => onSelect(e.target.value)}>
            {users.map(user => <option value={user.id} key={user.id}>{user.fullName}</option>)}
        </select>
    </React.Fragment>
);

export default Dropdown;
