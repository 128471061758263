/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import autobind from 'autobind-decorator';
import axios from 'axios';
import boiler from '@boiler';
import Modal from '@common/Modal';
import { actions } from './redux/data';
import Dropdown from './components/Dropdown';

class App extends React.Component {

    componentDidMount() {
        const { initApp } = this.props;

        document.addEventListener('delete-professional-user-delete', e => {
            initApp(e.detail.userId);
        });
    }

    @autobind
    onSaveChanges() {
        const { settings, user, mainUser, deleteUser, disableSubmit } = this.props;

        disableSubmit(true);

        axios.post(settings.delete_url, {
            professionalUserToDelete: deleteUser,
            professionalUserToReceiveInstallations: user,
            newProfessionalMainUser: mainUser,
        })
            .then((response) => {
                const { data } = response;
                if (data.success === true) {
                    window.location.href = data.url;
                }
            })
            .catch((error) => {
                console.log(error); // eslint-disable-line no-console
            });
    }

    render() {
        const { labels, isMainUser, selectUser, selectMainUser, users, deleteUser, isSubmitDisabled } = this.props;

        return (
            <Modal
                modalTitle={labels.modal_title}
                closeLabel={labels.close}
                saveLabel={labels.save}
                submit={this.onSaveChanges}
                isSubmitDisabled={isSubmitDisabled}
            >
                <Dropdown
                    title={labels.user_select_title}
                    users={users.filter(u => u.id !== deleteUser)}
                    onSelect={selectUser}
                />
                {isMainUser && (
                    <React.Fragment>
                        <br />
                        <Dropdown
                            title={labels.main_user_select_title}
                            users={users.filter(u => u.id !== deleteUser && u.isActive)}
                            onSelect={selectMainUser}
                        />
                    </React.Fragment>
                )}
            </Modal>
        );
    }

}

const mapStateToProps = ({ data, labels, settings }) => ({
    users: data.users,
    isMainUser: data.isMainUser,
    user: data.user,
    mainUser: data.mainUser,
    deleteUser: data.deleteUser,
    isSubmitDisabled: data.isSubmitDisabled,
    labels,
    settings,
});

const mapActionsToProps = {
    selectUser: actions.selectUser,
    selectMainUser: actions.selectMainUser,
    disableSubmit: actions.disableSubmit,
    initApp: actions.initApp,
};

export default boiler.connect(mapStateToProps, mapActionsToProps)(App);
