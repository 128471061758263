// Action types
const SELECT_USER = 'SELECT_USER';
const SELECT_MAIN_USER = 'SELECT_MAIN_USER';
const INIT_APP = 'INIT_APP';
const DISABLE_SUBMIT = 'DISABLE_SUBMIT';

// Default state
const initialState = {
    deleteUser: null,
    isMainUser: false,
    user: null,
    mainUser: null,
    isSubmitDisabled: false,
};

// Action creators
export const actions = {
    selectUser: user => ({ type: SELECT_USER, data: user }),
    selectMainUser: mainUser => ({ type: SELECT_MAIN_USER, data: mainUser }),
    initApp: user => ({ type: INIT_APP, data: user }),
    disableSubmit: isSubmitDisabled => ({ type: DISABLE_SUBMIT, data: isSubmitDisabled }),
};

// Reducer
export default function data(state, action) {

    switch (action.type) {

        case SELECT_USER:
            return { ...state, user: action.data };

        case SELECT_MAIN_USER:
            return { ...state, mainUser: action.data };

        case DISABLE_SUBMIT:
            return { ...state, isSubmitDisabled: action.data };

        case INIT_APP:
            const id = action.data;
            const newState = {
                deleteUser: id,
                isMainUser: state.users.find(u => u.id === id).isMainUser,
                user: state.users.find(u => u.id !== id).id,
                isSubmitDisabled: false,
            };
            newState.mainUser = newState.isMainUser ? newState.user : null;
            return { ...state, ...newState };

        default:
            return { ...initialState, ...state };
    }
}
