import { TOOLTIPS } from '../../enums';
import { Errors } from '../../types';

export enum ACTIONTYPES {
    SET_ERRORS = 'appState.SET_ERRORS',
    SET_TEMPLATE_STEP = 'appState.SET_TEMPLATE_STEP',
    IGNORE_TOOLTIP = 'appState.IGNORE_TOOLTIP',
    SET_SAVED_STATUS = 'appState.SET_SAVED_STATUS',
}

type SetErrorsAction = {
    type: ACTIONTYPES.SET_ERRORS;
    errors: Errors;
};
const setErrors = (errors: Errors): SetErrorsAction => ({
    type: ACTIONTYPES.SET_ERRORS,
    errors,
});

type SetTemplateStepAction = {
    type: ACTIONTYPES.SET_TEMPLATE_STEP;
    templateStep: number | null;
};
const setTemplateStep = (templateStep: number | null): SetTemplateStepAction => ({
    type: ACTIONTYPES.SET_TEMPLATE_STEP,
    templateStep,
});

type IgnoreTooltipAction = {
    type: ACTIONTYPES.IGNORE_TOOLTIP;
    tooltip: TOOLTIPS;
};
const ignoreTooltip = (tooltip: TOOLTIPS): IgnoreTooltipAction => ({
    type: ACTIONTYPES.IGNORE_TOOLTIP,
    tooltip,
});

type SetFormSavedAction = {
    type: ACTIONTYPES.SET_SAVED_STATUS;
    isFormSaved: boolean;
};
const setFormSaved = (isFormSaved: boolean): SetFormSavedAction => ({
    type: ACTIONTYPES.SET_SAVED_STATUS,
    isFormSaved,
});

export type AppStateActions =
    | SetErrorsAction
    | SetTemplateStepAction
    | IgnoreTooltipAction
    | SetFormSavedAction;

export default {
    setErrors,
    setTemplateStep,
    ignoreTooltip,
    setFormSaved,
};
