import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectCompany } from '../store';
import DropDown from '../controls/DropDown';
import { ListItem, RadioInput, RadioLabel } from './SearchResults.styles';

const SearchResults = () => {

    const dispatch = useDispatch();

    const companies = useSelector(({ store }) => store.companies);
    const selectedUserId = useSelector(({ store }) => store.selectedUserId);
    return (
        <div>
            {companies.map(({ id, label, users }) => {
                const selectedCompanyUser = users.find(u => u.id === selectedUserId);
                const mainCompanyUser = users.find(u => u.isMainUser);
                const firstCompanyUser = users[0];
                return (
                    <ListItem key={id}>
                        <div className='row'>
                            <div className='col-md-6' style={{ marginTop: '1rem' }}>
                                <RadioInput
                                    className='radio-input'
                                    type='radio'
                                    id={`companyName-${id}`}
                                    name={`companyName-${id}`}
                                    value={id}
                                    checked={!!selectedCompanyUser}
                                    onChange={() => dispatch(selectCompany(id))}
                                />
                                <RadioLabel htmlFor={`companyName-${id}`} className='radio-label'>
                                    {label}
                                </RadioLabel>
                            </div>
                            <div className='col-md-6'>
                                <DropDown
                                    label={(selectedCompanyUser || mainCompanyUser || firstCompanyUser).label || ''}
                                    items={users}
                                    callback={user => dispatch(selectUser(user.id))}
                                />
                            </div>
                        </div>
                    </ListItem>
                );
            })}
        </div>
    );
};

export default SearchResults;
