import { dedupeCoords } from '@tsUtils';
import axios from 'axios';
import { createLogic } from 'redux-logic';
import { Store } from '../../types';
import {
    FetchInstallationsActionType,
    INSTALLATIONS_ACTIONS,
    setInstallationsAction,
} from '../actions';
import { getApiQuery } from '../selectors';

type DepObj = {
    getState: () => Store;
    action: FetchInstallationsActionType;
};

const fetchInstallationsLogic = createLogic({
    name: 'installations.fetchInstallationsLogic',
    type: INSTALLATIONS_ACTIONS.fetchInstallations,
    process({ getState }: DepObj, dispatch, done) {
        axios
            .get(getApiQuery(getState()))
            .then((res) => {
                const { status, data } = res;
                dedupeCoords(data);
                if (status === 200 && data) dispatch(setInstallationsAction(data));
                done();
            })
            .catch((err) => {
                // TODO
                console.log(err);
            })
            .finally(done);
    },
});

export default [fetchInstallationsLogic];
