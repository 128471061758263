import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Icons from '@common/icons';
import { useAppSelector } from '../redux/index';
import actions from '../redux/actions';
import i18n from '../i18n';
import ProductItem from './ProductItem';
import { Product } from '../types';
import { TEMPLATE_STEPS } from '../enums';
import * as InfoTooltipStyles from '../styles/InfoTooltip';
import { AlertStyles } from '../styles/alert';

type Positions = 'right' | 'top' | 'left' | 'bottom';

const ProductsAndPrices = (): JSX.Element => {
    const dispatch = useDispatch();
    const productsAndPrices = useAppSelector((store) => store.epackage.productsAndPrices);
    const errors = useAppSelector((store) => store.appState.errors?.productsAndPrices);
    const templateStep = useAppSelector((store) => store.appState.templateStep);
    const formErrors = useAppSelector((store) => store.appState.errors?.form);
    const isActive = useAppSelector((store) => store.epackage.isActive);
    const impersonation = useAppSelector((store) => store.settings.impersonation);
    const [panelOpen, setPanelOpen] = useState<boolean>(templateStep === TEMPLATE_STEPS.step_1);

    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const [draftProductsAndPrices, setDraftProductsAndPrices] =
        useState<Product[]>(productsAndPrices);
    const [popoverPosition, setPopoverPosition] = useState<Positions>('right');

    const panelClass = formErrors?.['epackageGroupId.products_and_prices']
        ? 'panel-danger'
        : 'panel-default';

    useEffect(() => {
        setPanelOpen(templateStep === TEMPLATE_STEPS.step_1);
    }, [templateStep]);

    const updateProduct = (id: string, data: Partial<Product>): void => {
        setDraftProductsAndPrices(
            draftProductsAndPrices.map((product) => {
                return id === product.id ? { ...product, ...data } : product;
            }),
        );
    };

    window.addEventListener('resize', () => {
        setPopoverPosition(document.body.clientWidth < 768 ? 'top' : 'right');
    });

    return (
        <div className={`panel ${panelClass}`}>
            <div className="panel-heading" role="tab" id="heading-prices">
                <h4 className="panel-title">
                    <a
                        role="button"
                        className="collapse in"
                        data-parent="#accordion"
                        href="#collapse-prices"
                        aria-expanded="true"
                        aria-controls="collapse-prices"
                        onClick={() => {
                            // Open/Close template step 1
                            setPanelOpen(!panelOpen);
                            dispatch(actions.appState.setTemplateStep(panelOpen ? null : 1));
                        }}
                    >
                        {i18n('step_1_your_products_and_prices')}
                    </a>
                </h4>
            </div>
            <div
                id="collapse-prices"
                className={`panel-collapse collapse ${panelOpen ? 'in' : ''}`}
                aria-labelledby="heading-prices"
                aria-expanded={panelOpen}
            >
                <div className="panel-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="alert alert-info pull-right">
                                {i18n('products_and_prices_help_message')}
                            </span>
                        </div>
                    </div>
                    <br />

                    {errors && (
                        <>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div
                                        className="alert alert-warning pull-left"
                                        style={AlertStyles}
                                    >
                                        {Object.entries(errors).map(([id, error]) => {
                                            return <p key={id}>{i18n(error)}</p>;
                                        })}
                                    </div>
                                </div>
                            </div>
                            <br />
                        </>
                    )}

                    <div className="row">
                        <div className="col-lg-12">
                            <table className="table table-edit">
                                <thead>
                                    <tr>
                                        <th>{i18n('active')}</th>
                                        <th>{i18n('product_range')}</th>
                                        <th>
                                            {i18n('fee')}
                                            &nbsp;
                                            <span
                                                style={{
                                                    position: 'relative',
                                                    display: 'inline-block',
                                                    height: '20px',
                                                }}
                                            >
                                                <a
                                                    onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                                                    onMouseEnter={() => setIsTooltipOpen(true)}
                                                    onMouseLeave={() => setIsTooltipOpen(false)}
                                                >
                                                    <Icons.Glyph.InfoCircle />
                                                </a>
                                                {isTooltipOpen && (
                                                    <InfoTooltipStyles.Body
                                                        className={`popover fade ${popoverPosition} ${
                                                            isTooltipOpen ? 'in' : ''
                                                        }`}
                                                        role="tooltip"
                                                        id="popover"
                                                    >
                                                        <InfoTooltipStyles.Arrow className="arrow" />
                                                        <h3 className="popover-title">
                                                            {i18n('yearly_warranty_fee_title')}
                                                        </h3>
                                                        <div className="popover-content">
                                                            {i18n('yearly_warranty_fee_content')}
                                                        </div>
                                                    </InfoTooltipStyles.Body>
                                                )}
                                            </span>
                                            <br />
                                            <small>{i18n('yearly_warranty_fee_subtitle')}</small>
                                        </th>
                                        <th>
                                            {i18n('your_price')}
                                            <br />
                                            <small>
                                                {i18n('recurring_excl_transportation_cost')}
                                            </small>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {draftProductsAndPrices.map((product, index) => {
                                        return (
                                            <ProductItem
                                                key={product.id}
                                                productId={product.id}
                                                productData={product}
                                                setProductPrice={(productId, data) => {
                                                    updateProduct(productId, data);
                                                }}
                                                error={
                                                    errors?.[`productsAndPrices[${index}].price`] ||
                                                    null
                                                }
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {/* User can't save products and prices when impersonating prof and package is active */}
                            <button
                                type="button"
                                id="tooltip-target-3"
                                className="btn btn-primary"
                                disabled={impersonation && isActive}
                                onClick={
                                    impersonation && isActive
                                        ? undefined
                                        : () => {
                                              dispatch(
                                                  actions.epackage.saveProductsAndPrices(
                                                      draftProductsAndPrices,
                                                  ),
                                              );
                                          }
                                }
                            >
                                {i18n('save_all')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductsAndPrices;
