export enum KeyAccountActions {
    no_change = 'no_change',
    other_ka = 'other_ka',
    remove_ka = 'remove_ka',
}

export enum EndUserActions {
    no_change = 'no_change',
    other_eu = 'other_eu',
    invite_eu = 'invite_eu',
    remove_eu = 'remove_eu',
}

type MetaData = {
    id?: string;
    outputName?: string;
    outputNode?: HTMLElement;
};

export type Settings = MetaData & {
    fetchKeyAccountsUrl: string;
    fetchCustomersUrl: string | null; // null <- Can't change end-user on prof portal
    saveOwnerUrl: string;
    canEditEndUser: boolean;
    canDeleteKeyAccount: boolean;
};

type KeyAccount = {
    id: string;
    name: string;
};

export type KeyAccountData = {
    action: KeyAccountActions;
    hasKeyAccount: boolean | null;
    selectedKeyAccount: KeyAccount | null;
    selectedProject: unknown | null;
    errors: Record<string, string>;
    keyAccounts: KeyAccount[];
};

type ExistingEndUser = {
    id: string;
    name: string;
};

type NewEndUser = {
    firstname: string;
    lastname: string;
    email: string;
    agreed: boolean;
};

export type EndUser = {
    isContractHandoverAllowed: {
        other_eu: boolean;
        invite_eu: boolean;
    };
} & (ExistingEndUser | NewEndUser);

export type EndUserData = {
    action: EndUserActions;
    hasEndUser: boolean | null;
    selectedEndUser: EndUser | null;
    errors: Record<string, string>;
};

export type Store = {
    settings: Settings;
    data: {
        keyAccountData: KeyAccountData;
        endUserData: EndUserData;
    };
};
