/* eslint-disable quote-props */

const i18n = (k: string, returnKeyAsFallback = true): string => {
    if (!k) return '';
    const key = k.replace(/-/g, '_');
    let label: string | null = null;
    if (i18n.labels) {
        label = i18n.labels[key];
        if (!label && returnKeyAsFallback) label = key;
        return label;
    } else {
        return key;
    }
};

i18n.set = (labels: Record<string, string>): void => {
    i18n.labels = { ...i18n.labels, ...labels };
};

export default i18n;

// Set default labels
i18n.labels = {} as Record<string, string>;
