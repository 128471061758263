import { Epackage, Product, FormDataTermsAndConditions } from '../../types';

export enum ACTIONTYPES {
    SAVE_PRODUCTS_AND_PRICES = 'epackage.SAVE_PRODUCTS_AND_PRICES',
    SET_EPACKAGE = 'epackage.SET_EPACKAGE',
    FETCH_EPACKAGE_EXAMPLE = 'epackage.FETCH_EPACKAGE_EXAMPLE',
    SET_EPACKAGE_EXAMPLE = 'epackage.SET_EPACKAGE_EXAMPLE',
    SAVE_TERMS_AND_CONDITIONS = 'epackage.SAVE_TERMS_AND_CONDITIONS',
    DELETE_TERMS_AND_CONDITIONS_FILE = 'epackage.DELETE_TERMS_AND_CONDITIONS_FILE',
    SAVE_STATUS = 'epackage.SAVE_STATUS',
}

export type SaveProductsAndPricesAction = {
    type: ACTIONTYPES.SAVE_PRODUCTS_AND_PRICES;
    products: Product[];
};
const saveProductsAndPrices = (products: Product[]): SaveProductsAndPricesAction => ({
    type: ACTIONTYPES.SAVE_PRODUCTS_AND_PRICES,
    products,
});

type SetEpackageAction = {
    type: ACTIONTYPES.SET_EPACKAGE;
    epackage: Epackage;
};
const setEpackage = (epackage: Epackage): SetEpackageAction => ({
    type: ACTIONTYPES.SET_EPACKAGE,
    epackage,
});

export type FetchEpackageExampleAction = {
    type: ACTIONTYPES.FETCH_EPACKAGE_EXAMPLE;
    language: string;
};

const fetchEpackageExample = (language: string): FetchEpackageExampleAction => ({
    type: ACTIONTYPES.FETCH_EPACKAGE_EXAMPLE,
    language,
});

export type SetEpackageExampleAction = {
    type: ACTIONTYPES.SET_EPACKAGE_EXAMPLE;
    epackageExample: string; // html string
};

const setEpackageExample = (epackageExample: string): SetEpackageExampleAction => ({
    type: ACTIONTYPES.SET_EPACKAGE_EXAMPLE,
    epackageExample,
});

export type SaveTermsAndConditionsAction = {
    type: ACTIONTYPES.SAVE_TERMS_AND_CONDITIONS;
    data: FormDataTermsAndConditions;
};
const saveTermsAndConditions = (
    data: FormDataTermsAndConditions,
): SaveTermsAndConditionsAction => ({
    type: ACTIONTYPES.SAVE_TERMS_AND_CONDITIONS,
    data,
});

export type DeleteTermsAndConditionsFileAction = {
    type: ACTIONTYPES.DELETE_TERMS_AND_CONDITIONS_FILE;
    language: string;
};
const deleteTermsAndConditionsFile = (language: string): DeleteTermsAndConditionsFileAction => ({
    type: ACTIONTYPES.DELETE_TERMS_AND_CONDITIONS_FILE,
    language,
});

export type SaveStatusAction = {
    type: ACTIONTYPES.SAVE_STATUS;
    isActive: boolean;
};
const saveStatus = (isActive: boolean): SaveStatusAction => ({
    type: ACTIONTYPES.SAVE_STATUS,
    isActive,
});

export type EpackageActions =
    | SaveProductsAndPricesAction
    | SetEpackageAction
    | FetchEpackageExampleAction
    | SetEpackageExampleAction
    | SaveTermsAndConditionsAction
    | DeleteTermsAndConditionsFileAction
    | SaveStatusAction;

export default {
    saveProductsAndPrices,
    setEpackage,
    fetchEpackageExample,
    setEpackageExample,
    saveTermsAndConditions,
    deleteTermsAndConditionsFile,
    saveStatus,
};
