import { AppState } from '../../types';
import { Actions } from '../actions';
import { APP_STATE_ACTIONS } from '../actions/appState';
import { INSTALLATIONS_ACTIONS } from '../actions/installations';

const initialState: AppState = {
    isOverlayOpen: false,
    isLoading: false,
    isSaving: false,
    searchInput: null,
};

const appState = (state: AppState, action: Actions): AppState => {
    switch (action.type) {
        case APP_STATE_ACTIONS.OPEN_OVERLAY:
            return { ...state, isOverlayOpen: true };
        case APP_STATE_ACTIONS.CLOSE_OVERLAY:
            return initialState;
        case INSTALLATIONS_ACTIONS.FETCH:
            return { ...state, isLoading: true };
        case INSTALLATIONS_ACTIONS.SET:
            return { ...state, isLoading: false };
        case INSTALLATIONS_ACTIONS.SAVE:
            return { ...state, isSaving: true };
        case INSTALLATIONS_ACTIONS.SET_SEARCH_INPUT:
            return { ...state, searchInput: action.input };
        default:
            return state || initialState;
    }
};

export default appState;
