// Action types

// Default state
const initialState = {};

// Action creators
export const actions = {};

// Reducer
export default function data(state, action) {

    switch (action.type) {
        default:
            return { ...initialState, ...state };
    }

}
