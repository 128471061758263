import axios from 'axios/index';
import { STATUS } from '@common/atoms/VerificationIcon';
import { SET_SERIAL_NUMBER, SET_UNIT_CODE, setVerification } from './actions';

const verify = (input, settings, next) => {
    const data = {
        serial: input.serialNumber,
        material: input.unitCode,
        country: settings.country,
    };
    // Validate
    axios
        .post(settings.fetchEquipmentVerification, data)
        .then(res => {
            if (res.status === 200) {
                // Dispatch verification result action
                const { Message, isSoldToCorrectSalesOrganization, alreadyPurchased } = res.data;
                const hasError = Message || !isSoldToCorrectSalesOrganization || alreadyPurchased;
                next(setVerification({
                    status: hasError ? STATUS.ERROR : STATUS.SUCCESS,
                    error: Message || null,
                }));
            } else {
                next(setVerification({ status: STATUS.ERROR, error: null }));
            }
        })
        .catch(() => {
            next(setVerification({ status: STATUS.ERROR, error: null }));
        });
};

// Debounce edit-actions based on unit-id so they don't cancel eachother
let debouncedVerification = null;
const callDebouncedVerify = (input, settings, next) => {
    // Clear previous timeout if available
    if (debouncedVerification) clearTimeout(debouncedVerification);
    // Wait 1 second before making a verification call (users might be typing fast)
    debouncedVerification = setTimeout(() => verify(input, settings, next), 1000);
};

const equipmentVerificationMiddleware = store => next => action => {
    // Forward to next middleware
    next(action);
    // Check actionType
    if (action.type === SET_SERIAL_NUMBER || action.type === SET_UNIT_CODE) {
        // Fetch data from state & action
        const { input, settings } = store.getState();
        // Check if we can actually validate
        if (!settings.evEnabled) return;
        if (!settings.fetchEquipmentVerification) return;
        if (!input.serialNumber) return;
        if (!input.unitCode) return;
        // Ignore these unit for check
        if (input.unitType === 'lan_adapter') return;
        if (input.unitType === 'solar_pump') return;
        if (input.unitType === 'gateway') return;
        // Dispatch verification pending action
        next(setVerification({ status: STATUS.PENDING, error: null }));
        // Call debounced verify method
        callDebouncedVerify(input, settings, next);
    }
};

export default [equipmentVerificationMiddleware];
