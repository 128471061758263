import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../redux';
import i18n from '../i18n';
import actions from '../redux/actions';
import { TEMPLATE_STEPS } from '../enums';

const radioButtons = [
    { label: 'inactive', value: false, message: 'inactive_help_message' },
    { label: 'active', value: true, message: 'active_help_message' },
];

const Activation = (): JSX.Element => {
    const dispatch = useDispatch();
    const isActive = useAppSelector((store) => store.epackage.isActive);
    const isActivatable = useAppSelector((store) => store.settings.isActivatable);
    const templateStep = useAppSelector((store) => store.appState.templateStep);
    const impersonation = useAppSelector((store) => store.settings.impersonation);
    const [isActiveDraft, setIsActiveDraft] = useState<boolean>(isActive);
    const [panelOpen, setPanelOpen] = useState<boolean>(templateStep === TEMPLATE_STEPS.step_4);

    useEffect(() => {
        setPanelOpen(templateStep === TEMPLATE_STEPS.step_4);
    }, [templateStep]);

    // If epackage is archived by affiliate but is still active on prof portal, user can deactive epackage but not activate it again
    const radioButtonsDisabled = !isActivatable && !isActive;

    return (
        <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="heading-status">
                <h4 className="panel-title">
                    <a
                        role="button"
                        className="collapse in"
                        data-parent="#accordion"
                        href="#collapse-status"
                        aria-expanded="true"
                        aria-controls="collapse-status"
                        onClick={() => {
                            // Open/Close template step 4
                            setPanelOpen(!panelOpen);
                            dispatch(actions.appState.setTemplateStep(panelOpen ? null : 4));
                        }}
                    >
                        {i18n('step_4_activation')}
                    </a>
                </h4>
            </div>
            <div
                id="collapse-status"
                className={`panel-collapse collapse ${panelOpen ? 'in' : ''}`}
                aria-labelledby="heading-status"
                aria-expanded={panelOpen}
            >
                <div className="panel-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <span className="alert alert-info pull-right">
                                {i18n('activation_help_message')}
                            </span>
                        </div>
                    </div>

                    {!isActivatable && (
                        <div className="row">
                            <div className="col-lg-12">
                                <span className="pull-left">{i18n('archived_message')}</span>
                            </div>
                        </div>
                    )}
                    {radioButtons.map(({ label, value, message }) => {
                        return (
                            <div key={label} className="radio">
                                <label htmlFor={label}>
                                    {/* User can't save select "Active" radio button when impersonating prof and package is active */}
                                    <input
                                        type="radio"
                                        name={label}
                                        id={label}
                                        disabled={
                                            radioButtonsDisabled ||
                                            (impersonation && !isActive && label === 'active')
                                        }
                                        checked={isActiveDraft === value}
                                        onClick={() => setIsActiveDraft(value)}
                                    />
                                    <strong>{i18n(label)}</strong>
                                    &nbsp;
                                    <small>{i18n(message)}</small>
                                </label>
                            </div>
                        );
                    })}
                    <hr />
                    {/* User can't save activation and package when impersonating prof and package is active */}
                    <button
                        type="button"
                        className="btn btn-primary confirm"
                        disabled={impersonation && isActive}
                        onClick={
                            impersonation && isActive
                                ? undefined
                                : () => dispatch(actions.epackage.saveStatus(isActiveDraft))
                        }
                    >
                        {i18n('save_status')}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Activation;
