import React from 'react';
import * as Styles from '../styles';

const Notification = ({ id, title, timeAgo, url, hasBeenRead, toggleNotificationRead }) => (
    <Styles.Notification className='list-group-item' hasBeenRead={hasBeenRead}>
        <div className='row'>
            <div className='col-sm-11'>
                <Styles.NotificationLink href={url}>{title}</Styles.NotificationLink>
                <span className='pull-right small'>
                    <small>{timeAgo}</small>
                </span>
            </div>
            <div className='col-sm-1' onClick={() => { toggleNotificationRead(id); }}>
                <i className={`glyphicon glyphicon-${(hasBeenRead) ? 'check' : 'unchecked'} text-info`} />
            </div>
        </div>
    </Styles.Notification>
);

export default Notification;
