import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '@common/components/Loader';
import i18n from '../i18n';
import { useAppSelector } from '../redux';
import actions from '../redux/actions';
import { TEMPLATE_STEPS } from '../enums';

const epackageExampleStyles = {
    width: '100%',
    height: '490px',
    border: 'none',
};

const Example = (): JSX.Element => {
    const dispatch = useDispatch();
    const templateStep = useAppSelector((store) => store.appState.templateStep);
    const languages = useAppSelector((store) => store.settings.languages);
    const epackageExample = useAppSelector((store) => store.epackage.epackageExample);
    const isFetchingEpackageExample = useAppSelector(
        (store) => store.appState.isFetchingEpackageExample,
    );
    const epackageExampleError = useAppSelector((store) => store.appState.errors?.epackageExample);
    const epackageExampleLanguage = useAppSelector(
        (store) => store.appState.epackageExampleLanguage,
    );

    const [panelOpen, setPanelOpen] = useState<boolean>(templateStep === TEMPLATE_STEPS.step_3);
    // Default is the selected language set to the first item in the language list
    const [selectedLanguage, setSelectedLanguage] = useState<string>(Object.keys(languages)[0]);

    // When opening the accordion step 3 the epackage example will be fetched for the selected language
    useEffect(() => {
        // Don't fetch the epackage example again if the selected language is not changed
        if (panelOpen && selectedLanguage !== epackageExampleLanguage) {
            dispatch(actions.epackage.fetchEpackageExample(selectedLanguage));
        }
    }, [dispatch, languages, selectedLanguage, epackageExampleLanguage, panelOpen]);

    useEffect(() => {
        setPanelOpen(templateStep === TEMPLATE_STEPS.step_3);
    }, [templateStep]);

    const onSelectLanguage = (language: string): void => {
        setSelectedLanguage(language);
    };

    return (
        <div className="panel panel-default">
            <div className="panel-heading" role="tab" id="heading-example">
                <h4 className="panel-title">
                    <a
                        role="button"
                        className="collapse in"
                        data-parent="#accordion"
                        href="#collapse-example"
                        aria-expanded="true"
                        aria-controls="collapse-example"
                        onClick={() => {
                            // Open/Close template step 3
                            setPanelOpen(!panelOpen);
                            dispatch(actions.appState.setTemplateStep(panelOpen ? null : 3));
                        }}
                    >
                        {i18n('step_3_example')}
                    </a>
                </h4>
            </div>
            <div
                id="collapse-example"
                className={`panel-collapse collapse ${panelOpen ? 'in' : ''}`}
                aria-labelledby="heading-example"
                aria-expanded={panelOpen}
            >
                <div className="panel-body">
                    <select
                        className="form-control"
                        onChange={(e) => onSelectLanguage(e.target.value)}
                    >
                        {Object.entries(languages).map(([key, value]) => (
                            <option value={key} key={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                    <hr />
                    <div>
                        {isFetchingEpackageExample && !epackageExampleError && (
                            <Loader message={i18n('loading')} />
                        )}
                        {epackageExampleError ? (
                            <div className="alert alert-warning">{i18n(epackageExampleError)}</div>
                        ) : (
                            epackageExample && (
                                <iframe
                                    title="epackage_example"
                                    srcDoc={epackageExample || ''}
                                    style={epackageExampleStyles}
                                />
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Example;
