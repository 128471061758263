import boiler from '@boiler';
import { createLogicMiddleware } from 'redux-logic';
import App from './App';
import i18n from './i18n';
import reducers from './redux/reducers';
import logic from './redux/logic';

const mapInputValueToState = (json) => {
    // Add labels to i18n
    i18n.set(json.labels);

    return {
        appState: {},
        settings: json.settings,
    };
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(
        App,
        'subscription-order-add-installations',
        reducers,
        mapInputValueToState,
        [createLogicMiddleware(logic)],
    );
}());
