// Default state
const defaultLabels = {
    /* REFERENCE LABELS */
    // add_unit: 'Add unit',
    // unit_details: 'Unit details',
    // select: 'Select...',
    // save: 'Save',
    // close: 'Close',
    // unit_type: 'Unit type',
    // serial_number: 'Serial number',
    // number_of_panels: 'Number of panels',
    // invalid_number_of_panels: 'Number of panels should be a whole number between 1 and 10',
    // no_units_can_be_added: 'No units can be added',
    // lan_adapter_info: 'Info on adding a LAN Adapter',
    // gateway: 'Gateway',
    // unit: 'Unit',
    // loading: 'Loading...',
    // types: {
    //     outdoor: 'Outdoor unit',
    //     indoor: 'Indoor unit',
    //     gateway: 'Gateway',
    //     boiler: 'Boiler',
    //     tank: 'Tank',
    //     mac_address: 'Mac Address',
    //     lan_adapter: 'Lan adapter',
    // },
};

export default function labels(state) { return { ...defaultLabels, ...state }; }
