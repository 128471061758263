import React from 'react';

const Remove = ({ message }) => {
    return (
        <div className='panel-body'>
            <p>{message}</p>
        </div>
    );
};

export default Remove;
