import { ACTIONS } from '../../constants';
import { actions } from '../data';

//eslint-disable-next-line no-unused-vars
const pagingMiddleware = store => next => action => {
    //Forward to next middleware
    next(action);
    // Check actionType
    if (action.type === ACTIONS.SET_PAGING) {
        next(actions.fetchNotifications());
    }
};

export default pagingMiddleware;
