import boiler from '@boiler';
import App from './App';
import i18n from './i18n';
import data from './redux/data';
import settings from './redux/settings';

const mapInputToState = (input) => {
    const { labels, settings, data } = input;
    i18n.set(labels);

    return { data, settings };
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(App, 'installation-change-owner', { data, settings }, mapInputToState);
}());
