export const parseCompany = company => ({
    id: company.id,
    label: [company.company_name, company.city].join(', '),
    users: company.users.map(user => ({
        id: user.id,
        label: [user.firstName, user.lastName].filter(v => !!v).join(' '),
        email: user.email,
    })),
});

export const getMostRelevantUserId = (company, searchQuery = null) => {
    // Check if there's an exact match with the email-address given
    const exactUser = company.users.find(user => user.email === searchQuery);
    // Check if there's a main user
    const mainUser = company.users.find(user => !!+user.isMainUser);
    // If both are unavailable fall back to the first user of the company
    const firstUser = company.users[0];
    // Return the best match
    return (exactUser || mainUser || firstUser)?.id || '';
};
