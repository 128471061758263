import styled from 'styled-components';

export const Table = styled.table`
    tbody > tr > td {
        vertical-align: middle;
        span {
            margin-top: 0;
        }
    }
`;

export const ScollTable = styled.div`
    max-height: 600px;
    overflow-y: auto;
    margin-top: 2rem;
`;

export const Alert = styled.div`
    .alert {
        text-align: left;
        margin-bottom: 2rem;
    }
    .alert-danger a {
        color: #09c;
    }
`;

export const Message = styled.div`
    padding: 2rem 0;
`;

export const SearchBar = styled.label`
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding: 0rem 1.5rem;
    border: 1px solid #9aaabc;
    input {
        margin-right: 1.2rem;
        border: none;
        color: #414d5a;
        box-shadow: none;
    }
    input:focus {
        box-shadow: none;
    }
    button {
        padding: 0;
        height: 2rem;
        background: none;
        border: none;
    }
`;
