import React from 'react';

const Modal = ({ modalTitle, closeLabel, saveLabel, saveDisabled, children, submit, isSubmitDisabled, onClose }) => {
    return (
        <div className='modal-content'>
            <div className='modal-header'>
                <button
                    id='commissioning-modal-close'
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={onClose || null}
                >
                    <span aria-hidden='true'>×</span>
                </button>
                <h2 className='modal-title'>{modalTitle}</h2>
            </div>
            <div className='modal-body'>
                {children || null}
            </div>
            <div className='modal-footer'>
                <button
                    className='btn btn-default'
                    data-dismiss='modal'
                    type='button'
                    tabIndex={-1}
                    onClick={onClose}
                >
                    {closeLabel}
                </button>
                {(submit || saveLabel) && (
                    <button
                        className={`btn ${saveDisabled ? 'btn-disabled' : 'btn-success'}`}
                        onClick={saveDisabled ? null : submit}
                        disabled={isSubmitDisabled}
                        type='button'
                    >
                        {saveLabel}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Modal;
