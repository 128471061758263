import boiler from '@boiler';
import App from './App';
import data from './redux/data';
import settings from './redux/settings';
import labels from './redux/labels';
import middleware from './redux/middleware/index';

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(
        App,
        'notifications',
        { data, settings, labels },
        null,
        middleware,
    );
}());
