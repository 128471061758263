const defaultLabels = {
    refrigerant_type: 'Refrigerant type',
    total_field_charge: 'Total field charge',
    total_factory_charge: 'Total factory charge',
    total_charge: 'Total charge',
    factory_charge_unit: 'kg',
    voucher_result: '<strong>You will receive a voucher for {voucherValue} kg of {coolantType}</strong>',
    voucher_warning:
        `<strong>WARNING!</strong>
        The provided total charge exceeds the maximum total charge.
        <strong>You will not receive a voucher.</strong>`,
    voucher_info: 'Field charge input will determine the value of your refrigerant voucher.',
};

export default function labels(state) { return { ...defaultLabels, ...state }; }
