/* eslint-disable camelcase */
import i18nEnCountries from 'i18n-iso-countries/langs/en.json';

// npm-package is not compatible with IE
// Extract name directly from i18n-iso-countries asset files
// Package version is locked to make sure json-format does nog change
const getCountryName = country => i18nEnCountries.countries[country.toUpperCase()] || country;

const CACHE = {};

class GoogleMaps {

    constructor() {
        this.isLoaded = false;
        this.callbackQueue = [];
        this.geoCoder = null;
        this.API_KEY = null;
        this.MAPS_JS_API = null;
    }

    init = (API_KEY, MAPS_JS_API, appId) => {
        // Only allow this once
        if (!this.API_KEY) {
            this.MAPS_JS_API = MAPS_JS_API;
            this.API_KEY = API_KEY;
            // Listen for loadComplete & notify parent
            document.getElementById(appId).addEventListener('initMap', () => {
                this.isLoaded = true;
                this.geoCoder = new google.maps.Geocoder();
                // Invoke all callback methods on queue
                while (this.callbackQueue.length) this.callbackQueue.pop()();
            }, false);
            // Inject Google Maps script tag in header
            const mapsScript = document.createElement('script');
            mapsScript.src = `${this.MAPS_JS_API}?key=${this.API_KEY}&callback=initMap`;
            mapsScript.type = 'text/javascript';
            document.getElementsByTagName('head')[0].appendChild(mapsScript);
        }
    };

    checkReadiness(isLoadedCallback) {
        if (this.isLoaded) {
            isLoadedCallback();
        } else {
            this.callbackQueue.push(isLoadedCallback);
        }
    }

    getCoordsFromGeolocation(options = {}) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }

    reverseGeoCode(location) {
        // Loops over all results and tries to find an address_component with matching type
        return new Promise((resolve, reject) => {
            // Check cache first
            if (CACHE[location]) {
                resolve(CACHE[location]);
            } else {
                this.geoCoder.geocode({ location }, (results, status) => {
                    if (status === 'OK' && results.length) {
                        // Check if there are results of type "street_address", these are best suited for our needs
                        let geocodeResult = results.find(res => res.types.includes('street_address'));
                        // If a "street_address" was not found, we'll use the top result (most accurate)
                        if (!geocodeResult) geocodeResult = results[0];
                        const { address_components } = geocodeResult;
                        // Attempt to build an address-object which our views can use
                        const normalizedAddress = {};
                        for (let j = 0; j < address_components.length; j++) {
                            const { types, long_name, short_name } = address_components[j];
                            if (types.includes('locality') || types.includes('postal_town')) {
                                normalizedAddress.city = long_name;
                            } else if (types.includes('postal_code')) {
                                normalizedAddress.postalCode = long_name;
                            } else if (types.includes('street_number')) {
                                normalizedAddress.number = long_name;
                            } else if (types.includes('route')) {
                                normalizedAddress.street = long_name;
                            } else if (types.includes('country')) {
                                normalizedAddress.country = short_name;
                            }
                        }
                        // Resolve promise with found result
                        resolve(normalizedAddress);
                    } else {
                        reject(status);
                    }
                });
            }
        });
    }

    geoCode(country, city, postalCode, street, number, language, restrictCountry = false) {
        const query = {
            address: [],
            language,
        };
        // Restrict or Bias the country (hard restriction is needed for EWB)
        // - the region code will only influence, but not fully restrict, results from the geocoder
        //   cfr.: https://developers.google.com/maps/documentation/geocoding/overview#RegionCodes
        if (restrictCountry) query.componentRestrictions = { country };
        else query.region = country;
        // Add address-parts
        if (street) query.address.push([street, number].filter(v => !!v).join(' '));
        if (postalCode) query.address.push(postalCode);
        if (city) query.address.push(city);
        // Attempt to push full country-name (in english) to the address. (country-code as fallback)
        query.address.push(getCountryName(country));
        query.address = query.address.join(',');

        // Loops over all results and tries to find an address_component with matching type
        const getResultByType = (results, type) => {
            for (let i = 0; i < results.length; i++) {
                for (let j = 0; j < results[i].address_components.length; j++) {
                    const ac = results[i].address_components[j];
                    if (ac.types && ac.types.includes(type) && ac.long_name) {
                        return { city: ac.long_name, address: results[i] };
                    }
                }
            }
            return null;
        };

        return new Promise((resolve, reject) => {
            // Check cache first
            if (CACHE[query.address]) {
                resolve(CACHE[query.address]);
            } else {
                this.geoCoder.geocode(query, (results, status) => {
                    if (status === 'OK' && results.length) {
                        // Fetch the first result which has an address_component of type with a long_name.
                        let result = getResultByType(results, 'sublocality');
                        if (!result) result = getResultByType(results, 'locality');
                        if (!result) result = getResultByType(results, 'postal_town');
                        if (!result) result = getResultByType(results, 'administrative_area_level_3');
                        if (!result) result = getResultByType(results, 'postal_code'); // Turkey
                        if (!result) result = getResultByType(results, 'country'); // Fallback
                        if (!result) {
                            // No valid results found. Reject promise.
                            reject('No valid address found of type postal_town');
                        } else {
                            // Resolve promise with found result
                            resolve({
                                city: result.city,
                                formattedAddress: result.address.formatted_address,
                                coordinates: {
                                    latitude: result.address.geometry.location.lat(),
                                    longitude: result.address.geometry.location.lng(),
                                },
                                postalCode: result.address.address_components.find((comp) => (
                                    comp.types.includes('postal_code')))?.long_name || null,
                            });
                        }
                    } else {
                        reject(status);
                    }
                });
            }
        });
    }

    getMap(destinationNode, options) {
        if (this.map) {
            // Update current instance
            this.map.setOptions(options);
            destinationNode.parentNode.replaceChild(this.map.getDiv(), destinationNode);
        } else {
            // Create new instance
            this.map = new google.maps.Map(destinationNode, options);
        }
        return this.map;
    }

    createMarker(markerOptions) {
        return new google.maps.Marker(markerOptions);
    }

    createInfoWindow(content) {
        return new google.maps.InfoWindow({ content, disableAutoPan: true });
    }

    getLatLngBounds() {
        return new google.maps.LatLngBounds();
    }

}

/* --- Export Singleton ------------------------------------------------------------------------------ */

const _singleton = new GoogleMaps();

export default _singleton;
