import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoIcon, MagnifyingGlass } from '@common/icons';
import TextInputWithAddon from '@common/controls/TextInputWithAddon';
import PopoverTrigger from '@common/components/popover/PopoverTrigger';
import { selectKnownCompany, search, SELECTION_METHODS, setSearchQuery } from '../store';
import i18n from '../i18n';
import DropDown from '../controls/DropDown';

const SearchBar = (props) => {

    const dispatch = useDispatch();
    // Known companies
    const searchQuery = useSelector(({ store }) => store.searchQuery);
    const knownCompanies = useSelector(({ store }) => store.knownCompanies);
    const selectedKnownCompanyLabel = useSelector(({ store }) => {
        if (store.selectionMethod === SELECTION_METHODS.SEARCH) return i18n('select_company');
        if (!store.selectedUserId) return i18n('select_company');
        return store.knownCompanies.find(c => !!c.users.find(u => u.id === store.selectedUserId)).label;
    });

    // UI handlers
    const onSelectKnownCompany = company => dispatch(selectKnownCompany(company.id));
    const onSetSearchQuery = query => dispatch(setSearchQuery(query));
    const onSearch = () => dispatch(search());

    return (
        <div className='form-group d-flex' style={{ pointerEvents: 'auto', opacity: 1, minHeight: 44 }}>
            <div className='col-sm-5'>
                <DropDown
                    label={selectedKnownCompanyLabel}
                    items={knownCompanies}
                    callback={onSelectKnownCompany}
                />
            </div>
            <div className='col-sm-1 text-center'>
                {i18n('or')}
                <div style={{ marginTop: 5 }}>
                    <PopoverTrigger
                        title={i18n('search_by_email_title')}
                        content={i18n('search_by_email_requirements')}
                        triggerMethod='hover'
                        positioning='bottom'
                    >
                        <InfoIcon />
                    </PopoverTrigger>
                </div>
            </div>
            <div className='col-sm-6'>
                <TextInputWithAddon
                    inputRef={props.inputRef}
                    type='text'
                    value={searchQuery}
                    onSubmit={props.disabled ? null : onSearch}
                    onChange={onSetSearchQuery}
                    placeholder={i18n('search_by_email')}
                    icon={<MagnifyingGlass />}
                />
            </div>
        </div>
    );

};

export default SearchBar;
