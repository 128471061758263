import React, { useCallback, useEffect, useRef } from 'react';
import boiler from '@boiler';
import { useDispatch, useSelector } from 'react-redux';
import Guard from '@common/atoms/Guard';
import Popover from '@common/components/popover/Popover';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalBackDrop } from '@common/components/Modal';
import Loader from '@common/components/Loader';
import { PrimaryButton, SecondaryButton } from '@common/controls/Button';
import i18n from './i18n';
import { setId, setComment, submit, retrySearch } from './store';
import SearchBar from './components/SearchBar';
import SearchResults from './components/SearchResults';
import LegalChecks from './components/LegalChecks';

const App = () => {
    // Dispatch
    const dispatch = useDispatch();
    // References
    const searchFieldRef = useRef(null);
    // Store data
    const companies = useSelector(({ store }) => store.companies);
    const comment = useSelector(({ store }) => store.comment);
    const isSearchingQuery = useSelector(({ store }) => store.isSearchingQuery);
    const isSending = useSelector(({ store }) => store.isSending);
    const isSubmitSuccessful = useSelector(({ store }) => store.isSubmitSuccessful);
    const canSend = useSelector(({ store }) => (
        store.selectedUserId && store.allowDataProcessing && store.allowRegistration
    ));
    // Callbacks
    const onTryAgainClicked = useCallback(() => {
        dispatch(retrySearch());
        searchFieldRef.current.focus();
    }, [searchFieldRef]);
    // Success/Error message
    if (isSubmitSuccessful !== null) {
        return (
            <>
                <ModalBody>
                    {i18n(`sent_to_professional_${isSubmitSuccessful ? 'success' : 'failed'}_description`)}
                </ModalBody>
                <ModalFooter>
                    <SecondaryButton
                        onClick={() => dispatch(setId(null))}
                    >
                        {i18n('close')}
                    </SecondaryButton>
                </ModalFooter>
            </>
        );
    }
    // Loading message (when submitting)
    if (isSending) {
        return (
            <ModalBody>
                <Loader message={i18n('sending_to_professional')} />
            </ModalBody>
        );
    }
    // Form
    return (
        <>
            <ModalBody>
                <SearchBar inputRef={searchFieldRef} />
                {isSearchingQuery && <Loader message={i18n('searching_companies')} />}
                {!isSearchingQuery && (companies !== null) && (
                    (companies.length > 0) ? (
                        <>
                            <hr />
                            <SearchResults />
                            <hr />
                            <div className='form-group' style={{ display: 'flex' }}>
                                <label className='col-sm-2 control-label'>
                                    {i18n('comment')}
                                </label>
                                <div className='col-sm-10'>
                                    <textarea
                                        className='form-control'
                                        style={{ maxWidth: '100%' }}
                                        rows='6'
                                        value={comment}
                                        onChange={e => dispatch(setComment(e.currentTarget.value))}
                                    />
                                </div>
                            </div>
                            <hr />
                            <LegalChecks />
                        </>
                    ) : (
                        <div className='alert alert-warning mt-4 d-flex'>
                            {i18n('no_professional_found')}
                            &nbsp;
                            <a onClick={onTryAgainClicked}>
                                {i18n('try_another_search')}
                            </a>
                        </div>
                    )
                )}
            </ModalBody>
            <ModalFooter>
                <PrimaryButton
                    onClick={canSend ? () => dispatch(submit()) : null}
                    disabled={!canSend}
                >
                    {i18n('send_to_selected_professional')}
                </PrimaryButton>
            </ModalFooter>
        </>
    );

};

export default () => {
    // Listen for initialize calls
    const dispatch = useDispatch();
    useEffect(() => {
        boiler.postalChannel.subscribe({
            topic: 'send-to-professional',
            callback: id => dispatch(setId(id)),
        });
    }, []);
    // RenderGuard
    return (
        <Guard selector={({ store }) => !!store.id}>
            <Popover />
            <Modal>
                <ModalHeader closeHandler={() => dispatch(setId(null))}>
                    {i18n('send_to_professional_title')}
                </ModalHeader>
                <App />
            </Modal>
            <ModalBackDrop />
        </Guard>
    );
};
