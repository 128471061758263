import styled from 'styled-components';

export const JoyrideWrapperStyles = {
    options: {
        overlayColor: 'transparent',
        arrowColor: '#fff',
        backgroundColor: '#fff',
        textColor: '#585858',
        width: 500,
        zIndex: 1000,
    },
};

export const Body = styled.div`
    background-color: #fff;
    min-width: 290px;
    max-width: 420px;
    position: relative;
    border-color: #ddd;
    border-radius: 5px;
`;

export const Title = styled.h4`
    padding: 15px;
    margin: 0;
    margin-bottom: 15px;
    float: left;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 5px 5px 0px 0px;
`;

export const Button = styled.button`
    float: right;
    background: transparent;
    border: none;
    svg {
        vertical-align: middle;
    }
`;
export const Content = styled.div`
    padding: 15px;
`;
