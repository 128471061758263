import React, { useState, useRef } from 'react';
import { UpArrow, DownArrow } from '@common/icons';
import { useOnClickOutside } from '@common/hooks';
import * as S from './DropDown.styles';

const DropDown = ({ label, items, callback }) => {

    const [open, setOpen] = useState(false);
    // Auto-close when clicking outside
    const ref = useRef(null);
    useOnClickOutside(ref, () => setOpen(false));

    const onSelectItem = item => {
        if (item.disabled) return;
        setOpen(false);
        if (callback) callback(item);
    };

    return (
        <S.Wrapper ref={ref}>
            <S.Main onClick={() => setOpen(!open)}>
                <S.Label>{label}</S.Label>
                <S.Addon>
                    <S.Icon>
                        {open ? <UpArrow /> : <DownArrow />}
                    </S.Icon>
                </S.Addon>
            </S.Main>
            {open && (
                <S.List>
                    {items.map(item => (
                        <S.Item
                            key={item.id}
                            onClick={() => onSelectItem(item)}
                            disabled={item.disabled}
                        >
                            <S.ItemLabel disabled={item.disabled}>{item.label}</S.ItemLabel>
                        </S.Item>
                    ))}
                </S.List>
            )}
        </S.Wrapper>
    );
};

export default DropDown;
