import { breakpoint } from '@common/components/Breakpoints';
import styled from 'styled-components';

export const Body = styled.div`
    top: 0;
    left: 100%;
    min-width: 275px;
    display: block;
    z-index: 1000;
    transform: translateY(calc(-50% + 5px));
    @media ${breakpoint.down('md')} {
        top: 0;
        left: 50%;
        transform: translateX(calc(-50% + 5px)) translateY(-100%);
    }
`;

export const Arrow = styled.div`
    top: 50%;
    @media ${breakpoint.down('md')} {
        top: 100%;
    }
`;
