import React from 'react';

export type DropDownOptionType = {
    label: string;
    value: string | number | undefined;
    disabled?: boolean;
};

export type DropDownOptionGroupType = {
    label: string;
    options: DropDownOptionType[];
};

type DropDownPropsType = {
    options?: DropDownOptionType[];
    optionGroups?: DropDownOptionGroupType[];
    selected: string | number | undefined;
    onChange: (value: string | number | undefined) => void;
    onClick?: () => void;
    disabled?: boolean;
};

type OptionPropsType = DropDownOptionType & { isDisabled?: boolean };
const Option = (props: OptionPropsType): JSX.Element => {
    return (
        <option key={props.value} value={props.value} disabled={!!props.isDisabled}>
            {props.label || props.value}
        </option>
    );
};

const DropDown = (props: DropDownPropsType): JSX.Element => {
    const { selected, disabled, options, optionGroups, onChange, onClick } = props;
    return (
        <select
            value={selected}
            className={`form-control ${disabled ? 'disabled' : ''}`}
            onChange={onChange && ((e) => onChange(e.target.value))}
            onClick={onClick}
            disabled={disabled}
        >
            {options &&
                options.map((o) => (
                    <Option key={o.value} value={o.value} label={o.label} isDisabled={o.disabled} />
                ))}
            {optionGroups &&
                optionGroups.map((group) => (
                    <optgroup label={group.label} key={group.label}>
                        {group.options &&
                            group.options.map((o) => (
                                <Option key={o.value} value={o.value} label={o.label} />
                            ))}
                    </optgroup>
                ))}
        </select>
    );
};

export default DropDown;
