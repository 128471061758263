/* eslint-disable camelcase */
import React, { useState, useCallback, useMemo } from 'react';
import boiler from '@boiler';
import axios from 'axios';
// Utils
import { toggleArrValue } from '@tsUtils';
// Redux
import { updateStructure } from './redux/structure';
// Components
import AddToFolderModal from './components/AddToFolderModal';
import EditFolderModal from './components/EditFolderModal';
import RemoveConfirmModal from './components/RemoveConfirmModal';
import ExplorerRow from './components/ExplorerRow';
// Labels
import i18n from './i18n';

/* --- <App/> -------------------------------------------------------------------------------------- */

const App = ({ settings, structure, update }) => {
    // State
    const [current, setCurrent] = useState(null);
    const [openFolders, setOpenFolders] = useState([]);
    const [showAddToFolderModal, setShowAddToFolderModal] = useState(false);
    const [editFolderName, setEditFolderName] = useState(null);
    const [removalType, setRemovalType] = useState(null);

    // -- Handlers --

    const postAndUpdate = useCallback(async (endpoint, postData = {}, callback, config) => {
        try {
            const { data } = await axios.post(endpoint, postData, config);
            if (data) update(data.structure);
            if (callback) callback();
            return { success: true, ...data };
        } catch (err) {
            return { success: false, errors: err.response.data.errors };
        }
    }, []);

    // -- Memoization --

    const explorerItems = useMemo(() => {
        const docsAndFolders = [];
        const addFolderContents = (folder, folderDepth) => {
            // Check subfolders
            folder.folders.forEach(({ id, name, parent_id, documents, folders }) => {
                const isExpanded = openFolders.includes(id);
                const folderConfig = { id, name, parent_id, folderDepth, isFolder: true, isExpanded };
                const hasChildren = !!documents.length || !!folders.length;
                const withCurrentFolder = (fn) => () => {
                    setCurrent(id);
                    setOpenFolders(opened => Array.from(new Set([...opened, id]))); // Open current folder
                    fn();
                };
                // Actions
                folderConfig.onClick = () => setOpenFolders(opened => toggleArrValue(opened, id));
                folderConfig.onAdd = withCurrentFolder(() => setShowAddToFolderModal(true));
                folderConfig.onEdit = withCurrentFolder(() => setEditFolderName(name));
                // Allow deleting empty folders
                if (!hasChildren) folderConfig.onRemove = () => { setCurrent(id); setRemovalType('folder'); };
                // Add to list of viewable folderConfig
                docsAndFolders.push(folderConfig);
                // Add children to tree
                if (hasChildren && isExpanded) {
                    addFolderContents({ ...folderConfig, folders, documents }, folderDepth + 1);
                }
            });
            // Add folder documents
            folder.documents.forEach(({ id, parent_id, file_name, url, ...doc }) => {
                const docConfig = { id, parent_id, file_name, url, folderDepth, ...doc };
                docConfig.onRemove = () => { setCurrent(id); setRemovalType('file'); };
                docsAndFolders.push(docConfig);
            });
        };
        // Kickoff recursion with root folder
        addFolderContents(structure, 0);
        // Return visible explorer items
        return docsAndFolders;
    }, [structure, openFolders]);

    // -- Render --

    return (
        <div className='container'>
            <div className='row overview-header'>
                <div className='col-lg-10 col-md-6 col-sm-8 col-xs-9'>
                    <h1>{i18n('documents')}</h1>
                </div>
                <div className='col-lg-2 col-md-6 col-sm-4 col-xs-3'>
                    <a
                        data-toggle='modal'
                        data-target='#add'
                        className='btn btn-default pull-right'
                        onClick={() => { setCurrent(null); setShowAddToFolderModal(true); }}
                    >
                        <i className='glyphicon glyphicon-plus' />
                        {` ${i18n('add')}`}
                    </a>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-8 col-lg-offset-2'>
                    <div id='tree' className='treeview'>
                        <ul className='list-group'>
                            {explorerItems.map(({
                                id,
                                name,
                                folderDepth,
                                isFolder,
                                isExpanded,
                                file_name,
                                url,
                                onClick,
                                onEdit,
                                onAdd,
                                onRemove,
                            }) => (
                                <ExplorerRow
                                    key={`expl_item_${id}`}
                                    id={id}
                                    name={name}
                                    folderDepth={folderDepth}
                                    isFolder={isFolder}
                                    isExpanded={isExpanded}
                                    file_name={file_name}
                                    url={url}
                                    onClick={onClick || null}
                                    onEdit={onEdit || null}
                                    onAdd={onAdd || null}
                                    onRemove={onRemove || null}
                                    postAndUpdate={postAndUpdate}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {showAddToFolderModal && (
                <AddToFolderModal
                    parent={current}
                    urls={settings.urls}
                    onClose={() => { setCurrent(null); setShowAddToFolderModal(false); }}
                    postAndUpdate={postAndUpdate}
                />
            )}
            {!!editFolderName && (
                <EditFolderModal
                    id={current}
                    name={editFolderName}
                    urls={settings.urls}
                    onClose={() => { setCurrent(null); setEditFolderName(null); }}
                    postAndUpdate={postAndUpdate}
                />
            )}
            {!!removalType && (
                <RemoveConfirmModal
                    id={current}
                    type={removalType}
                    urls={settings.urls}
                    onClose={() => { setCurrent(null); setRemovalType(null); }}
                    postAndUpdate={postAndUpdate}
                />
            )}
        </div>
    );
};

/* --- Boiler Connect ------------------------------------------------------------------------------ */

const mapStateToProps = ({ settings, structure }) => {
    return { settings, structure };
};

const mapActionsToProps = {
    update: updateStructure,
};

/* --- Export App ---------------------------------------------------------------------------------- */

export default boiler.connect(mapStateToProps, mapActionsToProps)(App);
