/* eslint-disable func-names */
import boiler from '@boiler';
// Redux
import settings from './redux/settings';
import structure from './redux/structure';
// Components
import App from './App';
// Labels
import i18n from './i18n';

/* --- Redux ------------------------------------------------------------------------------- */

const mapInputsToState = json => {
    // Pass labels to i18n
    i18n.set(json.labels);
    // Merge config into settings
    return {
        settings: { ...json.settings, ...json.config },
        structure: { ...json.structure },
    };
};

/* --- Init() ------------------------------------------------------------------------------ */

(function () {
    boiler.initAppInstances(
        App,
        'document-explorer',
        { settings, structure },
        mapInputsToState,
        // null, // middleware,
    );
}());
