import { createLogic } from 'redux-logic';
import axios from 'axios';
import { templateString } from '@tsUtils';
import { actions, setSearchResults, setSubmitResult } from './store';

const GET = url => (
    new Promise((resolve, reject) => {
        axios({ method: 'GET', url })
            .then(responseData => {
                if (responseData && responseData.status === 200 && responseData.data) {
                    resolve(responseData.data.result);
                } else {
                    reject(responseData);
                }
            })
            .catch(reject);
    })
);

const POST = (url, data) => (
    new Promise((resolve, reject) => {
        axios({ method: 'POST', url, data })
            .then(responseData => {
                if (responseData && responseData.status === 200 && responseData?.data?.success) {
                    resolve();
                } else {
                    reject();
                }
            })
            .catch(reject);
    })
);

const searchLogic = createLogic({
    type: actions.SEARCH,

    process({ getState }, dispatch, done) {
        const { searchQuery, urls } = getState().store;
        const url = templateString(
            decodeURI(urls.searchProfessional),
            { search: encodeURIComponent(searchQuery) },
        );
        GET(url).then(result => dispatch(setSearchResults(result))).finally(done);
    },
});

const submitLogic = createLogic({
    type: actions.SUBMIT,

    process({ getState }, dispatch, done) {
        const { id, urls, selectedUserId, comment } = getState().store;
        const url = decodeURI(urls.saveSendToProfessional).replace(/\{(requestId|leadId)\}/g, id);
        POST(url, { professionalUserId: selectedUserId, comment })
            .then(() => dispatch(setSubmitResult(true)))
            .catch(() => dispatch(setSubmitResult(false)))
            .finally(done);
    },
});

export default [searchLogic, submitLogic];
