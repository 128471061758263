import axios from 'axios';
import { createLogic } from 'redux-logic';
import { State } from '../../types';
import actions from '../actions';
import { INSTALLATIONS_ACTIONS, SetSearchInputAction } from '../actions/installations';
import { APP_STATE_ACTIONS } from '../actions/appState';
import { INSTALLATION_KEYS } from '../../constants';

type DepObj = { getState: () => State };

const triggerFetchInstallationsLogic = createLogic({
    type: APP_STATE_ACTIONS.OPEN_OVERLAY,
    name: 'installations.triggerFetch',
    process(_: DepObj, dispatch, done) {
        dispatch(actions.installations.fetchInstallations());
        done();
    },
});

const fetchInstallationsLogic = createLogic({
    type: INSTALLATIONS_ACTIONS.FETCH,
    name: 'installations.fetch',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        const { list } = state.settings.urls;
        axios
            .get(list)
            .then((res) => {
                const { status, data } = res;
                if (status === 200 && data) {
                    dispatch(actions.installations.setInstallations(data));
                }
            })
            .catch((err) => console.error(err))
            .finally(done);
    },
});

const queryKeys = [
    INSTALLATION_KEYS.name,
    INSTALLATION_KEYS.product,
    INSTALLATION_KEYS.productName,
    INSTALLATION_KEYS.city,
    INSTALLATION_KEYS.email,
    INSTALLATION_KEYS.companyName,
    INSTALLATION_KEYS.serialNumber,
    INSTALLATION_KEYS.commissioningCode,
];

type FilterInstallationsDepObj = DepObj & { action: SetSearchInputAction };
const filterInstallationsLogic = createLogic({
    type: INSTALLATIONS_ACTIONS.SET_SEARCH_INPUT,
    name: 'installations.fetch',
    process({ getState, action }: FilterInstallationsDepObj, dispatch, done) {
        const state = getState();

        const filteredInstallations = state.installationsData.installations.filter(
            (installation) => {
                return queryKeys.some((key) => {
                    return (installation[key] as string)
                        ?.toLowerCase()
                        .includes(action.input?.toLowerCase());
                });
            },
        );

        dispatch(
            actions.installations.setFilteredInstallationIds(
                filteredInstallations.map((installation) => installation.id),
            ),
        );
        done();
    },
});

const saveSelectedInstallationsLogic = createLogic({
    type: INSTALLATIONS_ACTIONS.SAVE,
    name: 'installations.save',
    process({ getState }: DepObj, dispatch, done) {
        const state = getState();
        const baseUrl = decodeURI(state.settings.urls.addInstallation);
        const urls = state.installationsData.selected.map((id) =>
            baseUrl.replace('{installationId}', id),
        );

        Promise.allSettled(urls.map((url) => axios.post(url))).then((results) => {
            results.forEach((result) => {
                if (result.status === 'rejected') console.error(result.reason);
            });
            dispatch(actions.appState.closeOverlay());
            done();
        });
    },
});

const refreshPageLogic = createLogic({
    type: APP_STATE_ACTIONS.CLOSE_OVERLAY,
    name: 'installations.refresh',
    process(_: DepObj, _dispatch, done) {
        window.location.reload();
        done();
    },
});

export default [
    triggerFetchInstallationsLogic,
    fetchInstallationsLogic,
    filterInstallationsLogic,
    saveSelectedInstallationsLogic,
    refreshPageLogic,
];
