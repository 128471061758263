import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import i18n from '../i18n';
import { actions, useAppSelector } from '../redux';
import Link from './KeyAccountChange/Link';
import Accordion from './Accordion';
import Remove from './Remove';
import { KeyAccountActions, KeyAccountData } from '../types';

const KeyAccountChange: FC = () => {
    // Fetch data from store
    const settings = useAppSelector((store) => store.settings);
    const hasKeyAccount = useAppSelector((store) => store.data.keyAccountData.hasKeyAccount);
    const keyAccountData = useAppSelector((store) => store.data.keyAccountData);
    // UI handlers
    const dispatch = useDispatch();
    const setKeyAccountAction = (action: KeyAccountActions): void => {
        dispatch(actions.setKeyAccountAction(action));
    };
    const resetKeyAccountDataAction = (): void => {
        dispatch(actions.resetKeyAccountDataAction());
    };
    const setKeyAccountDataAction = (data: Partial<KeyAccountData>): void => {
        dispatch(actions.setKeyAccountDataAction(data));
    };

    const accordionItems = [
        {
            id: 'no_change',
            content: null,
            label: i18n('no_change'),
        },
        {
            id: 'other_ka',
            content: (
                <Link
                    {...keyAccountData} // eslint-disable-line
                    settings={settings}
                    resetKeyAccountDataAction={resetKeyAccountDataAction}
                    setKeyAccountDataAction={setKeyAccountDataAction}
                />
            ),
            label: i18n('link_ka'),
        },
    ];

    if (settings.canDeleteKeyAccount && hasKeyAccount) {
        accordionItems.push({
            id: 'remove_ka',
            content: <Remove message={i18n('remove_message')} />,
            label: i18n('remove_ka'),
        });
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
                    <h4>{i18n('change_ka')}</h4>
                    <Accordion
                        items={accordionItems}
                        selectedId={keyAccountData.action}
                        onSelectedItem={setKeyAccountAction}
                    />
                </form>
            </div>
        </div>
    );
};

export default KeyAccountChange;
