// Default state
const defaultStructure = {
    folders: [],
    documents: [],
    data: {},
};

/* --- actions ------------------------------------------------------------------------------ */

const UPDATE_STUCTURE = 'UPDATE_STRUCTURE';
export const updateStructure = update => ({ type: UPDATE_STUCTURE, ...update });

/* --- Reducer ------------------------------------------------------------------------------ */

export default function structure(state, action) {
    return {
        ...defaultStructure,
        ...state,
        ...(action.folders ? { folders: action.folders } : {}),
        ...(action.documents ? { documents: action.documents } : {}),
    };
}
