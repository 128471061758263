import boiler from '@boiler';
import { createLogicMiddleware } from 'redux-logic';
import reducers from './redux/reducers';
import SaveProductsAndPricesLogic from './redux/logic/epackageLogic';
import i18n from './i18n';
import App from './App';
import { TOOLTIPS } from './enums';

const mapInputValueToState = json => {
    // Add labels to i18n
    i18n.set(json.labels);

    const epackage = {
        ...json.epackage,
        productsAndPrices: Object.values(json.epackage.productsAndPrices),
        termsAndConditions: Object.values(json.epackage.termsAndConditions),
    };

    // Check which tooltips should be rendered anymore
    const deriveIgnoredTooltipsFromInput = (epackage) => {
        const ignoredList = [];
        // Check if products of epackage has prices
        if (epackage.productsAndPrices.find(pp => pp.price)) {
            ignoredList.push(TOOLTIPS.tt1, TOOLTIPS.tt2, TOOLTIPS.tt3);
        }
        // Check if epackage has terms
        if (epackage.termsAndConditions.find(tac => tac.text)) {
            ignoredList.push(TOOLTIPS.tt4, TOOLTIPS.tt5);
        }
        return ignoredList;
    };

    return {
        epackage,
        appState: {
            ignoredTooltips: deriveIgnoredTooltipsFromInput(json.epackage),
        },
        settings: json.settings,
    };
};

// eslint-disable-next-line func-names
(function () {
    boiler.initAppInstances(
        App,
        'daikin-a-la-carte',
        reducers,
        mapInputValueToState,
        [createLogicMiddleware(SaveProductsAndPricesLogic)],
    );
}());
