import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
    .rdw-editor-wrapper {
        box-sizing: content-box;

        .rdw-editor-toolbar {
            padding: 6px 5px 0;
            border-radius: 2px;
            border: 1px solid #f1f1f1;
            display: flex;
            justify-content: flex-start;
            background: white;
            flex-wrap: wrap;
            font-size: 15px;
            margin-bottom: 5px;
            user-select: none;

            .rdw-inline-wrapper, .rdw-list-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                flex-wrap: wrap;

                .rdw-option-wrapper {
                    border: 1px solid #F1F1F1;
                    padding: 5px;
                    min-width: 25px;
                    height: 20px;
                    border-radius: 2px;
                    margin: 0 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    background: white;
                    text-transform: capitalize;
                },
            }

            .rdw-dropdown-wrapper {
                height: 20px;
                min-width: 95px;
                color: black;
                cursor: pointer;
                border: 1px solid #F1F1F1;
                border-radius: 2px;
                margin: 0 3px;
                text-transform: capitalize;
            }
            .rdw-dropdown-carettoopen {
                height: 0px;
                width: 0px;
                position: absolute;
                top: 35%;
                right: 0%;
                border-top: 6px solid black;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                margin: 0px 5px;
            }
            .rdw-dropdown-carettoclose {
                height: 0px;
                width: 0px;
                top: 35%;
                position: absolute;
                right: 0%;
                border-bottom: 6px solid black;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                margin: 0px 5px;
            }
            .rdw-dropdown-selectedtext {
                display: flex;
                position: relative;
                height: 100%;
                align-items: center;
                padding: 0 5px;
                text-decoration: none;
                color: black;
            }
            .rdw-dropdown-optionwrapper {
                z-index: 100;
                position: relative;
                border: 1px solid #F1F1F1;
                background: white;
                border-radius: 2px;
                margin: 0;
                padding: 0;
                max-height: 250px;
                overflow-y: scroll;
            }
            .rdw-dropdown-optionwrapper:hover {
                box-shadow: 1px 1px 1px #BFBDBD;
                background-color: #FFFFFF;
            }
            .rdw-dropdownoption-default {
                min-height: 25px;
                display: flex;
                align-items: center;
                padding: 0 5px;
            }
            .rdw-dropdownoption-highlighted {
                background: #F1F1F1;
            }
            .rdw-dropdownoption-active {
                background: #f5f5f5;
            }
        }
`;
