import { InstallationType } from '../../types';
import { ActionType, INSTALLATIONS_ACTIONS } from '../actions';

const installations = (state = [], action: ActionType): InstallationType[] => {
    switch (action.type) {
        case INSTALLATIONS_ACTIONS.setInstallations:
            return action.installations;
        default:
            return state || [];
    }
};

export default installations;
