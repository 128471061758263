import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18n from './i18n';
import { fetchInstallationsAction } from './redux/actions';
import { useAppSelector } from './redux';
import Filters from './components/Filters';
import InstallationMap from './components/InstallationMap';

const containerStyle = { padding: '0px', width: '100%' };
const backButtonStyle = { marginBottom: '20px' };

const App: FC = () => {
    const dispatch = useDispatch();
    const installationOverview = useAppSelector(
        (store) => store.settings.urls.installationOverview,
    );
    const error = useAppSelector((store) => store.data.error);

    useEffect(() => {
        dispatch(fetchInstallationsAction());
    }, [dispatch]);

    return (
        <div className="container" style={containerStyle}>
            {error && <div className="alert alert-warning">{i18n(error)}</div>}
            <div className="row">
                <div className="col-lg-12">
                    <p>{i18n('installation_help_text')}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <Filters />
                    <a
                        className="btn btn-default"
                        href={installationOverview}
                        style={backButtonStyle}
                    >
                        {i18n('back_to_overview')}
                    </a>
                </div>
                <div className="col-lg-8">
                    <InstallationMap />
                </div>
            </div>
        </div>
    );
};

export default App;
