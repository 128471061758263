import React from 'react';
import styled from 'styled-components';

const InputGroup = styled.div`
display: table;
position: relative;
width: 100%;
max-height: 4rem;
margin: 0;
padding: 0;
border-collapse: separate;
`;

const InputText = styled.input`
border: 1px solid #C3CFD5;
border-radius: .2rem;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
position: relative;
margin: 0;
display: table-cell;
width: 100%;
padding: 1rem;
font-family: "Roboto",sans-serif;
font-size: 1.5rem;
line-height: 2.2rem;
color: #628495;
background-color: #fff;
background-image: none;
box-sizing: border-box;
-webkit-transition: border-color ease-in-out 0.15s;
transition: border-color ease-in-out 0.15s;

&:focus {
    outline: 0;
}
`;

const Addon = styled.span`
padding: 0px;
border: 1px solid #C3CFD5;
border-left: 0;
border-radius: 4px;
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-collapse: separate;
display: table-cell;
width: 1%;
min-width: 43px;
font-family: "Roboto",sans-serif;
font-size: 1.5rem;
text-align: center;
color: #628495;
background-color: #F6F6F6;
background-image: none;
vertical-align: middle;
white-space: nowrap;
transition: background-color ease-in-out 0.15s;
margin-left: 1rem;
`;

const AddonButton = styled.button`
height: 41px;
margin-left: 0px;
border: none;
display: table-cell;
width: 1%;
margin: 0;
min-width: 43px;
padding: 0 1rem;
font-family: "Roboto",sans-serif;
font-size: 1.5rem;
text-align: center;
line-height: 20px;
color: #628495;
background-color: #F6F6F6;
background-image: none;
vertical-align: middle;
white-space: nowrap;
box-sizing: border-box;
touch-action: manipulation;
text-decoration: none;
cursor: pointer;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-transition: background-color ease-in-out 0.15s;
transition: background-color ease-in-out 0.15s;

&:focus { outline: 0; }
&:active {
    color: #fff;
    background-color: #005875;
    outline: 0;
}
`;

const TextInputWithAddon = props => {
    return (
        <InputGroup>
            <InputText
                type='text'
                placeholder={props.placeholder}
                ref={props.inputRef}
                value={props.value}
                onKeyPress={e => (e.key === 'Enter') ? props.onSubmit() : null}
                onChange={e => props.onChange(e.currentTarget.value)}
            />
            <Addon onClick={() => props.onSubmit()}>
                <AddonButton>
                    {props.icon}
                </AddonButton>
            </Addon>
        </InputGroup>
    );
};

export default TextInputWithAddon;
