import React, { useState, useEffect, useCallback } from 'react';
// Components
import ModalWrapper from './ModalWrapper';
// Labels
import i18n from '../i18n';

/* --- <AddToFolderModal/> ------------------------------------------------------------------------------ */

const AddToFolderModal = ({ onClose, urls, parent, postAndUpdate }) => {
    // State
    const [type, setType] = useState('folder');
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState([]);

    // -- Handlers --

    const onCreateFolder = useCallback(async (e) => {
        if (e) e.preventDefault();
        setErrors([]); // Clear current errors
        const res = await postAndUpdate(urls.createFolder, { name, parent }, onClose);
        if (res.errors && res.errors.length) setErrors(res.errors); // Error handling
    }, [urls.createFolder, name, parent]);

    const onFileUpload = useCallback(async (e) => {
        if (e) e.preventDefault();
        setErrors([]); // Clear current errors
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const formData = new FormData();
        formData.append('name', name);
        formData.append('file', file);
        if (parent) formData.append('folder', parent); // Don't send null as parent
        const res = await postAndUpdate(urls.upload, formData, onClose, config);
        if (res.errors && res.errors.length) setErrors(res.errors); // Error handling
    }, [name, parent, file, urls.upload, name, parent]);

    // -- Effects --

    useEffect(() => {
        // Reset file when switching to folder
        if (type === 'folder') setFile(null);
    }, [type]);

    // -- Flags & Vars --

    const fileSizeExceeded = file && file.size && (file.size / (1000 ** 2)) > 10;
    const fileMissing = type === 'file' && !file;
    const saveDisabled = !name || fileMissing || fileSizeExceeded;
    const errMessages = fileSizeExceeded ? [...errors, i18n('fileSizeExceeded')] : errors;
    const onSubmit = saveDisabled ? null : type === 'folder' ? onCreateFolder : onFileUpload;

    // -- Render --

    return (
        <ModalWrapper
            modalTitle={i18n('add')}
            closeLabel={i18n('close')}
            saveLabel={i18n('add')}
            saveDisabled={saveDisabled}
            onSubmit={onSubmit}
            onClose={onClose}
        >
            <form className='form-horizontal' onSubmit={onSubmit}>
                <div className='form-group required'>
                    <label className='control-label col-sm-3'>{i18n('type')}</label>
                    <div className='col-sm-7'>
                        <div className='radio'>
                            <label>
                                <input
                                    type='radio'
                                    value='folder'
                                    name='type'
                                    checked={type === 'folder'}
                                    onChange={e => e.currentTarget.value ? setType('folder') : null}
                                />
                                {i18n('folder')}
                            </label>
                        </div>
                        <div className='radio'>
                            <label>
                                <input
                                    type='radio'
                                    value='file'
                                    name='type'
                                    checked={type === 'file'}
                                    onChange={e => e.currentTarget.checked ? setType('file') : null}
                                />
                                {i18n('file')}
                            </label>
                        </div>
                    </div>
                </div>
                <div className='form-group required'>
                    <label className='control-label col-sm-3'>{i18n('name')}</label>
                    <div className='col-sm-7'>
                        <input
                            type='text'
                            id='name'
                            className='form-control'
                            onChange={e => setName(e.currentTarget.value)}
                        />
                    </div>
                </div>
                {type === 'file' && (
                    <div className='form-group required' id='file'>
                        <label className='control-label col-sm-3'>{i18n('file')}</label>
                        <div className='col-sm-7'>
                            <input
                                type='file'
                                id='file'
                                accept='.doc, .docx, .ppt, .pptx, .xls, .xlsx, .jpg, .jpeg, .png, .pdf'
                                onChange={(e) => setFile(e.currentTarget.files[0])}
                            />
                        </div>
                    </div>
                )}
                {!!errMessages.length && (
                    <div className='row'>
                        {errMessages.map(err => (
                            <div className='col-sm-12' key={`error_${err}`}>
                                <div className='alert alert-danger'>
                                    {err}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </form>
        </ModalWrapper>
    );
};

/* --- Export ------------------------------------------------------------------------------ */

export default AddToFolderModal;
