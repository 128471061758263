/* eslint-disable react/prefer-stateless-function */
import autobind from 'autobind-decorator';
import React from 'react';
import boiler from '@boiler';
import { debounce } from '@utils';
import { templateString, round } from '@tsUtils';
import { actions } from './redux/data';

const textToNumber = value => +(value.toString().replace(',', '.'));

class App extends React.Component {

    constructor(props) {
        super(props);
        this.errorRef = React.createRef();
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        // Check for pre-filled charge
        const {
            setTotalFactoryChargeAction,
            setCoolantTypeAction,
            setShowRefrigerantAction,
            setTotalFieldChargeAction,
        } = this.props;
        this.setValidity = debounce(valid => {
            if (this.errorRef && this.errorRef.current) {
                this.errorRef.current.classList.toggle('has-error', !valid);
            }
        }, 150);
        // Add postal subscriptions
        boiler.postalChannel.subscribe({
            topic: 'set-total-factory-charge',
            callback: setTotalFactoryChargeAction,
        });

        boiler.postalChannel.subscribe({
            topic: 'set-coolant-type',
            callback: setCoolantTypeAction,
        });

        boiler.postalChannel.subscribe({
            topic: 'set-show-refrigerant',
            callback: setShowRefrigerantAction,
        });
        boiler.postalChannel.subscribe({
            topic: 'select-productrange',
            callback: () => setTotalFieldChargeAction('0'),
        });
    }

    componentDidUpdate() {
        this.setValidity(this.props.totalFieldCharge && !Number.isNaN(textToNumber(this.props.totalFieldCharge)));
    }

    @autobind
    onChangeInput(e) {
        const xNumeric = new RegExp('[^\\.\\,\\d]', 'g');
        let input = e.target.value
            .replace(xNumeric, '')
            .replace(/ /g, '')
            .trim();
        while ((input[0] === '0') && (input.length > 1) && (input[1] !== '.' && input[1] !== ',')) {
            input = input.slice(1);
        }
        this.props.setTotalFieldChargeAction(input);
    }

    render() {
        const {
            totalFieldCharge,
            totalFactoryCharge,
            voucherValue,
            labels,
            settings,
            coolantType,
            showRefrigerant,
        } = this.props;
        const numericFieldCharge = textToNumber(totalFieldCharge);
        return ((!!totalFactoryCharge && showRefrigerant) && (
            <React.Fragment>
                {/* COOLANT TYPE FIELD */}
                <div className='form-group required'>
                    <label className='col-sm-3 col-sm-2 control-label required'>{labels.refrigerant_type}</label>
                    <div className='col-sm-2'>
                        <input
                            type='text'
                            className='form-control disabled'
                            value={coolantType}
                            disabled
                        />
                    </div>
                    <div className='col-sm-1'>
                        <i className='glyphicon glyphicon-info-sign js-coolant-type-info' />
                    </div>
                </div>
                {/* CALCULATIONS */}
                <div className='form-group'>
                    {/* TOTAL FIELD CHARGE */}
                    <div className='col-sm-2 col-sm-offset-3'>
                        <strong>{labels.total_field_charge}</strong>
                        <br />
                        <div className='input-group' ref={this.errorRef}>
                            <input
                                type='text'
                                className={`form-control ${settings.totalFieldChargeDisabled ? 'disabled' : ''}`}
                                ref={this.inputRef}
                                onChange={this.onChangeInput}
                                name={settings.outputName}
                                value={totalFieldCharge}
                                disabled={settings.totalFieldChargeDisabled}
                            />
                            <span className='input-group-addon'>{labels.factory_charge_unit}</span>
                        </div>
                    </div>
                    {/* TOTAL FACTORY CHARGE */}
                    <div className='col-sm-1 text-center'>
                        <br />
                        +
                    </div>
                    <div className='col-sm-2'>
                        <strong>{labels.total_factory_charge}</strong>
                        <br />
                        <div className='input-group'>
                            <input
                                type='number'
                                className='form-control disabled'
                                value={round((totalFactoryCharge || 0), 2)}
                                disabled
                            />
                            <span className=' input-group-addon'>{labels.factory_charge_unit}</span>
                        </div>
                    </div>
                    {/* TOTAL RESULT CHARGE */}
                    <div className='col-sm-1 text-center'>
                        <br />
                        =
                    </div>
                    <div className='col-sm-2'>
                        <strong>{labels.total_charge}</strong>
                        <br />
                        <div className=' input-group'>
                            <input
                                type='number'
                                className='disabled form-control'
                                value={round(totalFactoryCharge + numericFieldCharge, 2)}
                                readOnly
                                disabled
                            />
                            <span className=' input-group-addon'>{labels.factory_charge_unit}</span>
                        </div>
                    </div>
                </div>
                {/* VOUCHER RESULT */}
                {(numericFieldCharge === 0 || numericFieldCharge === null) && !settings.totalFieldChargeDisabled && (
                    <div className='row'>
                        <div className='col-sm-8 col-sm-offset-3'>
                            <div
                                className='alert alert-info'
                                dangerouslySetInnerHTML={{ __html: labels.voucher_info }}
                            />
                        </div>
                    </div>
                )}
                {(voucherValue !== null) && (!!numericFieldCharge) && !settings.totalFieldChargeDisabled && (
                    <div className='row'>
                        <div className='col-sm-8 col-sm-offset-3'>
                            {(voucherValue === -1) ? (
                                <div
                                    className='alert alert-warning'
                                    dangerouslySetInnerHTML={{ __html: labels.voucher_warning }}
                                />
                            ) : (
                                <div
                                    className='alert alert-success'
                                    dangerouslySetInnerHTML={{
                                        __html: templateString(labels.voucher_result, {
                                            voucherValue,
                                            coolantType,
                                        }),
                                    }}
                                />
                            )}
                        </div>
                    </div>
                )}
            </React.Fragment>
        ));
    }

}

const mapStateToProps = ({ data, labels, settings }) => ({
    totalFieldCharge: data.totalFieldCharge,
    totalFactoryCharge: data.totalFactoryCharge,
    voucherValue: data.voucherValue,
    coolantType: data.coolantType,
    showRefrigerant: data.showRefrigerant,
    errors: data.errors,
    labels,
    settings,
});

const mapActionsToProps = {
    setTotalFieldChargeAction: actions.setTotalFieldCharge,
    setTotalFactoryChargeAction: actions.setTotalFactoryCharge,
    setCoolantTypeAction: actions.setCoolantType,
    setShowRefrigerantAction: actions.setShowRefrigerant,
};

export default boiler.connect(mapStateToProps, mapActionsToProps)(App);
