import { AppState } from '../../types';
import { ACTIONTYPES as appStateActions } from '../actions/appStateActions';
import { ACTIONTYPES as epackageActions } from '../actions/epackageActions';
import { Actions } from '../actions';

const initialState: AppState = {
    templateStep: 1,
    ignoredTooltips: [],
    isFormSaved: false,
    errors: null,
    isFetchingEpackageExample: false,
    epackageExampleLanguage: null,
};

const appState = (state: AppState, action: Actions): AppState => {
    switch (action.type) {
        case appStateActions.SET_ERRORS:
            return {
                ...state,
                errors: action.errors,
                isFetchingEpackageExample: false,
            };
        case appStateActions.SET_TEMPLATE_STEP:
            return { ...state, templateStep: action.templateStep };
        case appStateActions.IGNORE_TOOLTIP: {
            if (state.ignoredTooltips.includes(action.tooltip)) return state;
            return { ...state, ignoredTooltips: [...state.ignoredTooltips, action.tooltip] };
        }
        case epackageActions.SET_EPACKAGE:
            return {
                ...state,
                errors: { productsAndPrices: null, termsAndConditions: null },
            };
        case epackageActions.FETCH_EPACKAGE_EXAMPLE:
            return {
                ...state,
                isFetchingEpackageExample: true,
                errors: { ...state.errors, epackageExample: null },
                epackageExampleLanguage: action.language,
            };
        case epackageActions.SET_EPACKAGE_EXAMPLE:
            return {
                ...state,
                isFetchingEpackageExample: false,
                errors: { ...state.errors, epackageExample: null },
            };
        case appStateActions.SET_SAVED_STATUS:
            return { ...state, isFormSaved: action.isFormSaved };
        default:
            return { ...initialState, ...state };
    }
};

export default appState;
