/* eslint-disable react/sort-comp, react/no-did-mount-set-state */
import React from 'react';
import Postal from '../../Postal';

const ARROW_POS = { top: 'bottom', right: 'left', bottom: 'top', left: 'right' };

class PopoverView extends React.Component {

    constructor(props) {
        super(props);
        this.state = { dimensions: null };
        this.$popover = React.createRef();
    }

    calculateOffsetTop = () => {
        const { positioning, triggerDimensions } = this.props;
        const { dimensions } = this.state;
        switch (positioning) {
            case 'top':
                return window.pageYOffset - dimensions.height - 10;
            case 'bottom':
                return window.pageYOffset + triggerDimensions.height + 10;
            case 'left':
            case 'right':
                return window.pageYOffset - (dimensions.height / 2);
        }
        return 0;
    };

    calculateOffsetLeft = () => {
        const { positioning, triggerDimensions } = this.props;
        const { dimensions } = this.state;
        switch (positioning) {
            case 'left':
                return -(dimensions.width + 10);
            case 'right':
                return 10 + triggerDimensions.width;
            case 'top':
            case 'bottom':
                return (triggerDimensions.width - dimensions.width) / 2;
        }
        return 0;
    };

    closePopoverHandler = () => {
        Postal.publish('CLOSE_POPOVER');
    };

    componentDidMount() {
        const { width, height } = this.$popover.current.getBoundingClientRect();
        this.setState({ dimensions: { width, height } });
        setTimeout(() => {
            document.addEventListener('click', this.closePopoverHandler);
        }, 50);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closePopoverHandler);
    }

    render() {
        const { title, content, positioning, triggerDimensions } = this.props;
        const { dimensions } = this.state;
        // Style arrow position
        const arrowStyle = { [ARROW_POS[positioning]]: `calc((0.5rem + 1px) * -1)` };
        // Should be invisible on initial render
        const popoverStyle = { opacity: '0', pointerEvents: 'none', display: 'block' };
        const popoverContentStyle = {};
        // Calculate popover position & style once we do have all necessary dimensions
        if (dimensions) {
            // Calculate offsets
            const offsetTop = this.calculateOffsetTop();
            const offsetLeft = this.calculateOffsetLeft();
            // Calculate position
            const top = triggerDimensions.top + offsetTop;
            const left = triggerDimensions.left + offsetLeft;
            // Assign position to style
            popoverStyle.top = `${top}px`;
            popoverStyle.left = `${left}px`;
            // Make visible again
            popoverStyle.opacity = '1';
            if (this.props.fontSize) popoverContentStyle['font-size'] = `${this.props.fontSize}rem`;
            if (this.props.lineHeight) popoverContentStyle['line-height'] = `${this.props.lineHeight}rem`;
        }
        // Render Popover
        return (
            <div
                ref={this.$popover}
                className={`popover ${positioning}`}
                role='tooltip'
                style={popoverStyle}
            >
                <div className='popover-arrow' style={arrowStyle} />
                {title && <h3 className='popover-title'>{title}</h3>}
                <div
                    className='popover-content'
                    dangerouslySetInnerHTML={{ __html: content }}
                    style={popoverContentStyle}
                />
            </div>
        );
    }

}

export default PopoverView;
