/* eslint-disable func-names */
import boiler from '@boiler';
import App from './App';
import data from './redux/data';
import input from './redux/input';
import labels from './redux/labels';
import settings from './redux/settings';
import middleware from './redux/middleware';

(function () {
    boiler.initAppInstances(
        App,
        'add-installation-unit',
        { data, settings, labels, input },
        null,
        middleware,
    );
}());
