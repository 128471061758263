import React from 'react';
import boiler from '@boiler';
import Accordion from './Accordion';
import Remove from './Remove';
import Link from './EndUserChange/Link';
import Invite from './EndUserChange/Invite';
import i18n from '../i18n';
import { actions } from '../redux';

const EndUserChange = props => {

    const {
        action, hasEndUser, setEndUserAction, endUserData, setSelectedEndUserAction, settings,
    } = props;

    const accordionItems = [
        {
            id: 'no_change',
            content: null,
            label: i18n('no_change'),
        },
        {
            id: 'other_eu',
            content: <Link
                {...endUserData} // eslint-disable-line
                setSelectedEndUserAction={setSelectedEndUserAction}
                settings={settings}
            />,
            label: i18n('link_eu'),
        },
        {
            id: 'invite_eu',
            content: <Invite
                {...endUserData} // eslint-disable-line
                setSelectedEndUserAction={setSelectedEndUserAction}
            />,
            label: i18n('invite_eu'),
        },
    ];
    if (hasEndUser) {
        accordionItems.push({
            id: 'remove_eu',
            content: <Remove message={i18n('remove_message')} />,
            label: i18n('remove_eu'),
        });
    }
    return (
        <div className='row'>
            <div className='col-md-12'>
                <form className='form-horizontal' onSubmit={e => e.preventDefault()}>
                    <h4>{i18n('change_eu')}</h4>
                    <Accordion
                        items={accordionItems}
                        selectedId={action}
                        onSelectedItem={setEndUserAction}
                    />
                </form>
            </div>
        </div>
    );

};

const mapStateToProps = ({ data, settings }) => ({
    settings,
    hasEndUser: data.endUserData.hasEndUser,
    action: data.endUserData.action,
    endUserData: data.endUserData,
});

const mapActionsToProps = {
    setEndUserAction: actions.setEndUserAction,
    setSelectedEndUserAction: actions.setSelectedEndUserAction,
};

export default boiler.connect(mapStateToProps, mapActionsToProps)(EndUserChange);
