import React from 'react';
// Components
import Modal from '@common/Modal';

/* --- Styles --------------------------------------------------------------------------------------- */

const styles = {
    wrapper: {
        position: 'fixed',
        display: 'flex',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignContent: 'center',
    },
    zIndexMax: { zIndex: 9999 },
    backdrop: { opacity: 0.3, zIndex: 9000 },
};

/* --- <ModalWrapper/> ------------------------------------------------------------------------------ */

const ModalWrapper = ({ modalTitle, closeLabel, saveLabel, saveDisabled, onSubmit, onClose, children }) => (
    <div style={styles.wrapper}>
        <div className='modal-dialog' style={styles.zIndexMax}>
            <Modal
                modalTitle={modalTitle}
                closeLabel={closeLabel}
                saveLabel={saveLabel}
                saveDisabled={saveDisabled}
                submit={onSubmit}
                onClose={onClose}
            >
                {children}
            </Modal>
        </div>
        <div className='modal-backdrop' style={styles.backdrop} onClick={onClose} />
    </div>
);

/* --- Exports --------------------------------------------------------------------------------------- */

export default ModalWrapper;
