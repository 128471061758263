import React from 'react';

export const STATUS = Object.freeze({
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
});

const STYLES = {
    [STATUS.SUCCESS]: { icon: 'ok', color: '#74AC3C' },
    [STATUS.ERROR]: { icon: 'remove', color: '#6E060D' },
    [STATUS.PENDING]: { icon: 'hourglass', color: '#d7e4ed' },
};

const VerificationIcon = ({ verification }) => (
    <div className='col-sm-1'>
        {(verification && verification.status && Object.keys(STYLES).includes(verification.status)) ? (
            <i
                className={`glyphicon glyphicon-${STYLES[verification.status].icon}`}
                style={{ top: 8, color: STYLES[verification.status].color }}
            />
        ) : null}
    </div>
);

export default VerificationIcon;
