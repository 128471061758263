import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setAllowDataProcessing, setAllowRegistration } from '../store';
import i18n from '../i18n';

const CheckBox = styled.label`
    display: flex;
    line-height: 20px;
    margin-bottom: 1.5rem;

    input {
        margin: 0 1rem 0 0 !important;
    }
`;

const LegalChecks = () => {
    const dispatch = useDispatch();
    const allowDataProcessing = useSelector(({ store }) => store.allowDataProcessing);
    const allowRegistration = useSelector(({ store }) => store.allowRegistration);
    return (
        <div className='form-group' id='legal-declarations-professional'>
            <div className='col-sm-12' style={{ float: 'none', clear: 'both' }}>
                <CheckBox>
                    <input
                        type='checkbox'
                        checked={allowDataProcessing}
                        onChange={() => dispatch(setAllowDataProcessing(!allowDataProcessing))}
                        id='legal-data-processing'
                        name='legal-data-processing'
                    />
                    <span>{i18n('legal_send_to_prof_data_processing')}</span>
                </CheckBox>
                <CheckBox>
                    <input
                        type='checkbox'
                        checked={allowRegistration}
                        onChange={() => dispatch(setAllowRegistration(!allowRegistration))}
                        id='legal-allow-registration'
                        name='legal-allow-registration'
                    />
                    <span>{i18n('legal_send_to_prof_registration')}</span>
                </CheckBox>
            </div>
        </div>
    );
};

export default LegalChecks;
