/* eslint-disable no-throw-literal, consistent-return */
import postal from 'postal';

let _defaultChannel = postal.channel('marlon-default-channel');

const tryCreateChannel = (input) => {
    if (typeof input === 'string') {
        return postal.channel(input);
    } else if (input instanceof postal.ChannelDefinition) {
        return input;
    } else {
        throw 'Channel passed to tryCreateChannel is not of type String or postal.ChannelDefinition.';
    }
};

const getChannel = (altChannel = null) => {
    if (altChannel) {
        tryCreateChannel(altChannel);
    } else if (_defaultChannel) {
        return _defaultChannel;
    } else {
        throw 'Postal error: Postal instance or default-channel not set.';
    }
};

class Postal {

    // Set the default-channel so you do not need to pass a channel on every postal interaction.
    setDefaultChannel(defaultChannel) {
        _defaultChannel = tryCreateChannel(defaultChannel);
    }

    // Subscribe to a single event
    subscribe(topic, callback, altChannel = null) {
        return getChannel(altChannel).subscribe(topic, callback);
    }

    // Subscribe to multiple events
    smartSubscribe(target, ...events) {
        if (!target.handlers) target.handlers = {};
        events.forEach(evt => target.handlers[evt.event] = this.subscribe(evt.event, evt.handler));
    }

    // Unsubscribe to a single handler
    unsubscribe(subscription) {
        postal.unsubscribe(subscription);
    }

    // Unsubscribe all handlers of a target
    smartUnsubscribe(target) {
        if (target.handlers) Object.values(target.handlers).forEach(this.unsubscribe);
    }

    // Publish an event
    publish(topic, data, altChannel = null) {
        getChannel(altChannel).publish(topic, data);
    }

}

const _singleton = new Postal();

export default _singleton;
