import * as Icons from '@common/icons';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import i18n from '../i18n';
import * as Styles from '../styles';
import { useAppSelector } from '../redux';
import selectors from '../redux/selectors';
import { INDETERMINATE_STATES } from '../constants';
import actions from '../redux/actions';
import { Installation } from '../types';

type PropsType = {
    installations: Array<Installation>;
    selected: Array<string>;
};

const InstallationsTable = ({ installations, selected }: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const intermedinateState = useAppSelector(
        selectors.installations.getIndermedinateCheckboxState,
    );
    const checkboxRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (installations.length) {
            if (intermedinateState === INDETERMINATE_STATES.CHECKED) {
                checkboxRef.current.checked = true;
                checkboxRef.current.indeterminate = false;
            } else if (intermedinateState === INDETERMINATE_STATES.UNCHECKED) {
                checkboxRef.current.checked = false;
                checkboxRef.current.indeterminate = false;
            } else if (intermedinateState === INDETERMINATE_STATES.INDETERMINATE) {
                checkboxRef.current.checked = false;
                checkboxRef.current.indeterminate = true;
            }
        }
    }, [checkboxRef, intermedinateState, installations]);

    const checkAll = (checked: boolean): void => {
        dispatch(actions.installations.selectAllInstallations(checked));
    };

    const changeCheckbox = (installationId: string): void => {
        dispatch(actions.installations.selectInstallation(installationId));
    };

    return (
        <Styles.ScollTable>
            <Styles.Table className="table table-striped">
                <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                id="tableHeaderCheckbox"
                                onChange={(e) => checkAll(e.currentTarget.checked)}
                                ref={checkboxRef}
                            />
                        </th>
                        <th>{i18n('commissioning_date')}</th>
                        <th>{i18n('name')}</th>
                        <th>{i18n('city')}</th>
                        <th>{i18n('product')}</th>
                        <th>{i18n('status')}</th>
                    </tr>
                </thead>

                <tbody id="installationsTableBody">
                    {installations.map((installation) => (
                        <tr key={installation.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    onChange={() => changeCheckbox(installation.id)}
                                    checked={!!selected.find((id) => id === installation.id)}
                                />
                            </td>
                            <td>{installation.commissioningDate.replaceAll('-', '/')}</td>
                            <td>
                                {installation.name}
                                {installation.isKeyAccount && (
                                    <>
                                        &nbsp;
                                        <Icons.Glyph.Buildings />
                                    </>
                                )}
                            </td>
                            <td>{installation.city}</td>
                            <td>{installation.product}</td>
                            <td>
                                <span className={`badge badge--${installation.status}`}>
                                    {installation.status}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Styles.Table>
        </Styles.ScollTable>
    );
};

export default InstallationsTable;
