/* eslint-disable react/prefer-stateless-function */
import { debounce } from '@utils';
import boiler from '@boiler';
import autobind from 'autobind-decorator';
import axios from 'axios/index';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import Overview from './components/Overview';
import WebSocketListener from './components/WebSocketListener';
import Widget from './components/Widget';
import { WS_RESPONSE_TYPES } from './constants';
import { actions } from './redux/data';

class App extends React.Component {

    constructor(props) {
        super(props);
        const { settings, fetchNotificationsAction } = this.props;
        // WebSocket
        WebSocketListener.openWebSocket(settings.webSocketUrl, settings.jwt);
        WebSocketListener.subscribe(this.parseWebSocket);
        this.debouncedFetchNotifications = debounce(fetchNotificationsAction, 1000, false);
    }

    @autobind
    parseWebSocket(data) {
        switch (data.action) {
            case WS_RESPONSE_TYPES.REGISTERED:
                if (!this.keepAliveTO) WebSocketListener.keepAlive();
                break;
            case WS_RESPONSE_TYPES.UPDATE:
                //fetch notifications & tasks
                this.debouncedFetchNotifications();
                break;
        }
    }

    @autobind
    toggleNotificationRead(id) {
        const {
            settings,
            toggleNotificationReadAction,
            updateNotificationCounterAction,
            unreadNotificationCount,
        } = this.props;

        toggleNotificationReadAction(id);

        const notification = this.props.notifications.find(n => n.id === id);
        const toggleEndpoint = notification.hasBeenRead
            ? settings.markNotificationAsUnRead
            : settings.markNotificationAsRead;

        notification.hasBeenRead
            ? updateNotificationCounterAction(unreadNotificationCount + 1)
            : updateNotificationCounterAction(unreadNotificationCount - 1);

        axios.post(toggleEndpoint, {
            notification: notification.id,
        });
    }

    @autobind
    markAllAsRead() {
        const { settings } = this.props;
        axios.post(settings.markAllNotificationsAsRead);
    }

    @autobind
    loadMore() {
        const { setPagingAction, page } = this.props;
        setPagingAction(page + 1);
    }

    render() {
        const { toggleShowUnreadAction, settings } = this.props;
        const theme = { type: settings.theme };

        return (
            <ThemeProvider theme={theme}>
                <Widget
                    toggleNotificationRead={this.toggleNotificationRead}
                    markAllAsRead={this.markAllAsRead}
                    {...this.props} // eslint-disable-line
                />
                <Overview
                    toggleNotificationRead={this.toggleNotificationRead}
                    markAllAsRead={this.markAllAsRead}
                    loadMoreAction={this.loadMore}
                    toggleShowUnreadAction={toggleShowUnreadAction}
                    {...this.props} // eslint-disable-line
                />
            </ThemeProvider>
        );
    }

}

const mapStateToProps = ({ data, labels, settings }) => ({
    labels,
    settings,
    page: data.page,
    notifications: data.notifications,
    notificationCount: data.notificationCount,
    unreadNotificationCount: data.unreadNotificationCount,
    animate: data.animate,
    showUnread: data.showUnread,
});

const mapActionsToProps = {
    setNotificationsAction: actions.setNotifications,
    setAnimationAction: actions.setAnimation,
    toggleNotificationReadAction: actions.toggleNotificationRead,
    updateNotificationCounterAction: actions.updateUnreadNotificationCounter,
    setPagingAction: actions.setPaging,
    fetchNotificationsAction: actions.fetchNotifications,
    toggleShowUnreadAction: actions.toggleShowUnreadAction,
};

export default boiler.connect(mapStateToProps, mapActionsToProps)(App);
