import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../redux/index';
import { Product } from '../types';
import i18n from '../i18n';
import actions from '../redux/actions';
import { TOOLTIPS } from '../enums';
import { getCurrentTooltip } from '../redux/epackageSelectors';

const ProductRangeNameStyles = { maxWidth: '150px' };
const ProductRangePriceStyles = { maxWidth: '100px' };

type PropsType = {
    productId: string;
    productData: Product;
    error: string | null;
    setProductPrice: (productId: string, data: Partial<Product>) => void;
};
const ProductItem = ({ productId, productData, error, setProductPrice }: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const { name, fee, price } = productData;
    const isActive = useAppSelector((store) => store.epackage.isActive);
    const paymentFrequency = useAppSelector((store) => store.settings.paymentFrequency);
    const currencySymbol = useAppSelector((store) => store.settings.currencySymbol);
    const currentTooltip = useAppSelector(getCurrentTooltip);

    const [isProductRangeActive, setIsProductRangeActive] = useState<boolean>(!!price);

    return (
        <tr>
            <td>
                <input
                    type="checkbox"
                    className="checkbox tooltip-target-1"
                    checked={isProductRangeActive}
                    disabled={isActive}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.currentTarget.checked) {
                            setIsProductRangeActive(true);
                            dispatch(actions.appState.ignoreTooltip(TOOLTIPS.tt1));
                        } else {
                            setIsProductRangeActive(false);
                            setProductPrice(productId, { price: null });
                        }
                    }}
                />
            </td>
            <td style={ProductRangeNameStyles}>
                <strong>{name}</strong>
            </td>
            <td>
                <span className="text">
                    {i18n('Payment_frequency_of_fee')
                        .replace('%currencySymbol%', currencySymbol)
                        .replace('%fee%', fee)
                        .replace('%paymentFrequency%', paymentFrequency)}
                </span>
            </td>
            <td style={ProductRangePriceStyles}>
                {isProductRangeActive && (
                    <div className={`input-group ${error ? 'has-error' : ''}`}>
                        <input
                            type="decimal"
                            className="form-control tooltip-target-2"
                            placeholder="0.00"
                            step="0.01"
                            min="0.00"
                            disabled={isActive}
                            value={price || undefined}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setProductPrice(productId, { price: e.currentTarget.value });
                                if (currentTooltip === TOOLTIPS.tt2) {
                                    dispatch(actions.appState.ignoreTooltip(TOOLTIPS.tt2));
                                }
                            }}
                        />
                        <span className="input-group-addon">
                            {i18n('Payment_frequency_of_price')
                                .replace('%currencySymbol%', currencySymbol)
                                .replace('%paymentFrequency%', paymentFrequency)}
                        </span>
                    </div>
                )}
            </td>
        </tr>
    );
};

export default ProductItem;
