import React from 'react';
import * as Styles from '../styles';

const Header = ({ title, unreadNotificationCount, markAllAsRead, labels }) => (
    <Styles.Header className='list-group-item'>
        <div className='row'>
            <div className='col-sm-12'>
                <strong>
                    <small>{title}</small>
                </strong>
                <a
                    href='/'
                    onClick={(e) => { e.preventDefault(); markAllAsRead(); }}
                    className='pull-right read-all'
                >
                    <small><strong>{labels.mark_all_as_read}</strong></small>
                </a>
                <Styles.NotificationHeaderCounter
                    className='badge'
                    id='notification-counter'
                >
                    {unreadNotificationCount.toString()}
                </Styles.NotificationHeaderCounter>
            </div>
        </div>
    </Styles.Header>
);

export default Header;
