import React from 'react';
import { createPortal } from 'react-dom';
import * as Styles from '../styles';
import Header from './Header';
import Notification from './Notification';

const Overview = (props) => {

    const {
        notifications,
        unreadNotificationCount,
        toggleNotificationRead,
        toggleShowUnreadAction,
        markAllAsRead,
        labels,
        showUnread,
        settings,
        notificationCount,
        loadMoreAction,
        page,
    } = props;

    const notificationsOverview = document.getElementsByClassName(settings.overviewNode)[0];

    if (!notificationsOverview) return null;

    const renderLoadMore = () => {
        if ((page * 10) > notificationCount) {
            return null;
        } else {
            return (
                <Styles.LoadMore className="list-group-item">
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <a href='/' onClick={(e) => { e.preventDefault(); loadMoreAction(); }}>
                                <strong>{labels.load_more}</strong>
                            </a>
                        </div>
                    </div>
                </Styles.LoadMore>
            );
        }
    };

    return createPortal(
        <React.Fragment>
            <div className='row overview-header'>
                <div className='col-lg-6'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h1>{labels.notifications}</h1>
                        </div>
                        <div className='col-lg-6'>
                            <Styles.CheckIcon className='pull-right'>
                                <i
                                    className={`glyphicon glyphicon-${(showUnread) ? 'check' : 'unchecked'} text-info`}
                                    onClick={() => { toggleShowUnreadAction(); }}
                                />
                                &nbsp;
                                {labels.only_show_unread}
                            </Styles.CheckIcon>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6'>
                    <Header
                        title={labels.notifications}
                        unreadNotificationCount={unreadNotificationCount}
                        markAllAsRead={markAllAsRead}
                        labels={labels}
                        {...props} // eslint-disable-line
                    />
                    {notifications.map((notification) => {
                        return (
                            <Notification
                                toggleNotificationRead={toggleNotificationRead}
                                {...notification} // eslint-disable-line
                                key={notification.id}
                            />
                        );
                    })}
                    {renderLoadMore()}
                </div>
            </div>
        </React.Fragment>,
        notificationsOverview,
    );
};

export default Overview;
