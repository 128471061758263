import React, { useState, useCallback } from 'react';
// Components
import ModalWrapper from './ModalWrapper';
// Labels
import i18n from '../i18n';

/* --- <EditFolderModal> ---------------------------------------------------------------------------- */

const EditFolderModal = ({ onClose, name: propName, urls, id, postAndUpdate }) => {
    // State
    const [name, setName] = useState(propName);

    // -- Handlers --

    const onEditFolder = useCallback((e) => {
        if (e) e.preventDefault();
        postAndUpdate(urls.updateFolder.replace('__id__', id), { name }, onClose);
    }, [id, name]);

    // -- Render --

    return (
        <ModalWrapper
            modalTitle={i18n('rename')}
            closeLabel={i18n('close')}
            saveLabel={i18n('rename')}
            onSubmit={onEditFolder}
            onClose={onClose}
        >
            <form className='form-horizontal' onSubmit={onEditFolder}>
                <div className='form-group required'>
                    <label className='control-label col-sm-3'>{i18n('name')}</label>
                    <div className='col-sm-7'>
                        <input
                            type='text'
                            id='name'
                            className='form-control'
                            value={name}
                            onChange={e => setName(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </form>
        </ModalWrapper>
    );
};

/* --- Exports ------------------------------------------------------------------------------ */

export default EditFolderModal;
