import {
    SET_TOTAL_FIELD_CHARGE,
    SET_TOTAL_FACTORY_CHARGE,
    SET_VOUCHER_VALUE,
    SET_COOLANT_TYPE,
    SET_SHOW_REFRIGERANT,
} from './actions';

// Default state
const initialState = {
    totalFieldCharge: '0',
    totalFactoryCharge: 0,
    voucherValue: null,
    coolantType: null,
    showRefrigerant: false,
};

// Action creators
export const actions = {
    setTotalFieldCharge: totalFieldCharge => ({ type: SET_TOTAL_FIELD_CHARGE, value: totalFieldCharge }),
    setTotalFactoryCharge: totalFactoryCharge => ({ type: SET_TOTAL_FACTORY_CHARGE, value: totalFactoryCharge }),
    setCoolantType: coolantType => ({ type: SET_COOLANT_TYPE, value: coolantType }),
    setShowRefrigerant: showRefrigerant => ({ type: SET_SHOW_REFRIGERANT, value: showRefrigerant }),
};

// Reducer
export default function data(state, action) {

    switch (action.type) {

        case SET_TOTAL_FIELD_CHARGE:
            return { ...state, totalFieldCharge: action.value };

        case SET_TOTAL_FACTORY_CHARGE:
            return { ...state, totalFactoryCharge: action.value };

        case SET_VOUCHER_VALUE:
            return { ...state, voucherValue: action.value };

        case SET_COOLANT_TYPE:
            return { ...state, coolantType: action.value };

        case SET_SHOW_REFRIGERANT:
            return { ...state, showRefrigerant: action.value };

        default:
            return { ...initialState, ...state };

    }
}
