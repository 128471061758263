import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import * as Styles from '../styles';
import Header from './Header';
import Notification from './Notification';

const Widget = (props) => {
    const ref = useRef();
    const notificationBadgeRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const {
        notifications,
        unreadNotificationCount,
        animate,
        toggleNotificationRead,
        markAllAsRead,
        labels,
        settings,
    } = props;

    useOnClickOutside(ref, (e) => {
        // Ignore clicks on the notificationBadge to prevent toggling twice
        if (!notificationBadgeRef.current.contains(e.target)) setIsOpen(false);
    });

    return (
        <React.Fragment>
            <Styles.NotificationButton
                ref={notificationBadgeRef}
                animate={animate}
                type='button'
                className="dropdown-toggle"
                onClick={() => setIsOpen(!isOpen)}
            >
                {unreadNotificationCount >= 1 && (
                    <Styles.Badge
                        id='notification-counter'
                        className='badge'
                    >
                        {unreadNotificationCount}
                    </Styles.Badge>
                )}
                <i className='glyphicon glyphicon-pushpin' />
            </Styles.NotificationButton>
            <Styles.NotificationDropdown
                ref={isOpen ? ref : null}
                isOpen={isOpen}
                className='notifications-dropdown dropdown-menu'
            >
                <Styles.NotificationListGroup className='list-group'>
                    <Header
                        title={labels.notifications}
                        unreadNotificationCount={unreadNotificationCount}
                        markAllAsRead={markAllAsRead}
                        labels={labels}
                    />
                    {notifications.slice(0, 3).map((notification) => {
                        return (
                            <Notification
                                toggleNotificationRead={toggleNotificationRead}
                                {...notification} // eslint-disable-line
                                key={notification.id}
                            />
                        );
                    })}
                    <Styles.ButtonAllNotifications className='list-group-item'>
                        <div className='row'>
                            <div className='col-sm-12 text-center'>
                                <a href={settings.overview}>
                                    <small>
                                        <strong>{labels.show_all_notifications}</strong>
                                    </small>
                                </a>
                            </div>
                        </div>
                    </Styles.ButtonAllNotifications>
                </Styles.NotificationListGroup>
            </Styles.NotificationDropdown>
        </React.Fragment>
    );
};

export default Widget;
