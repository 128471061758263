import React from 'react';
import Postal from '../../Postal';

export default class PopoverTrigger extends React.Component {

    constructor(props) {
        super(props);
        this.$popoverTrigger = React.createRef();
    }

    componentDidMount() {
        const { triggerMethod = 'hover' } = this.props;
        if (triggerMethod === 'hover') {
            this.$popoverTrigger.current.addEventListener('mouseenter', this.triggerPopoverOpen, false);
            this.$popoverTrigger.current.addEventListener('mouseleave', this.triggerPopoverClose, false);
        } else if (triggerMethod === 'click') {
            this.$popoverTrigger.current.addEventListener('click', this.triggerPopoverOpen, false);
        }
    }

    componentWillUnmount() {
        const { triggerMethod = 'hover' } = this.props;
        if (triggerMethod === 'hover') {
            this.$popoverTrigger.current.removeEventListener('mouseenter', this.triggerPopoverOpen, false);
            this.$popoverTrigger.current.removeEventListener('mouseleave', this.triggerPopoverClose, false);
        } else if (triggerMethod === 'click') {
            this.$popoverTrigger.current.removeEventListener('click', this.triggerPopoverOpen, false);
        }
    }

    triggerPopoverOpen = () => {
        const { title, content, positioning, style = null } = this.props;
        const { top, left, width, height } = this.$popoverTrigger.current.getBoundingClientRect();
        Postal.publish('SHOW_POPOVER', {
            title,
            content,
            positioning,
            triggerDimensions: { top, left, width, height },
            lineHeight: style?.lineHeight | null,
            fontSize: style?.fontSize | null,
        });
    };

    triggerPopoverClose = () => {
        Postal.publish('CLOSE_POPOVER');
    };

    render() {
        const { className, children } = this.props;
        return (
            <div
                ref={this.$popoverTrigger}
                className={className}
            >
                {children}
            </div>
        );
    }

}
