import React from 'react';
import Joyride, { Step } from 'react-joyride';
import JoyrideTooltip from './JoyrideTooltip';
import i18n from '../i18n';
import { useAppSelector } from '../redux';
import { getCurrentTooltip } from '../redux/epackageSelectors';
import { JoyrideWrapperStyles } from '../styles/JoyrideTooltip';

const JoyrideWrapper = (): JSX.Element => {
    const currentTooltip = useAppSelector(getCurrentTooltip);

    const steps: Step[] = [
        {
            target: '.tooltip-target-1:first-of-type',
            placement: 'top-start',
            hideCloseButton: true,
            disableBeacon: true,
            title: i18n('step_1_tooltip_title'),
            content: i18n('step_1_tooltip_content'),
        },
        {
            target: '.tooltip-target-2:first-of-type',
            placement: 'top',
            hideCloseButton: true,
            disableBeacon: true,
            title: i18n('step_2_tooltip_title'),
            content: i18n('step_2_tooltip_content'),
        },
        {
            target: '#tooltip-target-3',
            placement: 'top',
            hideCloseButton: true,
            disableBeacon: true,
            title: i18n('step_3_tooltip_title'),
            content: i18n('step_3_tooltip_content'),
        },
        {
            target: '#tooltip-target-4',
            placement: 'top',
            hideCloseButton: true,
            disableBeacon: true,
            title: i18n('step_4_tooltip_title'),
            content: i18n('step_4_tooltip_content'),
        },
        {
            target: '#tooltip-target-5',
            placement: 'top-start',
            hideCloseButton: true,
            disableBeacon: true,
            title: i18n('step_5_tooltip_title'),
            content: i18n('step_5_tooltip_content'),
        },
    ];

    return (
        <Joyride
            run={currentTooltip !== null} // Checks if tooltips should run or not
            steps={steps}
            stepIndex={currentTooltip ?? undefined}
            disableScrolling={true}
            hideCloseButton={true}
            spotlightClicks={true}
            disableOverlay={true}
            tooltipComponent={JoyrideTooltip}
            styles={JoyrideWrapperStyles}
        />
    );
};

export default JoyrideWrapper;
