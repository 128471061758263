import { SET_NUMBER_OF_PANELS, SET_SERIAL_NUMBER, SET_UNIT_CODE, SET_UNIT_TYPE, SET_VERIFICATION } from './actions';

// Action types
const initialState = {
    unitType: null,
    serialNumber: null,
    unitCode: null,
    verification: null,
};

// Reducer
export default function input(state, action) {
    switch (action.type) {
        case SET_UNIT_TYPE:
            const { unitType } = action;
            // Reset everything if unitType changed
            return state.unitType === unitType ? state : { ...initialState, unitType };

        case SET_SERIAL_NUMBER:
            const { serialNumber } = action;
            return state.serialNumber === serialNumber ? state : { ...state, serialNumber };

        case SET_UNIT_CODE:
            const { unitCode } = action;
            return state.unitCode === unitCode ? state : { ...state, unitCode };

        case SET_VERIFICATION:
            const { verification } = action;
            return state.verification === verification ? state : { ...state, verification };

        case SET_NUMBER_OF_PANELS:
            const numberOfPanels = +action.numberOfPanels;
            if (Number.isNaN(numberOfPanels)) return state;
            return state.numberOfPanels === numberOfPanels ? state : { ...state, numberOfPanels };

        default:
            return { ...initialState, ...state };
    }
}
