/* eslint-disable import/prefer-default-export */
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { Store, EndUser, EndUserActions, KeyAccountActions, KeyAccountData } from '../types';

export enum actionTypes {
    SET_KEYACCOUNT_ACTION = 'SET_KEYACCOUNT_ACTION',
    RESET_KEYACCOUNT_DATA = 'RESET_KEYACCOUNT_DATA',
    SET_KEYACCOUNT_DATA = 'SET_KEYACCOUNT_DATA',
    SET_ERRORS_ACTION = 'SET_ERRORS_ACTION',
    SET_ENDUSER_ACTION = 'SET_ENDUSER_ACTION',
    SET_SELECTED_ENDUSER = 'SET_SELECTED_ENDUSER',
}

type SetKeyAccountAction = { type: actionTypes.SET_KEYACCOUNT_ACTION; data: KeyAccountActions };
type ResetKeyAccountDataAction = { type: actionTypes.RESET_KEYACCOUNT_DATA; data: null };
type SetKeyAccountDataAction = {
    type: actionTypes.SET_KEYACCOUNT_DATA;
    data: Partial<KeyAccountData>;
};
type SetEndUserAction = { type: actionTypes.SET_ENDUSER_ACTION; data: EndUserActions };
type SetSelectedEndUserAction = { type: actionTypes.SET_SELECTED_ENDUSER; data: Partial<EndUser> };
type SetErrorsAction = { type: actionTypes.SET_ERRORS_ACTION; errors: Record<string, string> };

export type ActionTypes =
    | SetKeyAccountAction
    | ResetKeyAccountDataAction
    | SetKeyAccountDataAction
    | SetEndUserAction
    | SetSelectedEndUserAction
    | SetErrorsAction;

export const actions = {
    setKeyAccountAction: (action: KeyAccountActions): SetKeyAccountAction => ({
        type: actionTypes.SET_KEYACCOUNT_ACTION,
        data: action,
    }),
    resetKeyAccountDataAction: (): ResetKeyAccountDataAction => ({
        type: actionTypes.RESET_KEYACCOUNT_DATA,
        data: null,
    }),
    setKeyAccountDataAction: (data: Partial<KeyAccountData>): SetKeyAccountDataAction => ({
        type: actionTypes.SET_KEYACCOUNT_DATA,
        data,
    }),
    setEndUserAction: (action: EndUserActions): SetEndUserAction => ({
        type: actionTypes.SET_ENDUSER_ACTION,
        data: action,
    }),
    setSelectedEndUserAction: (data: Partial<EndUser>): SetSelectedEndUserAction => ({
        type: actionTypes.SET_SELECTED_ENDUSER,
        data,
    }),
    setErrorsAction: (errors: Record<string, string>): SetErrorsAction => ({
        type: actionTypes.SET_ERRORS_ACTION,
        errors,
    }),
};

export const useAppSelector: TypedUseSelectorHook<Store> = useSelector;
