export enum MONITORING_STATUS {
    has_lan_adapter = 'has_lan_adapter',
    on_hold = 'on_hold',
    consent_requested = 'consent_requested',
    inactive = 'inactive',
    active = 'active',
    warning = 'warning',
    error = 'error',
    no_consent = 'no_consent',
    no_status = 'no_status', // If monitoringStatus of installation is null
}

export const IconMarkers: Record<MONITORING_STATUS, string> = {
    [MONITORING_STATUS.has_lan_adapter]: '../assets/backend/images/map_marker_default.png',
    [MONITORING_STATUS.on_hold]: '../assets/backend/images/map_marker_default.png',
    [MONITORING_STATUS.consent_requested]: '../assets/backend/images/map_marker_default.png',
    [MONITORING_STATUS.inactive]: '../assets/backend/images/map_marker_default.png',
    [MONITORING_STATUS.active]: '../assets/backend/images/map_marker_green.png',
    [MONITORING_STATUS.warning]: '../assets/backend/images/map_marker_orange.png',
    [MONITORING_STATUS.error]: '../assets/backend/images/map_marker_active.png',
    [MONITORING_STATUS.no_consent]: '../assets/backend/images/map_marker_default.png',
    [MONITORING_STATUS.no_status]: '../assets/backend/images/map_marker_default.png',
};

export enum FILTERS {
    status = 'status',
    owner = 'has-key-account',
    maintenanceDue = 'maintenance-due-within-months',
    monitoring = 'monitoring',
}

export const MAP_STYLE = [
    { featureType: 'poi', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit', stylers: [{ visibility: 'off' }] },
    { featureType: 'water', elementType: 'labels', stylers: [{ visibility: 'off' }] },
];
