/* eslint-disable import/prefer-default-export */

export enum INDETERMINATE_STATES {
    CHECKED = 1,
    UNCHECKED = 2,
    INDETERMINATE = -1,
}

export enum INSTALLATION_KEYS {
    id = 'id',
    city = 'city',
    commissioningDate = 'commissioningDate',
    commissioningCode = 'commissioningCode',
    companyName = 'companyName',
    email = 'email',
    name = 'name',
    product = 'product',
    productName = 'productName',
    status = 'status',
    serialNumber = 'serialNumber',
    isKeyAccount = 'isKeyAccount',
}
