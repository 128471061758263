import autobind from 'autobind-decorator';
import { fatal, log, STYLE } from '@common/Logger';

const subscribers = {};

class WebSocketListener {

    constructor() {
        // KeepAlive timeout
        this.keepAliveTO = null;
    }

    subscribe(callback) {
        const token = Date.now();
        subscribers[token] = callback;
        return token;
    }

    unsubscribe(token) { delete (subscribers[token]); }

    @autobind
    keepAlive() {
        this.keepAliveTO = setTimeout(() => {
            log('WebSocket [keepalive]', STYLE.SOCKET);
            this.ws.send(JSON.stringify({ action: 'keepalive' }));
            this.keepAlive();
        }, 50000); // 50s // 270000); // 4m30s
    }

    openWebSocket(wsUrl, wsRegistrationID) {
        if (this.ws) return;
        // Save values
        this.wsUrl = wsUrl;
        this.wsRegistrationID = wsRegistrationID;
        if (window.WebSocket) {
            log('WebSocket INIT', STYLE.SOCKET, 'Connecting to WebSocket on:', `[${wsUrl}]`);
            // Create WebSocket
            this.ws = new WebSocket(this.wsUrl);
            this.ws.onopen = this.onOpenHandler;
            this.ws.onerror = this.onErrorHandler;
        } else {
            fatal('Browser does not support WebSockets', 'Notifications will not work as expected.');
        }
    }

    @autobind
    onOpenHandler() {
        log('WebSocket OPENED', STYLE.SOCKET, 'Registering for configurationId:', `[${this.wsRegistrationID}]`);
        // Listen for close & message events
        this.ws.onclose = this.onCloseHandler;
        this.ws.onmessage = this.onMessageHandler;
        // Register to the WebSocket
        this.ws.send(JSON.stringify({
            action: 'register',
            jwt: this.wsRegistrationID,
        }));
    }

    @autobind
    onErrorHandler() {
        clearTimeout(this.keepAliveTO);
        fatal('WebSocket ERROR', 'Failed to open WebSocket on:', `[${this.wsUrl}]`);
    }

    @autobind
    onMessageHandler(event) {
        if (event.data) {
            try {
                // Parse response
                const data = JSON.parse(event.data);
                // Notify Logger
                const values = Object.keys(data).map((key) => {
                    return `${key}: ${data[key]}`;
                });
                log(`<< WebSocket MESSAGE from [${event.origin}]`, STYLE.SOCKET, ...values);
                // Notify all subscwibers
                Object.values(subscribers).forEach(sub => sub(data));
            } catch (e) {
                log(`<< WebSocket MESSAGE`, STYLE.SOCKET, `Error parsing JSON response!`);
            }
        }

    }

    @autobind
    onCloseHandler() {
        log(`WebSocket CLOSED`, STYLE.SOCKET, 'Attempting reconnect in 1 second.');
        setTimeout(() => this.openWebSocket(this.wsUrl, this.wsRegistrationID), 1000);
    }

}

const _singleton = new WebSocketListener();

export default _singleton;
