import { DataType } from '../../types';
import { ActionType, SELECTION_ACTIONS } from '../actions';
import { FILTERS } from '../../constants';

const initData = {
    filterSelection: {
        [FILTERS.status]: '',
        [FILTERS.owner]: '',
        [FILTERS.maintenanceDue]: '',
        [FILTERS.monitoring]: '',
    },
    searchQuery: '',
    error: null,
};

const data = (state: DataType, action: ActionType): DataType => {
    switch (action.type) {
        // Set selected filter values
        case SELECTION_ACTIONS.setFilterValue:
            return {
                ...state,
                filterSelection: {
                    ...state.filterSelection,
                    ...action.filter,
                },
            };
        case SELECTION_ACTIONS.setSearchQuery:
            return {
                ...state,
                searchQuery: action.searchQuery,
            };
        case SELECTION_ACTIONS.setError:
            return {
                ...state,
                error: action.errorMessage,
            };
        default:
            return { ...initData, ...state };
    }
};

export default data;
