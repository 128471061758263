import axios from 'axios/index';
import {
    SET_TOTAL_FIELD_CHARGE,
    SET_TOTAL_FACTORY_CHARGE,
    SET_VOUCHER_VALUE,
} from './actions';

const { CancelToken } = axios;
let cancel;

const textToNumber = value => +(value.toString().replace(',', '.'));

export default [(store => next => action => {
    next(action);
    // Check if charges were changed
    if ((action.type === SET_TOTAL_FIELD_CHARGE) || (action.type === SET_TOTAL_FACTORY_CHARGE)) {
        // Abort active request
        if (typeof cancel === 'function') cancel();
        // Get values
        const { data, settings } = store.getState();
        const field = textToNumber(action.type === SET_TOTAL_FIELD_CHARGE ? action.value : data.totalFieldCharge);
        const factory = action.type === SET_TOTAL_FACTORY_CHARGE ? action.value : data.totalFactoryCharge;
        const totalCharge = field + factory;
        if ((field <= 0) || (factory <= 0)) {
            // Clear voucher data
            next({ type: SET_VOUCHER_VALUE, value: null });
        } else if (totalCharge > (2 * factory)) {
            // Invalid voucher
            next({ type: SET_VOUCHER_VALUE, value: -1 });
        } else {
            // Reset while call is performing
            next({ type: SET_VOUCHER_VALUE, value: null });
            // Build request
            const url
                = `${settings.fetchCalculateVoucher}`
                + `?totalFieldCharge=${data.totalFieldCharge}`
                + `&affiliateCountryId=${settings.affiliateCountryId}`
                + `&coolantType=${data.coolantType}`;
            // Call service
            axios
                .get(url, { cancelToken: new CancelToken(c => cancel = c) })
                .then(res => {
                    if (res.status === 200 && res.data && (res.data.success === true)) {
                        next({ type: SET_VOUCHER_VALUE, value: res.data.value });
                    }
                })
                .catch(() => {});
        }
    }
})];
