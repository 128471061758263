import { InstallationType } from '../../types';

export enum INSTALLATIONS_ACTIONS {
    fetchInstallations = 'installations/fetch',
    setInstallations = 'installations/set',
}

export type FetchInstallationsActionType = {
    type: INSTALLATIONS_ACTIONS.fetchInstallations;
};
export const fetchInstallationsAction = (): FetchInstallationsActionType => ({
    type: INSTALLATIONS_ACTIONS.fetchInstallations,
});

export type SetInstallationsActionType = {
    type: INSTALLATIONS_ACTIONS.setInstallations;
    installations: InstallationType[];
};
export const setInstallationsAction = (
    installations: InstallationType[],
): SetInstallationsActionType => ({
    type: INSTALLATIONS_ACTIONS.setInstallations,
    installations,
});

export type InstallationsActionType = FetchInstallationsActionType | SetInstallationsActionType;
