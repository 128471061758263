import { KeyAccountActions, KeyAccountData } from '../types';
import { actionTypes, ActionTypes } from './index';

const KEYACCOUNT_ERROR_MAP: Record<string, string> = {
    'keyAccountData[selectedKeyAccount]': 'selectedKeyAccountError',
    'keyAccountData[selectedProject]': 'selectedProjectError',
};

const initialKeyAccountDataState: Partial<KeyAccountData> = {
    action: KeyAccountActions.no_change,
    hasKeyAccount: null,
    selectedKeyAccount: null,
    selectedProject: null,
    errors: {},
    keyAccounts: [],
};

const keyAccountData = (state: KeyAccountData, action: ActionTypes): KeyAccountData => {
    switch (action.type) {
        case actionTypes.SET_KEYACCOUNT_ACTION:
            return { ...state, action: action.data };
        case actionTypes.RESET_KEYACCOUNT_DATA:
            return {
                ...state,
                ...{ selectedKeyAccount: action.data, selectedProject: action.data },
            };
        case actionTypes.SET_KEYACCOUNT_DATA:
            return { ...state, ...action.data };
        case actionTypes.SET_ERRORS_ACTION: {
            const errors: Record<string, string> = {};
            Object.entries(action.errors).forEach(([key, value]) => {
                const mappedKey: string = KEYACCOUNT_ERROR_MAP[key];
                if (mappedKey) errors[mappedKey] = value as string;
            });
            return { ...state, ...{ errors } };
        }
        default:
            return { ...initialKeyAccountDataState, ...state };
    }
};

export default keyAccountData;
