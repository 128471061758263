import { Epackage } from '../../types';
import { ACTIONTYPES as epackageActionsType, EpackageActions } from '../actions/epackageActions';

const initialState: Epackage = {
    productsAndPrices: [],
    termsAndConditions: [],
    isActive: false,
    epackageExample: null,
};

const epackage = (state: Epackage, action: EpackageActions): Epackage => {
    switch (action.type) {
        case epackageActionsType.SET_EPACKAGE:
            return { ...action.epackage, epackageExample: state.epackageExample };
        case epackageActionsType.FETCH_EPACKAGE_EXAMPLE:
            return { ...state, epackageExample: null };
        case epackageActionsType.SET_EPACKAGE_EXAMPLE:
            return { ...state, epackageExample: action.epackageExample };
        default:
            return { ...initialState, ...state };
    }
};

export default epackage;
