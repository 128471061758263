import * as Icons from '@common/icons';
import { editorStateToHtml, htmlToEditorState } from '@tsUtils';
import { EditorState } from 'draft-js';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch } from 'react-redux';
import { TOOLTIPS } from '../enums';
import i18n from '../i18n';
import { useAppSelector } from '../redux';
import actions from '../redux/actions';
import { getCurrentTooltip } from '../redux/epackageSelectors';
import * as EditorStyles from '../styles/Editor';
import { TermsAndConditions } from '../types';

const wysiwygToolbarOptions = {
    options: ['inline', 'blockType', 'list'],
    inline: { options: ['bold', 'italic'] },
    blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3'] },
    list: { options: ['unordered', 'ordered'] },
};
const wysiwygWrapperStyle = { border: '1px solid #C4CFD5' };
const wysiwygEditorStyle = { minHeight: '20rem', marginLeft: 15, marginRight: 15 };

type PropsType = {
    error: string | null;
    data: TermsAndConditions;
    isActive: boolean;
    setTermsAndConditions: (language: string, text: EditorState) => void;
    setTermsAndConditionsFiles: (language: string, file: File | null) => void;
};

const TermsAndConditionsLanguage = ({
    error,
    data,
    isActive,
    setTermsAndConditions,
    setTermsAndConditionsFiles,
}: PropsType): JSX.Element => {
    const dispatch = useDispatch();
    const { language, text, fileName, filePath } = data;
    const currentTooltip = useAppSelector(getCurrentTooltip);

    const [input, setInput] = useState<EditorState>(htmlToEditorState(text || ''));

    const onChangeEditorState = (e: EditorState): void => {
        setInput(e);
        if (editorStateToHtml(e) !== editorStateToHtml(input)) {
            setTermsAndConditions(language, e);
            if (currentTooltip === TOOLTIPS.tt4) {
                dispatch(actions.appState.ignoreTooltip(TOOLTIPS.tt4));
            }
        }
    };

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setTermsAndConditionsFiles(language, e.target.files?.[0] || null);
    };

    const onDeleteFile = (): void => {
        dispatch(actions.epackage.deleteTermsAndConditionsFile(language));
    };

    return (
        <div key={`terms-and-conditions-${language}`}>
            <div className="col-lg-1">
                <h4>{language}</h4>
            </div>
            <div className="col-lg-11">
                <form className="form form-horizontal">
                    <div className="form-group">
                        <label htmlFor="termsconditions" className="col-sm-3 control-label">
                            {i18n('your_terms_and_conditions')}
                        </label>
                        <div className="col-sm-9">
                            <EditorStyles.Wrapper>
                                <Editor
                                    readOnly={isActive}
                                    toolbar={wysiwygToolbarOptions}
                                    wrapperStyle={wysiwygWrapperStyle}
                                    editorStyle={wysiwygEditorStyle}
                                    editorState={input}
                                    onEditorStateChange={onChangeEditorState}
                                />
                            </EditorStyles.Wrapper>
                        </div>
                    </div>
                    <hr />
                    <div className={`form-group ${error ? 'has-error' : ''}`}>
                        <label htmlFor="summary" className="col-sm-3 control-label">
                            {i18n('info_document')}
                        </label>
                        <div className="col-sm-9">
                            <input type="file" accept=".pdf" onChange={onChangeFile} />
                            <span className="help-block">{i18n('info_document_help_message')}</span>
                            {fileName && (
                                <>
                                    <br />
                                    {i18n('current_file')}
                                    &nbsp;
                                    <a href={filePath || ''}>{fileName}</a>
                                    &nbsp;
                                    <a
                                        href="#"
                                        className="btn btn-default btn-sm"
                                        onClick={onDeleteFile}
                                    >
                                        <Icons.Glyph.TrashCan />
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                </form>
                <hr />
            </div>
        </div>
    );
};

export default TermsAndConditionsLanguage;
