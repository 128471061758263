import { FILTERS } from '../../constants';

export enum SELECTION_ACTIONS {
    setFilterValue = 'selection/setFilterValue',
    setSearchQuery = 'selection/setSearchQuery',
    setError = 'data/setError',
}

export type SetFilterValueActionType = {
    type: SELECTION_ACTIONS.setFilterValue;
    filter: Partial<Record<FILTERS, string>>;
};
export const setFilterValueAction = (
    filter: Partial<Record<FILTERS, string>>,
): SetFilterValueActionType => ({
    type: SELECTION_ACTIONS.setFilterValue,
    filter,
});

export type SetSearchQueryActionType = {
    type: SELECTION_ACTIONS.setSearchQuery;
    searchQuery: string;
};
export const setSearchQueryAction = (searchQuery: string): SetSearchQueryActionType => ({
    type: SELECTION_ACTIONS.setSearchQuery,
    searchQuery,
});

export type SetErrorActionType = {
    type: SELECTION_ACTIONS.setError;
    errorMessage: string | null;
};
export const setErrorAction = (errorMessage: string | null): SetErrorActionType => ({
    type: SELECTION_ACTIONS.setError,
    errorMessage,
});

export type SelectionActionType =
    | SetFilterValueActionType
    | SetSearchQueryActionType
    | SetErrorActionType;
