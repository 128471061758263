const i18n = (k, returnKeyAsFallback = true) => {
    if (!k) return '';
    const key = k.replace(/-/g, '_');
    let label = null;
    if (i18n.labels) {
        label = i18n.labels[key];
        if (!label && returnKeyAsFallback) label = key;
    }
    return label;
};

i18n.set = labels => i18n.labels = ({ ...i18n.labels, ...labels });

export default i18n;

// Set default labels
i18n.labels = {
    select_company: 'Select a professional...',
    search_by_email: 'Search by e-mail address',
    or: 'or',
    search_by_email_title: 'Email Requirements',
    search_by_email_requirements: 'The email you search for should belong to an approved professional in the same country with pre-sales enabled.',
    send_to_professional_title: 'Send to another Professional',
    send_to_selected_professional: 'Send to selected Professional',
    no_professional_found: 'Cannot find a Professional for this e-mail address.',
    try_another_search: 'Try another search',
    comment: 'Comment',
    loading: 'Loading...',
    searching_companies: 'Searching...',
    legal_send_to_prof_data_processing: 'I, Installer, hereby declare that, having provided all information to Professional concerning the processing of personal data for the purpose of registering on the platform “Stand by Me”, in line with applicable legislation, the Professional has given the consent to me as Installer to the processing of his/her personal data.',
    legal_send_to_prof_registration: 'I, Installer, further declare that the Professional is informed that Stand by Me is a platform owned by Daikin and that Daikin will receive and use the above personal data to send to the Professional an invitation via e-mail, on my behalf/on behalf of my company, asking for the completion of the registration on "Stand by Me".',
    sending_to_professional: 'Sending lead to professional, please wait...',
    sent_to_professional_success_description: 'The lead was sent to the selected professional',
    sent_to_professional_failed_description: 'Something went wrong, please try again later',
};
