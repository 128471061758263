import { getMostRelevantUserId, parseCompany } from './utils';

// Action types
export const actions = {
    SET_ID: 'SET_ID',
    SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
    SEARCH: 'SEARCH',
    RETRY_SEARCH: 'RETRY_SEARCH',
    SET_COMPANIES: 'SET_COMPANIES',
    SELECT_KNOWN_COMPANY: 'SELECT_KNOWN_COMPANY',
    SELECT_COMPANY: 'SELECT_COMPANY',
    SELECT_USER: 'SELECT_USER',
    SET_COMMENT: 'SET_COMMENT',
    SET_ALLOW_DATA_PROCESING: 'SET_ALLOW_DATA_PROCESING',
    SET_ALLOW_REGISTRATION: 'SET_ALLOW_REGISTRATION',
    SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
    SUBMIT: 'SUBMIT',
    SET_SUBMIT_RESULT: 'SET_SUBMIT_RESULT',
};

// Action creators
export const setId = id => ({ type: actions.SET_ID, id });
export const selectKnownCompany = companyId => ({ type: actions.SELECT_KNOWN_COMPANY, companyId });
export const selectCompany = companyId => ({ type: actions.SELECT_COMPANY, companyId });
export const setSearchQuery = searchQuery => ({ type: actions.SET_SEARCH_QUERY, searchQuery });
export const search = () => ({ type: actions.SEARCH });
export const retrySearch = () => ({ type: actions.RETRY_SEARCH });
export const selectUser = userId => ({ type: actions.SELECT_USER, userId });
export const setSearchResults = result => ({ type: actions.SET_SEARCH_RESULTS, result });
export const setComment = comment => ({ type: actions.SET_COMMENT, comment });
export const setAllowDataProcessing = val => ({ type: actions.SET_ALLOW_DATA_PROCESING, allowDataProcessing: val });
export const setAllowRegistration = val => ({ type: actions.SET_ALLOW_REGISTRATION, allowRegistration: val });
export const submit = () => ({ type: actions.SUBMIT });
export const setSubmitResult = success => ({ type: actions.SET_SUBMIT_RESULT, success });

export const SELECTION_METHODS = {
    KNOWN_COMPANY: 'KNOWN_COMPANY',
    SEARCH: 'SEARCH',
};

// Default state
const initialState = {
    // Starting data
    id: null,
    urls: [],
    knownCompanies: [],
    // Company/User choice
    searchQuery: '',
    companies: null,
    selectedUserId: null,
    selectionMethod: null,
    // Form details
    comment: '',
    allowDataProcessing: false,
    allowRegistration: false,
    // LoadStates
    isSearchingQuery: false,
    isSending: false,
    isSubmitSuccessful: null,
};

// Reducer
export default function store(state, action) {

    switch (action.type) {
        case actions.SET_ID:
            // Reset store except for urls & knownCompanies
            return {
                ...initialState,
                urls: state.urls,
                knownCompanies: state.knownCompanies,
                id: action.id,
            };
        case actions.SET_SEARCH_QUERY:
            return { ...state, searchQuery: action.searchQuery };
        case actions.SEARCH:
            return {
                ...state,
                companies: null,
                selectedUserId: null,
                isSearchingQuery: true,
                selectionMethod: SELECTION_METHODS.SEARCH,
            };
        case actions.RETRY_SEARCH:
            return {
                ...state,
                companies: null,
                searchQuery: '',
                selectedUserId: null,
                isSearchingQuery: false,
            };
        case actions.SET_SEARCH_RESULTS:
            // Parse companies for easier handling
            const companies = action.result.map(parseCompany);
            return {
                ...state,
                companies,
                // AutoSelect a user if thers only 1 company
                selectedUserId: (companies.length === 1) ? getMostRelevantUserId(companies[0], state.searchQuery) : '',
                isSearchingQuery: false,
            };
        case actions.SELECT_KNOWN_COMPANY:
            // List consists only of the chosen company & auto-select the most relevant user of that company
            const selectedCompany = state.knownCompanies.find(c => c.id === action.companyId);
            return {
                ...state,
                searchQuery: '',
                companies: [selectedCompany],
                selectedUserId: getMostRelevantUserId(selectedCompany),
                selectionMethod: SELECTION_METHODS.KNOWN_COMPANY,
            };
        case actions.SELECT_COMPANY:
            // Instead of selecting a "company", select the most relevant user of that company
            return {
                ...state,
                selectedUserId: getMostRelevantUserId(state.companies.find(c => c.id === action.companyId)),
            };
        case actions.SELECT_USER:
            return { ...state, selectedUserId: action.userId };
        case actions.SET_COMMENT:
            return { ...state, comment: action.comment };
        case actions.SET_ALLOW_DATA_PROCESING:
            return { ...state, allowDataProcessing: action.allowDataProcessing };
        case actions.SET_ALLOW_REGISTRATION:
            return { ...state, allowRegistration: action.allowRegistration };
        case actions.SUBMIT:
            return { ...state, isSending: true };
        case actions.SET_SUBMIT_RESULT:
            return {
                ...state,
                isSending: false,
                isSubmitSuccessful: action.success,
            };
        default:
            return { ...initialState, ...state };
    }

}
