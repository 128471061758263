import { createLogic } from 'redux-logic';
import axios from 'axios';
import actions from '../actions';
import { Store } from '../../types';
import { TOOLTIPS } from '../../enums';
import {
    ACTIONTYPES as epackageActions,
    SaveProductsAndPricesAction,
    SaveTermsAndConditionsAction,
    DeleteTermsAndConditionsFileAction,
    FetchEpackageExampleAction,
    SaveStatusAction,
} from '../actions/epackageActions';
import { ACTIONTYPES as appStateActions } from '../actions/appStateActions';

type DepObj = { getState: () => Store };

type SaveProductsAndPricesDepObj = DepObj & { action: SaveProductsAndPricesAction };

const saveProductsAndPricesLogic = createLogic({
    type: epackageActions.SAVE_PRODUCTS_AND_PRICES,
    name: 'epackage.saveProductsAndPricesLogic',
    process({ getState, action }: SaveProductsAndPricesDepObj, dispatch, done) {
        const url = getState().settings.urls.saveProductsAndPrices;

        const productsAndPricesPayload = action.products.map(({ id, price }) => {
            return { templateEpackagePriceId: id, price };
        });

        axios
            .post(url, { productsAndPrices: productsAndPricesPayload })
            .then((res) => {
                const { data, status } = res;
                if (status === 200 && data && data.success) {
                    const { productsAndPrices, termsAndConditions, ...epackage } = data.package;
                    dispatch(
                        actions.epackage.setEpackage({
                            ...epackage,
                            productsAndPrices: Object.values(productsAndPrices),
                            termsAndConditions: Object.values(termsAndConditions),
                        }),
                    );
                    dispatch(actions.appState.setTemplateStep(2));
                    dispatch(actions.appState.ignoreTooltip(TOOLTIPS.tt3));
                }
            })
            .catch((err) => {
                const errors = err?.response?.data?.errors;
                // The errors are key,value pairs set by PHP e.g. "productsAndPrices[0].price: ..."
                dispatch(actions.appState.setErrors({ productsAndPrices: errors }));
            })
            .finally(done);
    },
});

type SaveTermsAndConditionsDepObj = DepObj & { action: SaveTermsAndConditionsAction };
const saveTermsAndConditionsLogic = createLogic({
    type: epackageActions.SAVE_TERMS_AND_CONDITIONS,
    name: 'epackage.saveTermsAndConditionsLogic',
    process({ getState, action }: SaveTermsAndConditionsDepObj, dispatch, done) {
        const url = getState().settings.urls.saveTermsAndConditionFiles;
        const { termsAndConditions } = getState().epackage;

        const { files, terms } = action.data;

        const formData = new FormData();
        Object.entries(files).forEach(([language, file]) => {
            if (file) {
                formData.append(`files[${language}][new_path]`, file);
            } else {
                const originalFile = termsAndConditions.find((tac) => tac.language === language);
                formData.append(
                    `files[${language}][originalFilename]`,
                    originalFile?.fileName || '',
                );
                formData.append(`files[${language}][path]`, originalFile?.filePath || '');
            }
        });
        Object.entries(terms).forEach(([language, term]) => {
            formData.append(`terms[${language}]`, term || '');
        });

        axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                const { data, status } = res;
                if (status === 200 && data && data.success) {
                    const { productsAndPrices, ...epackage } = data.package;
                    dispatch(
                        actions.epackage.setEpackage({
                            ...epackage,
                            productsAndPrices: Object.values(productsAndPrices),
                            termsAndConditions: Object.values(data.package.termsAndConditions),
                        }),
                    );
                    dispatch(actions.appState.setTemplateStep(3));
                    dispatch(actions.appState.ignoreTooltip(TOOLTIPS.tt5));
                }
            })
            .catch((err) => {
                const errors = err?.response?.data?.errors;
                // The errors are key,value pairs set by PHP e.g. "files[0]: ..."
                dispatch(actions.appState.setErrors({ termsAndConditions: errors }));
            })
            .finally(done);
    },
});

type DeleteTaCFileDepObj = DepObj & { action: DeleteTermsAndConditionsFileAction };
const deleteTermsAndConditionsFileLogic = createLogic({
    type: epackageActions.DELETE_TERMS_AND_CONDITIONS_FILE,
    name: 'epackage.deleteTermsAndConditionsFileLogic',
    process({ getState, action }: DeleteTaCFileDepObj, dispatch, done) {
        const url = getState().settings.urls.saveTermsAndConditionFiles;

        const formData = new FormData();
        formData.append(`files[${action.language}]`, new File([], ''));

        axios
            .post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                const { data, status } = res;
                if (status === 200 && data && data.success) {
                    const { productsAndPrices, termsAndConditions, ...epackage } = data.package;
                    dispatch(
                        actions.epackage.setEpackage({
                            ...epackage,
                            productsAndPrices: Object.values(productsAndPrices),
                            termsAndConditions: Object.values(termsAndConditions),
                        }),
                    );
                }
            })
            .catch((err) => {
                const errors = err?.response?.data?.errors;
                // The errors are key,value pairs set by PHP
                dispatch(actions.appState.setErrors({ termsAndConditions: errors }));
            })
            .finally(done);
    },
});

type FetchEpackageExampleDepObj = DepObj & { action: FetchEpackageExampleAction };

const fetchEpackageExampleLogic = createLogic({
    type: epackageActions.FETCH_EPACKAGE_EXAMPLE,
    name: 'epackage.fetchEpackageExampleLogic',
    process({ getState, action }: FetchEpackageExampleDepObj, dispatch, done) {
        const { renderExample } = getState().settings.urls;
        const url = decodeURIComponent(renderExample).replace('{language}', action.language);
        axios
            .get(url)
            .then((res) => {
                const { data, status } = res;
                if (status === 200 && data) dispatch(actions.epackage.setEpackageExample(data));
            })
            .catch((_err) => {
                dispatch(actions.appState.setErrors({ epackageExample: 'error_generating_example' }));
            })
            .finally(done);
    },
});

type SaveStatusDepObj = DepObj & { action: SaveStatusAction };
const saveStatusLogic = createLogic({
    type: epackageActions.SAVE_STATUS,
    name: 'epackage.saveStatusLogic',
    process({ getState, action }: SaveStatusDepObj, dispatch, done) {
        const url = getState().settings.urls.activate;
        axios
            .post(url, { activate: action.isActive })
            .then((res) => {
                const { data, status } = res;
                if (status === 200 && data && data.success) {
                    const { productsAndPrices, termsAndConditions, ...epackage } = data.package;
                    dispatch(
                        actions.epackage.setEpackage({
                            ...epackage,
                            productsAndPrices: Object.values(productsAndPrices),
                            termsAndConditions: Object.values(termsAndConditions),
                        }),
                    );
                    dispatch(actions.appState.setFormSaved(true));
                }
            })
            .catch((err) => {
                const errors = err?.response?.data?.errors;
                // These errors are for the whole form, e.g. "epackageGroupId.products_and_prices: epackage_group_has_no_epackages"
                dispatch(actions.appState.setErrors({ form: errors }));
                dispatch(actions.appState.setFormSaved(false));
            })
            .finally(done);
    },
});

const triggerRerenderJoyrideLogic = createLogic({
    type: appStateActions.IGNORE_TOOLTIP,
    name: 'epackage.triggerRerenderJoyrideLogic',
    debounce: 500,
    validate({ action }, allow) {
        allow(action);
    },
});
export default [
    saveProductsAndPricesLogic,
    saveTermsAndConditionsLogic,
    deleteTermsAndConditionsFileLogic,
    fetchEpackageExampleLogic,
    saveStatusLogic,
    triggerRerenderJoyrideLogic,
];
