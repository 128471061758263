/* eslint-disable quote-props */

/* --- i18n() -------------------------------------------------------------------------------------- */

const i18n = k => {
    const key = k.replace(/-/g, '_');
    // If labels arn't set yet, return the key
    if (!i18n.labels) return key;
    // If label does not exist, return an empty string (twig/yml return the key if a label is missing/empty)
    const label = i18n.labels[key];
    return (label === key) ? '' : label;
};

i18n.set = labels => i18n.labels = ({ ...i18n.labels, ...labels });

/* --- Default Labels ------------------------------------------------------------------------------ */

i18n.labels = {
    rename: 'Rename',
    documents: 'Documents',
    add: 'Add',
    close: 'Close',
    name: 'Name',
    type: 'Type',
    folder: 'Folder',
    file: 'File',
    areYouSure: 'Are you sure?',
    removeThisFolder: 'Remove this folder?',
    removeThisFile: 'Remove this file?',
    fileSizeExceeded: 'Files can only be max 10Mb in size',
    yes: 'Yes',
    no: 'No',
};

/* --- Exports ------------------------------------------------------------------------------------- */

export default i18n;
