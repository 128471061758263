import axios from 'axios';
import { ACTIONS } from '../../constants';
import { actions } from '../data';

const fetchNotificationMiddleware = store => next => action => {
    //Forward to next middleware
    next(action);
    // Check actionType
    if (action.type === ACTIONS.FETCH_NOTIFICATIONS || action.type === ACTIONS.TOGGLE_SHOW_UNREAD_NOTIFICATIONS) {

        const { settings, data } = store.getState();
        const { notificationCount, page, showUnread } = data;

        const params = {
            page: 1,
            perPage: (page * 10),
            onlyUnread: showUnread,
        };

        axios
            .get(settings.fetchNotifications, { params })
            .then(res => {
            //there are new notifications -> shake that widget
                if (notificationCount < res.data.count && action.type !== ACTIONS.TOGGLE_SHOW_UNREAD_NOTIFICATIONS) {
                    next(actions.setAnimation(true));
                    setTimeout(() => { next(actions.setAnimation(false)); }, 2000);
                }

                next(actions.setNotifications(res.data));
            });
    }
};

export default fetchNotificationMiddleware;
