/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import * as Logger from '@common/Logger';
// Labels
import i18n from '../i18n';

/* --- Styles --------------------------------------------------------------------------------------- */

const styles = {
    marginSpan: { margin: '0 6px' },
    ml8: { marginLeft: '8px' },
    mt0: { marginTop: '0px' },
    black: { color: 'black' },
    btnAdd: { position: 'absolute', right: '45px', marginTop: '0px' },
    btnRemove: { position: 'absolute', right: '12px', marginTop: '0px' },
};

/* --- Helpers -------------------------------------------------------------------------------------- */

const downloadFile = async (url, filename) => {
    const requestConfig = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json' } };
    try {
        const response = await axios.get(url, requestConfig);
        const type = response.headers['Content-Type'] || response.headers['content-type'];
        const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        link.parentElement.removeChild(link);
    } catch (err) {
        Logger.error(err);
    }
};

/* --- <ExplorerRow/> ------------------------------------------------------------------------------- */

const ExplorerRow = ({
    id,
    name,
    folderDepth,
    isFolder,
    isExpanded,
    file_name,
    url,
    onClick,
    onEdit,
    onAdd,
    onRemove,
}) => {
    let iconClass = 'glyphicon-file';
    if (isFolder) iconClass = isExpanded ? 'glyphicon-folder-open' : 'glyphicon-folder-close';
    // Render
    return (
        <li className='list-group-item node-tree' style={{ paddingLeft: `${(folderDepth * 20) + 16}px` }}>
            <span onClick={onClick || null}>
                <span className='icon expand-icon' />
                <span className={`icon node-icon glyphicon ${iconClass}`} />
                <span style={styles.marginSpan}>
                    {name}
                    {file_name && (
                        <span style={styles.ml8}>
                            (
                            <a
                                href={url}
                                target='_blank'
                                rel='noopener noreferrer'
                                download={file_name}
                                onClick={(e) => { e.preventDefault(); downloadFile(url, file_name); }}
                            >
                                {file_name}
                            </a>
                            )
                        </span>
                    )}
                </span>
            </span>
            {onEdit && (
                <a
                    className='rename btn btn-xs btn-default'
                    style={styles.mt0}
                    onClick={() => onEdit(id)}
                >
                    <i className='glyphicon glyphicon-pencil' style={styles.black} />
                </a>
            )}
            {onAdd && (
                <a
                    className='add btn btn-xs btn-default'
                    style={styles.btnAdd}
                    onClick={() => onAdd(id)}
                >
                    <i className='glyphicon glyphicon-plus' />
                    {` ${i18n('add')} `}
                </a>
            )}
            {onRemove && (
                <a
                    className='delete confirm btn btn-xs btn-default'
                    style={styles.btnRemove}
                    onClick={() => onRemove(id)}
                >
                    <i className='glyphicon glyphicon-trash' />
                </a>
            )}
        </li>
    );
};

/* --- Export ------------------------------------------------------------------------------ */

export default ExplorerRow;
